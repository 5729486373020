import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pt from 'vuetify/es5/locale/pt'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt'
  },
  theme: {
    options: {
      customProperties: true,
      variations: false
    },
    themes: {
      light: {
        // Primary
        primary: '#03142E',
        darkprimary: '#020C1C',
        lightprimary: '#7C95BF',
        // Secondary
        secondary: '#CE181E',
        darksecondary: '#4F090C',
        lightsecondary: '#F08185',
        // Other
        yellow: '#FACC00',
        cyan: '#048ABF',
        orange: '#F24F1D',
        dark: '#191E26',
        blue: '#173161',
        white: '#FFFFFF',
        black: '#000000',
        // Text
        labelcolor: '#152536',
        inputcolor: '#5E6267',
        placeholdercolor: '#ABB5BE',
        // Card
        cardcolor: '#EEEEEE',
        // Icon
        iconcolor: '#343A3F',
        // Grays
        // Ir preenchendo conforme o desenvolvimento
        gray: '#B9BDC4',
        lightgray: '#F4F5F4',
        // Feedback
        success: '#008836',
        warning: '#FFB801',
        error: '#CE181E'
      }
    }
  }
})
