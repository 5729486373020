import axios from 'axios'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import store from '@/store'
import { get } from 'lodash'
import session from './session'
import router from './router'
import axiosCancel from 'axios-cancel'

const http = axios.create({
  baseURL: `${process.env.VUE_APP_WS_ROOT}/api`,
  timeout: 60 * 1000
})

export default http

axiosCancel(http)

Vue.use(VueAxios, http)

// region Interceptor de request
function onRequestFulfilled (config) {
  if (session.exists()) {
    config.headers['Authorization'] = session.get('token')
  }

  const options = config['options'] = {
    silent: false,
    ...config['options']
  }

  if (!options.silent) {
    store.commit('loadingStart')
  }
  return config
}

function onRequestRejected (error) {
  hideLoading(error)
  return Promise.reject(error)
}

http.interceptors.request.use(onRequestFulfilled, onRequestRejected)
// endregion

// region Interceptor de response

function onResponseFulfilled (response) {
  hideLoading(response)

  return response
}

function onResponseRejected (error) {
  hideLoading(error)

  const status_code = get(error, 'response.status', 0)
  const url = get(error, 'response.config.url', '')

  if ([401, 403].includes(status_code) && url !== '/login') {
    Vue.$toast.error('Sua sessão expirou')
    session.destroy()
    // noinspection JSIgnoredPromiseFromCall
    router.replace('/login')

    return Promise.reject(new Error('cancelRequest'))
  } else {
    return Promise.reject(error)
  }
}

http.interceptors.response.use(onResponseFulfilled, onResponseRejected)
// endregion

function hideLoading (response) {
  const silent = get(response, 'config.options.silent', false)
  if (!silent) {
    store.commit('loadingDone')
  }
}
