<template>
  <Loading :visible="true" />
</template>

<script>
  import { get } from 'lodash'
  import auth from '@/mixins/auth'
  import { Mensagens } from '@/plugins/constants'

  export default {
    mixins: [auth],
    mounted () {
      this.autenticar()
    },
    methods: {
      async autenticar () {
        try {
          const resposta = await this.$msalInstance.handleRedirectPromise()

          if (resposta) {
            var mensagem = await this.autenticarAzure(resposta.idToken)

            if (mensagem) {
              this.$toast.warning(mensagem)
            }
          }

          this.$router.replace('/')
        } catch (erro) {
          const mensagem = get(erro, 'message' || Mensagens.ERRO)

          this.$router.push({ name: 'Login' })
          this.$toast.error(mensagem)
        }
      }
    }
  }

</script>
