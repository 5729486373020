<template>
  <div class="position-relative">
    <label v-if="label" :class="{ 'label-error': hasError }">{{ label }}</label>
    <v-select
        ref="field"
        v-bind="$attrs"
        v-on="$listeners"
        outlined
        dense
        :multiple="multiple"
        class="text-field transition"
        @click="toggle = true"
        @blur="toggle = false"
        @change="toggle = false">
      <template v-if="multiple" v-slot:item="{item, attrs, on}">
        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
          <v-list-item-content>
            <v-list-item-title>
              <v-row no-gutters align="center">
                {{ item.descricao }}
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <Checkbox color="secondary" :input-value="active"></Checkbox>
          </v-list-item-action>
        </v-list-item>
      </template>
      <template v-slot:append-outer>
        <a class="d-block icon-toggle">
          <v-icon
              :class="(toggle) ? 'mdi-rotate-0' : 'mdi-rotate-180'"
              x-small
              color="primary"
              @click="abreSeletor"
              @blur="toggle = false"
          >
            pc-seta
          </v-icon>
        </a>
      </template>
    </v-select>
  </div>
</template>

<script>
  export default {
    name: 'SelectField',
    props: {
      label: String,
      multiple: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      isMounted: false,
      toggle: false
    }),
    computed: {
      hasError () { return this.isMounted && this.$refs.field.validationState === 'error' }
    },
    mounted () {
      this.isMounted = true
    },
    methods: {
      abreSeletor () {
        this.toggle = true
        this.$refs.field.onClick(() => {})
      }
    }
  }
</script>

<style lang="scss" scoped>
  label {
    color: var(--labelcolor);
    &.label-error {
      color: var(--secondary);
    }
  }

  .icon-toggle {
    position: absolute;
    bottom: 45%;
    right: 20px;
    margin-bottom: 9px;
    text-decoration: none;
  }
</style>

<style lang="scss">
  .text-field.v-input {
    fieldset {
      border-color: var(--gray);
      background: var(--white);
    }
    &.v-input--is-focused fieldset {
      border-width: 1px;
      border-color: #048ABF;
      filter: drop-shadow(0 0 4px #55AFFC)
    }
    .v-input__slot {
      min-height: 38px !important;
      padding: 0 20px !important;
    }
    input {
      color: var(--inputcolor);
      &::placeholder {
        color: var(--placeholdercolor);
      }
    }

    &.error--text {
      fieldset {
        border-width: 1px;
        border-color: var(--secondary);
      }
    }
  }
  .v-application .text-field .error--text {
    color: var(--secondary) !important;
  }

  .theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before {
    display: none;
  }

  .theme--light.v-list-item--active:hover
  .v-icon {
    color: var(--primary) !important;
  }

</style>
