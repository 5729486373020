import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'

export async function buscar (pagina) {
  try {
    const { data: notificacoes } = await http.get('/notificacao', {
      options: {
        silent: true
      },
      headers: {
        page: pagina
      }
    })

    return notificacoes
  } catch (erro) {
    throw Mensagens.ERRO
  }
}

export async function vizualizar (notificacoes) {
  try {
    let ids = []

    notificacoes.map(notificacao => {
      ids.push(notificacao.idNotificacao)
    })

    await http.put('/notificacao', {
      idNotificacao: ids
    })
  } catch (erro) {
    throw Mensagens.ERRO
  }
}
