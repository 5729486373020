import Vue from 'vue'
import { isEmpty, isNumber } from 'lodash'
import { isObject as _isObject } from 'vuetify/es5/util/helpers'
import { TipoExibicaoCota, TipoPerfil } from '@/plugins/constants'
import moment from 'moment'

export function getFilters (value) {
  if (isDateFormat(value)) {
    return { filtro_data: toDateFormatFiltro(value) }
  }

  let filtro = {}
  if (isDecimal(value)) {
    const decimalValue = parseFloat(value.replace('.', '').replace(',', '.'))
    return { filtro_numero: decimalValue }
  }
  if (isNumeric(value)) {
    filtro = { filtro_numero: value }
  }
  return { ...filtro, filtro_texto: value }
}

function isDateFormat (value) {
  const dateRegex = /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d\d$/
  return value.match(dateRegex)
}

function isDecimal (value) {
  const decimalRegex = /^\d{1,3}(?:\.\d{3})*,\d{2}$/
  return value.match(decimalRegex)
}

function isNumeric (value) {
  return /^\d+$/.test(value)
}

export function toDateFormatFiltro (data) {
  if (!isEmpty(data)) {
    if (isNumber(data)) {
      data = data.toString().replace(/^([0-9]{4})([0-9]{2})([0-9]{2})$/, '$1/$2/$3')
    } else {
      data = toDate(data)
    }

    if (data.indexOf('/') !== -1) {
      let d = data.split('/')

      data = d[2] +
        '-' + d[1] +
        '-' + d[0] + 'T00:00:00.000-03:00'
    }
  }

  return data
}

export function toDate (data) {
  if (data.indexOf(' ') !== -1) {
    let d = data.split(' ')
    data = d[0]
  } else {
    if (data.indexOf('T') !== -1) {
      let d = data.split('T')
      data = d[0]
    }
  }

  return data
}

export const unmask = v => {
  if (!v) {
    return ''
  }
  return v.replace(/[^\d]+/g, '')
}

export function primeiraLetraMaiuscula (value) {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
  }
  return value
}

export const isObject = _isObject

export const alert = (options) => {
  const alert = Vue.prototype.$alert
  alert.show(options)
  return alert
}

export function getSituacaoReserva (parametros) {
  function getMilissegundosEntreDatas (tempoExpiracao) {
    if (parametros.dataHoraReserva) {
      const dataHoraExpiracao = moment(parametros.dataHoraReserva).add(tempoExpiracao).format('YYYY-MM-DDTHH:mm:ss')
      const event = moment(dataHoraExpiracao)
      const dataHoraAtual = moment()
      return event.diff(dataHoraAtual)
    }
    return 0
  }

  const tempoExpiracaoEmMilissegundos = horaParaMilissegundo(parametros.tempoExpiracaoReserva)
  const tempoPenalidadeEmMilissegundos = horaParaMilissegundo(parametros.tempoPenalidade)
  const tempoExpiracaoReservaEmMilissegundos = getMilissegundosEntreDatas(tempoExpiracaoEmMilissegundos) + tempoPenalidadeEmMilissegundos
  const tempoIniciarContagemEmMilissegundos = minutoParaMilissegundo(parametros.tempoIniciarContagemRegressiva)

  if (!parametros.cotaCancelada) {
    if (parametros.dataHoraReserva && tempoExpiracaoReservaEmMilissegundos > 0) {
      if (tempoExpiracaoReservaEmMilissegundos > tempoIniciarContagemEmMilissegundos || tempoIniciarContagemEmMilissegundos === 0) {
        return [ TipoExibicaoCota.EXIBIR_DATA_HORA, tempoExpiracaoReservaEmMilissegundos ]
      } else if (tempoExpiracaoReservaEmMilissegundos <= tempoIniciarContagemEmMilissegundos) {
        return [ TipoExibicaoCota.EXIBIR_CONTAGEM_REGRESSIVA, tempoExpiracaoReservaEmMilissegundos ]
      }
    }
  }
  return [ TipoExibicaoCota.EXIBIR_DISPONIVEL, tempoExpiracaoReservaEmMilissegundos ]
}

export function horaParaMilissegundo (hora) {
  return hora * (60000 * 60)
}

function minutoParaMilissegundo (minuto) {
  return minuto * 60000
}

export function validaCPF (cpf) {
  cpf = unmask(cpf)
  let numeros, digitos, soma, i, resultado, digitos_iguais
  digitos_iguais = 1
  if (cpf.length < 11) {
    return false
  }
  for (i = 0; i < cpf.length - 1; i++) {
    if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
      digitos_iguais = 0
      break
    }
  }
  if (!digitos_iguais) {
    numeros = cpf.substring(0, 9)
    digitos = cpf.substring(9)
    soma = 0
    for (i = 10; i > 1; i--) {
      soma += numeros.charAt(10 - i) * i
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    // eslint-disable-next-line eqeqeq
    if (resultado != digitos.charAt(0)) {
      return false
    }
    numeros = cpf.substring(0, 10)
    soma = 0
    for (i = 11; i > 1; i--) {
      soma += numeros.charAt(11 - i) * i
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    // eslint-disable-next-line eqeqeq
    return resultado == digitos.charAt(1)
  }
  return false
}

export function validaCNPJ (cnpj) {
  cnpj = unmask(cnpj)

  if (cnpj === '') return false

  if (cnpj.length !== 14) {
    return false
  }

  // Elimina CNPJs invalidos conhecidos
  if ([
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999'
  ].includes(cnpj)) {
    return false
  }

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  let digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  // eslint-disable-next-line eqeqeq
  if (resultado != digitos.charAt(0)) {
    return false
  }

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  // eslint-disable-next-line eqeqeq
  return resultado == digitos.charAt(1)
}

export function validaCelular (celular) {
  return /^\([1-9]{2}\) 9[2-9][0-9]{3}-[0-9]{4}$/.test(celular)
}

export function validaTelefone (telefone) {
  return /^\([1-9]{2}\) [2-8][0-9]{3}-[0-9]{4}$/.test(telefone)
}

export function validaEmail (email) {
  return /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i.test(email)
}

export function calcularSaldoBruto (entrada, valorCompra) {
  return entrada - valorCompra
}

export function calcularResultadoFinanceiro (saldoBruto, valorComissao, taxaTransferencia, tributo, custo_parcelas) {
  return saldoBruto - valorComissao - taxaTransferencia - tributo - custo_parcelas
}

export function calcularPercentualResultadoFinanceiro (resultadoFinanceiro, creditoAtual) {
  return resultadoFinanceiro / creditoAtual * 100 || null
}

export function calcularValorComissao (perfilVendedor, creditoAtual, configuracoes) {
  switch (perfilVendedor) {
    case TipoPerfil.PARCEIRO:
      return (configuracoes.percentualComissaoParceiro * creditoAtual) / 100
    case TipoPerfil.CLT:
      return (configuracoes.percentualComissaoVendedor * creditoAtual) / 100
    case TipoPerfil.COLABORADOR:
      return (configuracoes.percentualComissaoVendedor * creditoAtual / 100) +
          (configuracoes.percentualComissaoGerente * creditoAtual / 100) +
          (configuracoes.percentualComissaoSupervisor * creditoAtual / 100)
    case TipoPerfil.SUBLOGIN:
      return (configuracoes.percentualComissaoParceiro * creditoAtual) / 100
    case TipoPerfil.FRANQUEADO:
      return (configuracoes.percentualComissaoParceiro * creditoAtual) / 100
    case TipoPerfil.VENDEDOR:
      return (configuracoes.percentualComissaoVendedor * creditoAtual / 100) +
          (configuracoes.percentualComissaoGerente * creditoAtual / 100) +
          (configuracoes.percentualComissaoSupervisor * creditoAtual / 100)
    default:
      return 0
  }
}
