import http from '@/plugins/http'
import { get } from 'lodash'
import { Mensagens } from '@/plugins/constants'

export async function getMinhasReservas (usuario) {
  try {
    const { data } = await http.get('/minha-reserva', usuario)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function reservarCota (reserva) {
  try {
    const { data } = await http.post('/reservar', reserva)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function cancelarReserva (reserva) {
  try {
    const { data } = await http.post('/cancelar-reserva', reserva)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function atualizarEtiquetaReserva (reserva) {
  try {
    const { data } = await http.post('/atualiza-etiqueta-reserva', reserva)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function getCotaReservada (cotaId) {
  try {
    const { data } = await http.get(`/cota-reservada/${cotaId}`)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}
