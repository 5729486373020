import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'
import { get } from 'lodash'

export async function gerarCodigoPix (idProjeto) {
  try {
    const { data: payload } = await http.get(`/gerar-pix/${idProjeto}`)
    return payload
  } catch (erro) {
    throw get(erro, 'response.data.msg_erro', Mensagens.ERRO)
  }
}
