<template>
  <div>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        offset-y
        nudge-width="300"
        rounded
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            elevation="0"
            icon
            class="grey lighten-4"
            v-bind="attrs"
            v-on="on"
            @click="iniciaContadorNotificacao"
        >
          <v-badge
              dot
              offset-x="11"
              offset-y="9"
              color="secondary"
              :value="items.filter(item => item.hasOwnProperty('visualizado') && !item.visualizado).length"
          >
            <v-icon>pc-notificacao</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-card class="px-2 py-2 rounded-lg">
        <v-virtual-scroll
            ref="virtualScroll"
            bench="5"
            item-height="100"
            :items="items"
            :height="100 * items.length"
            max-height="500"
            @scroll="scroll"
        >
          <template
              v-if="items.length > 0"
              v-slot:default="{ item, index }"
          >
            <v-list-item class="pl-0 pr-2" v-if="item !== 0">
              <v-badge
                  bottom
                  :color="item.visualizado ? 'transparent' : 'secondary'"
                  offset-x="26"
                  offset-y="25"
                  dot
              >
                <v-list-item-icon class="cardcolor rounded-circle px-2 py-2 mx-4">
                  <v-icon>pc-alerta</v-icon>
                </v-list-item-icon>
              </v-badge>
              <v-list-item-content>
                <div class="text-subtitle-2 my-0">{{ item.titulo }}</div>
                <div class="text-caption my-0" style="height: min-content">{{ item.descricao }}</div>
                <div class="text-caption">{{ formatarData(item.dataHora) }}</div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="index + 1 === items.length">
              <v-list-item-content ref="loader" v-if="!fim">
                <v-img
                    v-if="carregando"
                    class="spinner"
                    src="../assets/img/spinner.png"
                    alt="Carregando..."
                    contain
                    height="30"
                    width="30"
                />
              </v-list-item-content>
              <v-list-item-content v-else class="text-center">
                <div>Não há mais notificações.</div>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-else class="mx-5"></v-divider>
          </template>
        </v-virtual-scroll>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  import moment from 'moment'
  import { buscar, vizualizar } from '@/service/notificacoes'

  export default {
    data: () => ({
      menu: false,
      items: [0],
      pagina: 0,
      carregando: false,
      fim: false
    }),
    mounted () {
      this.carregarNotificacoes()
    },
    methods: {
      iniciaContadorNotificacao () {
        const umSegundoEmMili = 1000
        const pagina = this.pagina

        let tempoTotal = 2000
        const interval = setInterval(() => {
          tempoTotal = moment.duration(tempoTotal - umSegundoEmMili, 'milliseconds')

          if (tempoTotal.seconds() === 0) {
            this.items.forEach(item => {
              if (item.pagina === pagina) {
                item.visualizado = true
              }
            })
            clearInterval(interval)
          }
        }, umSegundoEmMili)
      },
      async carregarNotificacoes () {
        this.carregando = true
        let notificacoes = []

        try {
          notificacoes = await buscar(this.pagina)
        } catch (erro) {
          this.$toast.error(erro)
        }

        notificacoes.forEach(item => {
          item.pagina = this.pagina
        })
        vizualizar(notificacoes)

        if (notificacoes.length < 10) {
          this.fim = true
        }

        if (this.items[0] === 0 && notificacoes.length > 0) {
          this.items.pop()
        }

        this.items = this.items.concat(notificacoes)

        this.carregando = false
      },
      scroll (event) {
        if (this.$refs.loader) {
          if (event.target.offsetHeight + 30 >= this.$refs.loader.getBoundingClientRect().top) {
            if (!this.carregando && !this.fim) {
              this.pagina++
              this.carregarNotificacoes()
              this.iniciaContadorNotificacao()
            }
          }
        }
      },
      formatarData (data) {
        const momentData = moment(data, 'YYYY/MM/DD H:m')
        const diaAtual = moment(Date.now()).format('D')
        const diaAnterior = moment(Date.now()).subtract(1, 'days').format('D')

        if (momentData.format('D') === diaAtual) {
          return `Hoje - ${momentData.format('hh:mm')}`
        } else if (momentData.format('D') === diaAnterior) {
          return `Ontem - ${momentData.format('hh:mm')}`
        }

        return momentData.format('DD/MM - hh:mm')
      }
    }
  }
</script>

<style>
  .v-overlay__scrim {
    height: 100vh !important;
    z-index: 2147483647 !important;
  }

  .v-virtual-scroll::-webkit-scrollbar {
    width: 5px;
    background-color: var(--lightgray);
    border-radius: 1rem;
  }

  .v-virtual-scroll::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 1rem;
  }
</style>
