<template>
  <span>
    <Button
        id="ButtonPix"
        block
        small
        class="secondary"
        @click="abrirDialog"
    >
      PIX
    </Button>

    <v-dialog v-model="dialog" persistent>
      <v-card>
        <v-card-title>
          <span>QR Code</span>
          <v-btn
              icon
              class="ml-auto"
              @click="dialog = false"
          >
            <v-icon dark size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <div
              class="text-center text--inputcolor"
              style="width: 100%"
          >
            <QRCode
                v-if="codigoPix"
                :value="codigoPix"
            />
            <p>Valor: {{ valor }}</p>
            <p>Beneficiário: {{ nomeBeneficiario }}</p>
          </div>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <a @click="copiar">
            <v-icon color="secondary">pc-copiar</v-icon>
            <span class="text--secondary ml-1 text-body-2">PIX copia e cola</span>
          </a>
          <v-spacer></v-spacer>
          <Button
              color="secondary"
              outlined
              style="height: 36px !important"
              @click="dialog = false"
          >
            Voltar
          </Button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
  import { gerarCodigoPix } from '@/service/pix'
  import QRCode from '@chenfengyuan/vue-qrcode'

  export default {
    components: { QRCode },
    props: ['idProjeto', 'statusProjeto', 'pixConfigurado'],
    data: () => ({
      dialog: false,
      codigoPix: '',
      valor: 'R$ 0,00',
      nomeBeneficiario: ''
    }),
    watch: {
      dialog: function (valor) {
        if (valor) {
          this.gerarCodigoPix()
        }
      }
    },
    methods: {
      async gerarCodigoPix () {
        try {
          const { payload, valor, beneficiario } = await gerarCodigoPix(this.idProjeto)
          this.codigoPix = payload
          this.valor = valor
          this.nomeBeneficiario = beneficiario
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      async copiar () {
        try {
          await navigator.clipboard.writeText(this.codigoPix)
          this.$toast.success('Código PIX copiado!')
        } catch (erro) {
          this.$toast.error('Não foi possivel copiar o código PIX.')
        }
      },
      abrirDialog () {
        if (this.pixConfigurado) {
          if (['rascunho', 'em_processo', 'aguardando_analise_credito'].some(status => status === this.statusProjeto)) {
            this.dialog = true
            return
          }

          this.$toast.error('Somente é possível gerar o pix de pagamento para projetos em status de \'Rascunho\', ‘Em processo’ ou ‘Aguardando análise de crédito’. Se for necessário, por favor entre em contato com o Administrador.')
          return
        }

        this.$toast.error('Os dados de pagamento em PIX não estão liberados na plataforma. Tente novamente mais tarde, ou entre em contato com o Administrador, por favor.')
      }
    }
  }
</script>
