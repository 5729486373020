import http from '@/plugins/http'
import { get } from 'lodash'
import { Mensagens } from '@/plugins/constants'

export async function getDashboard (mes, ano) {
  try {
    const { data } = await http.get(`/dashboard?mes=${mes}&ano=${ano}`)
    return data
  } catch (erro) {
    throw get(erro, 'response.data.msg_erro', Mensagens.ERRO)
  }
}
