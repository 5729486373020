<template>
  <v-container fluid class="pt-4">
    <h1 class="titulo">Configurações</h1>
    <div class="cardcolor py-2 rounded">
      <v-tabs
          id="TabConfiguracao"
          v-model="tab"
          hide-slider
          background-color="transparent"
      >
        <v-tab
            id="TabValorPadrao"
            class="text-capitalize rounded-t px-sm-16">
          Valor Padrão
        </v-tab>
        <v-tab
            id="TabPixEstatico"
            class="text-capitalize rounded-t px-sm-16"
            style="margin: 0 1px">
          Pix Estático
        </v-tab>
        <v-tab
            id="TabReservas"
            class="text-capitalize rounded-t px-sm-16">
          Reservas
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-row class="px-sm-10">
                <v-col cols="12" md="6" lg="3">
                  <DecimalTextField
                      id="DecimalTextFieldPercentualComissaoMedia"
                      label="Comissão média"
                      suffix="%"
                      v-model="configuracoes.percentualComissaoMedia"
                      tooltip="Tempo antes da expiração da reserva para que seja exibido o timer regressivo no lugar da data e hora da expiração"
                      maxvalue="100"
                  />
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <DecimalTextField
                      id="DecimalTextFieldPercentualTributo"
                      label="Tributo"
                      suffix="%"
                      v-model="configuracoes.percentualTributo"
                      maxvalue="100"
                  />
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <DecimalTextField
                      id="DecimalTextFieldPercentualTaxaTransferencia"
                      label="Taxa de Transferência"
                      suffix="%"
                      v-model="configuracoes.percentualTaxaTransferencia"
                      maxvalue="100"
                  />
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <DecimalTextField
                      id="DecimalTextFieldPercentualEntrada"
                      label="Porcentagem da Entrada"
                      suffix="%"
                      v-model="configuracoes.percentualEntrada"
                      maxvalue="100"
                  />
                </v-col>
              </v-row>
              <v-row class="px-sm-10">
                <v-col cols="12" md="6" lg="3">
                  <DecimalTextField
                      id="DecimalTextFieldPercentualComissaoParceiro"
                      label="Comissão parceiro"
                      suffix="%"
                      v-model="configuracoes.percentualComissaoParceiro"
                      maxvalue="100"
                  />
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <DecimalTextField
                      id="DecimalTextFieldPercentualComissaoVendedor"
                      label="Comissão Vendedor CLT"
                      suffix="%"
                      v-model="configuracoes.percentualComissaoVendedor"
                      maxvalue="100"
                  />
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <DecimalTextField
                      id="DecimalTextFieldPercentualComissaoSupervisor"
                      label="Comissão supervisor"
                      suffix="%"
                      v-model="configuracoes.percentualComissaoSupervisor"
                      maxvalue="100"
                  />
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <DecimalTextField
                      id="DecimalTextFieldPercentualComissaoGerente"
                      label="Comissão gerente"
                      suffix="%"
                      v-model="configuracoes.percentualComissaoGerente"
                      maxvalue="100"
                  />
                </v-col>
              </v-row>
              <v-row class="px-sm-10">
                <v-col cols="12" md="6" lg="3">
                  <DecimalTextField
                      label="Vl. Máx. Crédito Reservado"
                      v-model="configuracoes.creditoMaximoReservado"
                      maxvalue="99999999999"
                      :rules="[$rules.required, $rules.maior_que_zero]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-form ref="pix">
                <v-row class="px-sm-10">
                  <v-col cols="12" md="6" lg="3">
                    <SelectField
                        id="SelectFieldTipoChavePix"
                        label="Tipo da chave"
                        :items="selectItems"
                        item-text="texto"
                        item-value="valor"
                        v-model="configuracoes.tipoChavePix"
                    />
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <TextField
                        key="1"
                        v-if="chavePixTexto"
                        label="Chave pixo"
                        v-model="configuracoes.chavePix"
                        :rules="validacaoChavePix"
                        maxlength="50"
                    />
                    <TextField
                        v-else
                        label="Chave pix"
                        v-model="configuracoes.chavePix"
                        v-mask="mascaraChavePix"
                        :rules="validacaoChavePix"
                        maxlength="50"
                    />
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <TextField
                        id="TextFieldNomeBeneficiario"
                        ref="pix"
                        label="Nome beneficiário"
                        maxlength="25"
                        mensagem='Até 25 caracteres'
                        v-model="configuracoes.nomeBeneficiario"
                        :rules="validacaoChavePix"
                        v-uppercase
                    />
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <TextField
                        id="TextFieldCidadeBeneficiario"
                        ref="pix"
                        label="Cidade do beneficiário"
                        maxlength="15"
                        mensagem='Até 15 caracteres'
                        v-model="configuracoes.cidadeBeneficiario"
                        :rules="validacaoChavePix"
                        v-uppercase
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-row class="px-sm-10">
                <v-col cols="12" md="6" lg="3">
                  <TextField
                      id="TextFieldHorasExpiracaoReserva"
                      label="Tempo para expiração da reserva (valor em horas)"
                      v-model="configuracoes.horasExpiracaoReserva"
                      v-mask="'####'"
                  />
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <TextField
                      id="TextFieldHorasPenalidadeReserva"
                      label="Penalidade para refazer a reserva (valor em horas)"
                      v-model="configuracoes.horasPenalidadeReserva"
                      v-mask="'####'"
                  />
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <TextField
                      id="TextFieldMinutosExibirContagem"
                      label="Início da contagem regressiva (valor em minutos)"
                      v-model="configuracoes.minutosExibirContagem"
                      v-mask="'####'"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-row justify="end">
        <v-col cols="12" md="3" class="my-3 px-8 mt-md-11 mb-md-7 px-md-8">
          <Button
              id="ButtonSalvar"
              class="secondary"
              style="width: 100%;"
              @click="salvarConfiguracoes">
            Salvar
          </Button>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  import { getConfiguracoes, salvarConfiguracao } from '@/service/configuracao'

  export default {
    name: 'ConfiguracoesGerais',
    data: () => ({
      tab: undefined,
      configuracoes: {
        percentualTributo: 0,
        percentualTaxaTransferencia: 0,
        percentualEntrada: 0,
        percentualComissaoMedia: 0,
        percentualComissaoParceiro: 0,
        percentualComissaoVendedor: 0,
        percentualComissaoSupervisor: 0,
        percentualComissaoGerente: 0,
        tipoChavePix: '',
        chavePix: '',
        nomeBeneficiario: '',
        cidadeBeneficiario: '',
        horasExpiracaoReserva: 0,
        minutosExibirContagem: 0,
        horasPenalidadeReserva: 0,
        creditoMaximoReservado: 0
      }
    }),
    computed: {
      selectItems () {
        return [
          { texto: 'Celular', valor: 'CELULAR' },
          { texto: 'E-mail', valor: 'EMAIL' },
          { texto: 'CNPJ', valor: 'CNPJ' },
          { texto: 'Aleatória', valor: 'ALEATORIA' }
        ]
      },
      mascaraChavePix () {
        switch (this.configuracoes.tipoChavePix) {
          case 'CELULAR':
            return ['(##) ####-####', '(##) #####-####']

          case 'EMAIL':
            return null

          case 'CNPJ':
            return '##.###.###/####-##'

          case 'ALEATORIA':
            return null

          default:
            return null
        }
      },
      chavePixTexto () {
        return this.configuracoes.tipoChavePix === 'EMAIL' || this.configuracoes.tipoChavePix === 'ALEATORIA'
      },
      validacaoChavePix () {
        return this.erroChavePix ? [this.$rules.required] : []
      },
      erroChavePix () {
        if (this.configuracoes.chavePix !== '' || this.configuracoes.nomeBeneficiario !== '' || this.configuracoes.cidadeBeneficiario !== '') {
          if (this.configuracoes.chavePix === '' || this.configuracoes.nomeBeneficiario === '' || this.configuracoes.cidadeBeneficiario === '') {
            return true
          }
        }

        return false
      }
    },
    async mounted () {
      try {
        this.configuracoes = await getConfiguracoes()
      } catch (erro) {
        this.$toast.error(erro)
      }
    },
    methods: {
      async salvarConfiguracoes () {
        try {
          if (this.erroChavePix) {
            this.$toast.error('Campos obrigatórios não preenchidos, verifique o formulário e tente novamente')
            this.$refs.pix.validate()
            return
          }

          await salvarConfiguracao(this.configuracoes)
          this.$toast.success('As configurações foram salvas com sucesso!')
        } catch (erro) {
          this.$toast.error(erro)
        }
      }
    }
  }
</script>

<style>
  .v-tab {
    background: var(--v-primary-base);
    color: var(--v-white-base) !important;
  }

  .v-tab--active {
    background: var(--v-white-base);
    color: var(--v-primary) !important;
  }
</style>
