<template>
  <div>
    <v-list-item>
      <v-list-item-icon>
        <v-icon
            color="labelcolor"
        >
          pc-atencao
        </v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>
          <Chip
              class="justify-left"
              :color="cor"
              :texto="status"
          />
        </v-list-item-title>
        <v-list-item-subtitle
            class="informacao-status">
          Definido por {{ usuario }}
        </v-list-item-subtitle>
        <v-list-item-subtitle
            class="data-hora-status">
          {{ getDataHoraHistoricoStatus(dataHoraStatus) }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-icon>
        <v-btn
            icon
            @click="dialog= true"
            v-if="comentario"
        >
          <v-icon
              color="secondary">
            pc-balao
          </v-icon>
        </v-btn>
      </v-list-item-icon>
    </v-list-item>
    <v-dialog
        content-class="app-content"
        v-model="dialog"
        persistent
        width="360px"
    >
      <v-card>
        <v-card-title>
          <slot name="title">Comentário</slot>
          <v-btn
              icon
              class="ml-auto"
              @click="dialog = false"
          >
            <v-icon
                color="primary"
                size="12">
              pc-fechar
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-5">
          <v-row no-gutters>
            <v-col cols="1" class="mt-1">
              <v-icon color="secondary">pc-balao</v-icon>
            </v-col>
            <v-col cols="10" class="ml-2">
              <label class="comentario">{{ comentario }}</label>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-col cols="6">
            <label
                class="informacao-status">
              {{ getDataHoraHistoricoStatus(dataHoraStatus) }}
            </label>
            <br>
            <label class="informacao-status">{{ usuario }}</label>
          </v-col>
          <v-col cols="6">
            <Button
                small
                outlined
                color="secondary"
                @click="dialog = false"
            >
              Voltar
            </Button>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'CardHistoricoStatus',
    props: {
      cor: String,
      status: String,
      usuario: String,
      dataHoraStatus: String,
      comentario: String
    },
    data: () => ({
      dialog: false
    }),
    methods: {
      getDataHoraHistoricoStatus (dataHora) {
        const dataHoraMoment = moment(dataHora, 'YYYY/MM/DD H:m')
        const HoraMinutoHistoricoStatus = dataHoraMoment.format('HH:mm')

        const dataHoraHoje = Date.now()
        const dataHoraOntem = moment().subtract(1, 'day').toDate()

        if (dataHoraMoment.isSame(dataHoraHoje, 'day')) {
          return `Hoje - ${HoraMinutoHistoricoStatus}`
        } else if (dataHoraMoment.isSame(dataHoraOntem, 'day')) {
          return `Ontem - ${HoraMinutoHistoricoStatus}`
        } else {
          return dataHoraMoment.format('DD/MM - HH:mm')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .justify-left {
    justify-content: left !important;
  }

  .comentario {
    font-size: 16px;
    color: var(--labelcolor);
  }

  .informacao-status {
    font-size: 14px;
    color: var(--inputcolor);
  }

  .data-hora-status {
    font-size: 12px;
    color: var(--inputcolor);
  }
</style>
