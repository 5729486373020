import http from '@/plugins/http'
import { get } from 'lodash'
import { Mensagens } from '@/plugins/constants'

export async function getTipoAnexo () {
  try {
    const { data } = await http.get('/documento-anexo')
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function getAnexoGenerico (idProjeto) {
  try {
    const { data } = await http.get(`/anexo/generico/${idProjeto}`)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function salvarAnexoGenerico (anexo, silent = false) {
  try {
    const { data } = await http.post('/anexo/generico', anexo, { options: { silent: silent } })
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function getAnexo (idProjeto) {
  try {
    const { data } = await http.get(`/anexo/${idProjeto}`)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function salvarAnexo (anexo, silent = false) {
  try {
    const { data } = await http.post('/anexo', anexo, { options: { silent: silent } })
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function deletarAnexo (anexo) {
  try {
    const { data } = await http.delete('/anexo', { data: anexo, options: { silent: true } })
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function deletarAnexoGenerico (id) {
  try {
    const { data } = await http.delete(`/anexo/generico/${id}`, { options: { silent: true } })
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function getAnexoIndividual (idAnexoServidor) {
  try {
    const { data } = await http.get(`/anexo/azure/${idAnexoServidor}`)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function getConteudoAnexo (url) {
  try {
    const { data } = await http.get(`/anexo/azure/conteudo?url=${url}`)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function getAnexoFinalizado (idProjeto) {
  try {
    const { data } = await http.get(`/anexo/finalizado/${idProjeto}`)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}
