import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'

export async function getConfiguracoes () {
  try {
    const { data } = await http.get('/configuracao')
    return data
  } catch (erro) {
    throw Mensagens.ERRO
  }
}

export async function salvarConfiguracao (configuracoes) {
  try {
    await http.post('/configuracao', configuracoes)
  } catch (erro) {
    throw Mensagens.ERRO
  }
}

export async function buscarConfiguracoesPublicas () {
  try {
    return await http.get('/configuracao/publica')
  } catch (erro) {
    throw Mensagens.ERRO
  }
}
