<template>
  <span>
    <a
        style="text-decoration: none"
        @click="dialog = true"
    >
      <v-icon
          large
          color="black"
      >
        pc-excluir
      </v-icon>
    </a>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="100px"
    >
      <v-card>
        <v-card-title>
          <span>Remover Reserva</span>
          <v-btn
              icon
              class="ml-auto"
              @click="dialog = false"
          >
            <v-icon dark size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions class="mt-5 text--black">
          <div>
            <p>Deseja realmente remover a reserva desse vendedor e deixar a cota disponível para venda?</p>
          </div>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <Button
              color="secondary"
              outlined
              style="height: 36px !important"
              @click="dialog = false"
          >
            Cancelar
          </Button>
          <Button
              color="secondary"
              style="height: 36px !important"
              @click="cancelarReserva"
          >
            Confirmar
          </Button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
  import { cancelarReserva } from '@/service/reserva'

  export default {
    props: ['idCota', 'idUsuario', 'podeCancelar'],
    data: () => ({
      dialog: false
    }),
    methods: {
      async cancelarReserva () {
        if (!this.podeCancelar) {
          this.$toast.error('A cota está em processo de venda com um projeto, então a reserva não pode ser cancelada')
          return
        }

        try {
          await cancelarReserva({
            idCotas: [this.idCota],
            idUsuario: this.idUsuario
          })

          this.$toast.success('Feito! Reserva alterada')
          this.$emit('cancelar', null)
        } catch (erro) {
          this.$toast.error(erro.message)
        }

        this.dialog = false
      }
    }
  }
</script>
