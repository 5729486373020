import http from '@/plugins/http'
import { get } from 'lodash'
import { Mensagens } from '@/plugins/constants'

export async function getProdutos () {
  try {
    const { data } = await http.get('/produto')
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}
