<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col>
        <h1 class="titulo">Dashboard</h1>
      </v-col>
      <v-col class="align-center" sm="4" lg="3" xl="2">
        <DatePicker
            id="DatePickerDashboard"
            :data-inicial="data"
            @data="data = $event"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col
          cols="6"
          sm="4"
          lg="2"
      >
        <v-card
            elevation="0"
            height="100%"
            color="primary"
            rounded="lg"
        >
          <v-card-text class="text--white">
            <v-icon
                color="lightprimary"
                size="35"
            >
              pc-vender
            </v-icon>
            <div class="text-h5 font-weight-bold mt-4">{{ cards.projetosVendidos }}</div>
            <div class="font-weight-bold">projetos vendidos</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
          cols="6"
          sm="4"
          lg="2"
      >
        <v-card
            elevation="0"
            height="100%"
            color="primary"
            rounded="lg"
        >
          <v-card-text class="text--white">
            <v-icon
                color="lightprimary"
                size="35"
            >
              pc-cotas-contempladas
            </v-icon>
            <div class="text-h5 font-weight-bold mt-4">{{ cards.cotasVendidas }}</div>
            <div class="font-weight-bold">cotas vendidas</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
          cols="6"
          sm="4"
          lg="2"
      >
        <v-card
            elevation="0"
            height="100%"
            color="primary"
            rounded="lg"
        >
          <v-card-text class="text--white">
            <v-icon
                color="lightprimary"
                size="35"
            >
              pc-remover
            </v-icon>
            <div class="text-h5 font-weight-bold mt-4">{{ cards.projetosNegados }}</div>
            <div class="font-weight-bold">projetos negados</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
          cols="6"
          sm="6"
          lg="2"
      >
        <v-card
            elevation="0"
            height="100%"
            color="primary"
            rounded="lg"
        >
          <v-card-text class="text--white">
            <v-icon
                color="lightprimary"
                size="35"
            >
              pc-voltar
            </v-icon>
            <div class="text-h5 font-weight-bold mt-4">{{ cards.projetosEstornados }}</div>
            <div class="font-weight-bold">projetos estornados</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
          cols="12"
          sm="6"
          lg="4"
      >
        <v-card
            elevation="0"
            height="100%"
            color="primary"
            rounded="lg"
        >
          <v-card-text class="text--white">
            <v-icon
                color="lightprimary"
                size="35"
            >
              pc-lampada
            </v-icon>
            <div class="font-weight-bold mt-4">
              R$ <span class="text-h5 font-weight-bold">{{ formatarDinheiro(cards.creditoVendido) }}</span>
            </div>
            <div class="font-weight-bold">em crédito</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card
            elevation="0"
            rounded="lg"
        >
          <v-card-text
              class="primary text--white"
          >
            <v-flex
                class="px-5 py-4"
                row
            >
              <v-icon
                  color="lightprimary"
                  size="35"
                  class="mr-5"
              >
                pc-trofeu
              </v-icon>
              <div>
                <div class="text-subtitle-2">Ranking vendedor</div>
                <div>por quantidade de vendas</div>
              </div>
            </v-flex>
          </v-card-text>
          <DataTable
              elevation="0"
              :headers="tabela.cabecalho"
              :itens-externos="tabela.ranking"
              :show-pagination=false
              :reload-table="reload"
              item-key="key"
          >
            <template v-slot:[`item.posicao`]="item">
              <td>
                <span class="colocacao">
                  {{ item.posicao }}
                </span>
              </td>
            </template>
            <template v-slot:[`item.nome`]="item">
              <td>
                <span :class="isPrincipal(item.perfil) ? 'font-weight-black' : ''">{{ item.nome }}</span>
                <div class="text-caption text-lightgray">{{ item.cdComissionado }}</div>
              </td>
            </template>
            <template v-slot:[`item.totalVendido`]="item">
              <td>R$ {{ formatarDinheiro(item.totalVendido) }}</td>
            </template>
            <template v-slot:[`item.quantidadeProjetosVendidos`]="item">
              <td>{{ item.quantidadeProjetosVendidos }}</td>
            </template>
            <template v-slot:[`item.quantidadeCotasVendidas`]="item">
              <td>{{ item.quantidadeCotasVendidas }}</td>
            </template>
          </DataTable>
          <b>* Nomes destacados significam que a pessoa é comissionado principal</b>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { getDashboard } from '@/service/dashboard'
  import { money } from '@/plugins/formatters'
  import { TipoPerfilNewcon } from '../../../../plugins/constants'
  import store from '@/store'

  export default {
    name: 'Dashboard',
    data: () => ({
      cards: {
        projetosVendidos: 0,
        cotasVendidas: 0,
        projetosNegados: 0,
        projetosEstornados: 0,
        creditoVendido: 0
      },
      data: {
        mes: new Date(Date.now()).getMonth() + 1,
        ano: new Date(Date.now()).getFullYear(),
        reload: ''
      },
      tabela: {
        cabecalho: [
          { text: '#', value: 'posicao', width: '5%' },
          { text: store.state['perfil'] === 'adm' ? 'Parceiro/Franqueado' : 'Login/Sublogin', value: 'nome' },
          { text: 'Total vendido', value: 'totalVendido' },
          { text: 'Projetos vendidos', value: 'quantidadeProjetosVendidos' },
          { text: 'Cotas vendidas', value: 'quantidadeCotasVendidas' }
        ],
        ranking: []
      }
    }),
    watch: {
      data () {
        this.getDados()
      }
    },
    mounted () {
      this.getDados()
    },
    methods: {
      isPrincipal (perfil) {
        if (this.$route.name !== 'DashboardAdm') {
          return perfil === TipoPerfilNewcon.FRANQUEADO || perfil === TipoPerfilNewcon.PARCEIRO
        }

        return false
      },
      async getDados () {
        try {
          const dashboard = await getDashboard(this.data.mes, this.data.ano)

          this.cards.projetosVendidos = dashboard.quantidadeProjetosVendidos
          this.cards.cotasVendidas = dashboard.quantidadeCotasVendidas
          this.cards.projetosNegados = dashboard.quantidadeProjetosNegados
          this.cards.projetosEstornados = dashboard.quantidadeProjetosEstornados
          this.cards.creditoVendido = dashboard.valorTotalCreditoVendido
          this.tabela.ranking = dashboard.rankingParceiros.map((item, index) => {
            item.posicao = index + 1
            item.key = new Date().getTime()
            return item
          })
          this.reload = new Date().getTime().toString()
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      formatarDinheiro (valor) {
        return money(valor)
      }
    }
  }
</script>

<style scoped>
  .align-center {
    align-self: flex-end;
  }

  .colocacao {
    font-size: 20px !important;
    color: var(--black) !important;
    font-weight: 600 !important;
  }
</style>
