import Vue from 'vue'
import VueRouter from 'vue-router'
import session from './session'
import Login from '@/views/Login'
import Callback from '@/views/Callback'
import Anexo from '@/views/Anexo'
import Sistema from '@/views/Sistema/Sistema'
import Dashboard from '@/views/Sistema/CotasContempladas/Gestao/Dashboard'
import GestaoCotasContempladas from '@/views/Sistema/CotasContempladas/Gestao/GestaoCotasContempladas'
import DetalheCota from '@/views/Sistema/CotasContempladas/Gestao/DetalheCota'
import Projetos from '@/views/Sistema/CotasContempladas/Gestao/Projetos'
import GestaoDetalheProjeto from '@/views/Sistema/CotasContempladas/Gestao/GestaoDetalheProjeto'
import VerSublogins from '@/views/Sistema/CotasContempladas/Gestao/VerSublogins'
import MinhasReservas from '@/views/Sistema/CotasContempladas/Vender/MinhasReservas'
import AberturaProjeto from '@/views/Sistema/CotasContempladas/Vender/AberturaProjeto'
import MeusProjetos from '@/views/Sistema/CotasContempladas/Vender/MeusProjetos'
import VenderDetalheProjeto from '@/views/Sistema/CotasContempladas/Vender/VenderDetalheProjeto'
import ConfiguracoesGerais from '@/views/Sistema/CotasContempladas/Configuracao/ConfiguracoesGerais'
import ListaUsuarios from '@/views/Sistema/CotasContempladas/Configuracao/ListaUsuarios'
import Exportar from '@/views/Sistema/CotasContempladas/Configuracao/Exportar'
import CotasDisponiveisReserva from '@/views/Sistema/CotasContempladas/Vender/CotasDisponiveisReserva'
import LogoutCallback from '@/views/Logout'
import { TipoPerfil } from '@/plugins/constants'

Vue.use(VueRouter)

// Esse objeto contém as rotas estruturadas em uma hierarquia. Esse formato é usado para gerar o menu lateral
// dinamicamente
export const nestedRoutes = [
  {
    path: '/cotas-contempladas',
    menuTitle: 'Cotas contempladas',
    menuIcon: 'pc-cotas-contempladas',
    children: [
      {
        path: '/gestao',
        menuTitle: 'Gestão',
        menuIcon: 'pc-gestao',
        perfisPermitidos: [TipoPerfil.ADMINISTRATIVO],
        children: [
          {
            path: '/dashboard',
            name: 'DashboardAdm',
            menuTitle: 'Dashboard',
            component: Dashboard,
            meta: { title: 'Dashboard' }
          },
          {
            path: '/cotas',
            name: 'GestaoCotasContempladas',
            menuTitle: 'Cotas contempladas',
            component: GestaoCotasContempladas,
            meta: { title: 'Cotas' }
          },
          {
            path: '/detalhe-cota',
            name: 'DetalheCota',
            component: DetalheCota,
            meta: { title: 'Detalhe cota' },
            exibirMenu: false
          },
          {
            path: '/projetos',
            name: 'GestaoProjetos',
            menuTitle: 'Projetos',
            component: Projetos,
            meta: { title: 'Projetos' }
          },
          {
            path: '/detalhe-projeto',
            name: 'GestaoDetalheProjeto',
            component: GestaoDetalheProjeto,
            meta: { title: 'Detalhe projeto' },
            exibirMenu: false
          },
          {
            path: '/sublogins',
            name: 'VerSubloginsAdm',
            menuTitle: 'Ver sub-logins',
            component: VerSublogins,
            props: { url: 'sub-logins/ver' },
            meta: { title: 'Sub-logins' }
          }
        ]
      },
      {
        path: '/vender',
        menuTitle: 'Vender',
        menuIcon: 'pc-vender',
        perfisPermitidos: [TipoPerfil.ADMINISTRATIVO, TipoPerfil.PARCEIRO, TipoPerfil.FRANQUEADO, TipoPerfil.SUBLOGIN],
        children: [
          {
            path: '/cotas-disponiveis-reserva',
            name: 'cotas-disponiveis-reserva',
            menuTitle: 'Cotas contempladas',
            component: CotasDisponiveisReserva,
            meta: { title: 'Cotas contempladas' }
          },
          {
            path: '/reservas',
            name: 'MinhasReservas',
            menuTitle: 'Minhas Reservas',
            component: MinhasReservas,
            meta: { title: 'Reservas' }
          },
          {
            path: '/abertura-projeto',
            name: 'AberturaProjeto',
            component: AberturaProjeto,
            meta: { title: 'Abertura de projeto' },
            exibirMenu: false
          },
          {
            path: '/projetos',
            name: 'MeusProjetos',
            menuTitle: 'Meus Projetos',
            component: MeusProjetos,
            meta: { title: 'Projetos' }
          },
          {
            path: '/detalhe-projeto',
            name: 'VenderDetalheProjeto',
            component: VenderDetalheProjeto,
            meta: { title: 'Detalhe projeto' },
            exibirMenu: false
          }
        ]
      },
      {
        path: '/configuracoes',
        menuTitle: 'Configurações',
        menuIcon: 'pc-configuracao',
        perfisPermitidos: [TipoPerfil.ADMINISTRATIVO],
        children: [
          {
            path: '/geral',
            name: 'ConfiguracoesGerais',
            menuTitle: 'Geral',
            component: ConfiguracoesGerais,
            meta: { title: 'Configurações' }
          },
          {
            path: '/usuarios',
            name: 'ConfiguracoesUsuarios',
            menuTitle: 'Usuários',
            component: ListaUsuarios,
            meta: { title: 'Usuários' }
          },
          {
            path: '/exportar',
            name: 'Exportar',
            menuTitle: 'Exportar',
            component: Exportar,
            meta: { title: 'Exportar' }
          }
        ]
      },
      {
        path: '/gestao-sublogins',
        menuTitle: 'Gestão de Sub-logins',
        menuIcon: 'pc-perfil',
        perfisPermitidos: [TipoPerfil.PARCEIRO, TipoPerfil.FRANQUEADO],
        children: [
          {
            path: '/dashboard',
            name: 'Dashboard',
            menuTitle: 'Dashboard',
            component: Dashboard,
            meta: { title: 'Dashboard' }
          },
          {
            path: '/sublogins',
            name: 'VerSublogins',
            menuTitle: 'Ver sub-logins',
            component: VerSublogins,
            meta: { title: 'Sub-logins' }
          }
        ]
      }
    ]
  }
]

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { title: 'Login', public: true }
  },
  {
    path: '/auth/callback',
    name: 'callback',
    component: Callback,
    meta: { title: 'Callback', public: true }
  },
  {
    path: '/auth/logout',
    name: 'LogoutCallback',
    component: LogoutCallback,
    meta: { title: 'Logout', public: true }
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { title: 'Sair' },
    beforeEnter: (to, from, next) => {
      session.destroy()
      next({ name: 'login' })
    }
  },
  {
    path: '/anexo',
    name: 'Anexo',
    component: Anexo,
    meta: { title: 'Anexo' }
  },
  {
    path: '/',
    component: Sistema,
    meta: { title: 'Sistema' },
    children: [
      {
        path: '/',
        redirect: () => {
          return session.get('perfil') === TipoPerfil.ADMINISTRATIVO ? '/cotas-contempladas/gestao/dashboard' : '/cotas-contempladas/vender/cotas-disponiveis-reserva'
        }
      },
      ...flattenRoutes(nestedRoutes)
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  // noinspection JSUnresolvedVariable
  document.title = to.meta.title || ''
  checkSession(to, next)
})

function checkSession (to, next) {
  if (session.exists()) {
    if (to.name === 'login') {
      next({ path: '/' })
    } else {
      if (hasPerfilAcesso(to)) {
        next()
      } else {
        Vue.$toast.error('Acesso não autorizado.')
      }
    }
  } else if (!to.meta.public) {
    next({ name: 'login' })
  } else {
    next()
  }
}

function hasPerfilAcesso (to) {
  let permitir = true
  nestedRoutes.forEach((route) => {
    route.children.forEach((child, index) => {
      if (to.path.includes(child.path)) {
        permitir = child.perfisPermitidos.includes(session.get('perfil'))
      }
    })
  })
  return permitir
}

function flattenRoutes (routes, prefix = null) {
  return routes.flatMap(route => {
    if (prefix) {
      route.path = prefix + route.path
    }
    if (route.children) {
      return flattenRoutes(route.children, route.path)
    }
    return route
  })
}

export default router
