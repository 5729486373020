import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import storage from './storage'
import { pick } from 'lodash'
import { persistentes, nao_persistentes } from './data'

Vue.use(Vuex)

const key = process.env.VUE_APP_SECURED ? '0fad0a812088c8ba8d877cbefc90d8ff' : 'portal-corretora'

const vuexLocal = new VuexPersistence({
  key,
  storage,
  reducer (state) {
    return pick(state, Object.keys(persistentes))
  }
})

function defaultState () {
  return {
    ...persistentes,
    ...nao_persistentes
  }
}

const state = defaultState()

let loading = 0
let alertStack = []

const mutations = {
  destroy () {
    store.replaceState(defaultState())
  },
  setState (state, data) {
    Object.assign(state, data)
  },
  setAutenticar (state, promise) {
    if (promise) {
      state.autenticar = true
      state.senha_invalida = !!promise.senha_invalida
      state.retry_resolvers.push(promise)
    } else {
      state.autenticar = false
      state.senha_invalida = false
      state.retry_resolvers = []
    }
  },
  loadingStart (state) {
    state.loading = !!++loading
  },
  loadingDone (state) {
    if (loading > 0) {
      state.loading = !!--loading
    }
  },
  alertShow (state, options) {
    if (state.alert.visible) {
      alertStack.push(options)
    } else {
      state.alert = { options, visible: true }
    }
  },
  alertHide (state) {
    state.alert.visible = false
    if (alertStack.length) {
      setTimeout(() => {
        store.commit('alertShow', alertStack.shift())
      }, 150)
    }
  },
  setRegarregar (state) {
    state.recarregar = !state.recarregar
  },
  setDrawer (state, visible) {
    state.drawer = visible
  },
  setAutenticacaoMultifator (state, value) {
    state.autenticacaoMultifator = value
  }
}

const actions = {}

const plugins = [ vuexLocal.plugin ]

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  plugins
})

export default store
