<template>
  <v-container fluid>
    <h1 class="titulo">Ver Sub-logins</h1>
    <v-row>
      <v-col cols="12" sm="4" lg="4" md="4" xl="4">
        <FilterTextField
            id="FilterTextFieldBusca"
            v-model="buscar"
            maxlength="30"
            placeholder="Login/Código"
            tooltip="A busca deve ser realizada através do login ou do código do Parceiro ou e do franqueado"
            v-uppercase
        />
      </v-col>
    </v-row>
    <DataTable
        class="mt-5 data-table-overlow"
        :url="url"
        :filter="filters"
        :headers="headers"
        :params="params"
        item-key="id"
        @onLoad="verificarMsgErro($event)"
    >
      <template v-slot:[`item.id`]="item">
        <td style="text-align: center">{{ item.id }}</td>
      </template>
      <template v-slot:[`item.login`]="item">
        <td>{{ item.login }}</td>
      </template>
      <template v-slot:[`item.nome`]="item">
        <td>{{ item.nome }}</td>
      </template>
      <template v-slot:[`item.loginComissionado`]="item">
        <td>{{ item.loginComissionado }}</td>
      </template>
      <template v-slot:[`item.perfil`]>
        <td>Sub-login</td>
      </template>
      <template v-slot:[`item.bloqueado`]="item">
        <td>
          <v-switch
              v-if="item.id"
              :id="`Switch_${item.id}`"
              inset
              v-model="item.bloqueado"
              @change="toggleBloqueado(item.id, item.bloqueado)"
          />
        </td>
      </template>
    </DataTable>
  </v-container>
</template>

<script>
  import DataTable from '../../../../components/DataTable.vue'
  import FilterTextField from '../../../../components/FilterTextField.vue'
  import { getFilters } from '../../../../plugins/helpers'
  import { bloqueioUsuario } from '@/service/usuario'
  import session from '@/plugins/session'
  import { syncSublogins } from '../../../../service/sublogin'
  import { TipoPerfil } from '../../../../plugins/constants'

  export default {
    name: 'VerSublogins',
    components: {
      DataTable,
      FilterTextField
    },
    props: {
      url: {
        type: String,
        default: 'sub-logins/ver'
      }
    },
    data: () => ({
      filters: {},
      headers: [
        { text: 'Cod. sub-login', value: 'id', width: 120 },
        { text: 'Usuário', value: 'login', width: 120 },
        { text: 'Nome', value: 'nome', width: 180 },
        { text: 'Login', value: 'loginComissionado', width: 120 },
        { text: 'Perfil', value: 'perfil', width: 100 },
        { text: 'Bloqueado', value: 'bloqueado', width: 100 }
      ],
      pagination: {
        sortBy: [ 'id' ], sortDesc: [ false ]
      },
      buscar: ''
    }),
    computed: {
      params () {
        return { pagination: this.pagination, administrator: true }
      }
    },
    watch: {
      buscar () {
        if (this.buscar.length >= 2 || this.buscar.length === 0) {
          this.filters = getFilters(this.buscar)
        }
      },
      '$store.state.recarregar': function () {
        this.tabela.filtros = getFilters('')
      }
    },
    mounted () {
      if (session.get('perfil') !== TipoPerfil.ADMINISTRATIVO) {
        syncSublogins()
          .then(res => {
            if (res) {
              this.$toast.success(res)
            }
          })
          .catch(() => this.$toast.error('Algo deu errado. Não foi possível fazer a verificação de atualizações de seus sub logins'))
      }
    },
    methods: {
      verificarMsgErro (items) {
        if (items[0].msg) {
          this.$toast.error(items[0].msg)
        }
      },
      async toggleBloqueado (codSublogin, bloqueado) {
        try {
          await bloqueioUsuario(codSublogin, bloqueado)
        } catch (erro) {
          this.$toast.error(erro)
        }
      }
    }
  }
</script>

<style>

</style>
