export const Mensagens = {
  ERRO: 'Algo deu errado. O servidor não está respondendo conforme esperado. Tente novamente mais tarde.',
  ERRO_ENVIO_ANEXO: 'Algo deu errado. Não foi possível enviar com sucesso algum dos documentos. Acesse o projeto salvo, e tente realizar o upload novamente.'
}

export const TipoExibicaoCota = {
  EXIBIR_DATA_HORA: 'data_hora',
  EXIBIR_CONTAGEM_REGRESSIVA: 'contagem_regressiva',
  EXIBIR_DISPONIVEL: 'disponivel'
}

export const TipoPerfil = {
  ADMINISTRATIVO: 'adm',
  PARCEIRO: 'parceiro',
  FRANQUEADO: 'franqueado',
  VENDEDOR: 'vendedor',
  SUBLOGIN: 'sublogin'
}

export const TipoPerfilNewcon = {
  CLT: 'clt',
  PARCEIRO: 'parceiro',
  FRANQUEADO: 'franqueado',
  COLABORADOR: 'colaborador',
  SUBLOGIN: 'sublogin'
}

export const TipoNaturezaAnexo = {
  AMBOS: 'ambos',
  FISICA: 'fisica',
  JURIDICA: 'juridica'
}

export const StatusCota = {
  EM_PROJETO: 'em_projeto',
  INATIVA: 'inativa',
  ATIVA: 'ativa',
  RESERVADA: 'reservada',
  VENDIDA: 'vendida',
  INDISPONIVEL: 'indisponivel',
  TRANSFERIDA: 'transferida'
}

export const StatusProjeto = {
  EM_PROCESSO: 'em_processo',
  RASCUNHO: 'rascunho',
  AGUARDANDO_ANALISE_CREDITO: 'aguardando_analise_credito',
  AGUARDANDO_DOCUMENTO: 'aguardando_documento',
  REANALISE_DOCUMENTO: 'reanalise_documento',
  APROVADO: 'aprovado',
  NEGADO: 'negado',
  ESTORNADO: 'estornado',
  CANCELADO: 'cancelado',
  CONCLUIDO: 'concluido'
}

export const TipoAnexoFinal = {
  CONTRATO_VENDA: 'Contrato de venda',
  COMPROVANTE_ESTORNO: 'Comprovante de estorno'
}
