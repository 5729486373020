<template>
  <TextField ref="field"
             :label="label"
             v-model="lazyValue"
             v-bind="$attrs"
             v-on="listeners"
             class="decimal"
             text-color="inputcolor"
             :tooltip="tooltip" />
</template>

<script>
  import { get, omit } from 'lodash'

  export default {
    name: 'DecimalTextField',
    props: {
      value: { type: [ Number, String ], default: 0.00 },
      options: { type: Object, default: () => ({ locale: 'pt-BR', prefix: '', precision: 2, suffix: '' }) },
      maxvalue: { type: [ Number, String ], default: 0 },
      label: String,
      tooltip: String,
      permitirNumeroNegativo: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      lazyValue: null
    }),
    computed: {
      listeners () {
        return omit(this.$listeners, 'input')
      }
    },
    watch: {
      lazyValue () {
        this.$nextTick(() => {
          const machineValue = this.machineFormat(this.lazyValue)
          this.lazyValue = this.humanFormat(machineValue)
          this.$emit('input', machineValue)
        })
      },
      value (val) {
        if (!isNaN(this.value)) {
          this.lazyValue = this.humanFormat(val)
        }
      }
    },
    mounted () {
      this.lazyValue = this.value

      if (this.value && !isNaN(this.value) && parseFloat(this.value) > 0) {
        this.lazyValue = this.humanFormat(this.value)
      }
    },
    methods: {
      onlyNumber (value) {
        const regex = (this.permitirNumeroNegativo) ? /[^-0-9]+/g : /[^0-9]+/g
        return (value && String(value).replace(regex, '')) || 0.00
      },
      humanFormat (value) {
        value = isNaN(value) ? '' : Number(value).toLocaleString(this.options.locale, {
          maximumFractionDigits: this.options.precision,
          minimumFractionDigits: this.options.precision
        })
        return value
      },
      machineFormat (value) {
        value = this.onlyNumber(value)
        return this.applyPrecision(value)
      },
      applyPrecision (value) {
        if (value) {
          const precision = get(this.options, 'precision', 2)
          value = this.applyPrecisionLength(value, precision)
          value = this.splice(value, value.length - this.options.precision, 0, '.')
          value = this.validatePrecisionValue(value)
          value = this.applyMaxValue(value, precision)
        }
        return value
      },
      applyPrecisionLength (value, precision) {
        if (value.length < (precision + 1)) {
          return value.padStart(precision + 1, '0')
        }
        return value
      },
      applyMaxValue (value, precision) {
        if (parseFloat(this.maxvalue) > 0 && parseFloat(value) > parseFloat(this.maxvalue)) {
          return parseFloat(this.maxvalue).toFixed(precision)
        }
        return value
      },
      validatePrecisionValue (value) {
        if (value.length > 4 && value.slice(0, 1) === '0') {
          return value.slice(1, value.length)
        }
        return value
      },
      splice (value, start, delCount, newSubStr) {
        return value.slice(0, start) + newSubStr + value.slice(start + Math.abs(delCount))
      },
      reset () {
        this.$refs.field.reset()
      }
    }
  }
</script>
