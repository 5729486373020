<template>
  <v-container
      fluid
      class="text--black pt-0"
  >
    <h2>Vínculo</h2>
    <v-row
        class="mt-7"
    >
      <v-col
          v-if="reserva"
          cols="12"
          md="6"
          lg="4"
          xl="3"
      >
        <v-card
            class="rounded-lg"
            style="height: 100%"
            :disabled="podeDesabilitarCota()"
        >
          <v-card-title>
            <v-icon
                color="secondary"
                class="mr-2"
            >
              pc-etiqueta
            </v-icon>
            Reserva
          </v-card-title>
          <v-card-text class="text--black">
            <div>
              Vendedor: <span class="font-weight-bold">{{ reserva.nomeVendedor }}</span>
            </div>
            <div>
              Expira em: <span class="font-weight-bold">{{ toDateTimeBR(reserva.dataExpiracao) }}</span>
            </div>
          </v-card-text>
          <v-card-actions>
            <AlterarVendedor
                v-if="!reservaExpirada"
                :nome-vendedor="reserva.nomeVendedor"
                :id-cota="idCota"
                :id-usuario="reserva.idUsuario"
            />
            <a
                v-else
                class="text-subtitle-1"
                style="text-decoration: underline"
                @click="alterarCotaExpirada"
            >
              Alterar vendedor
            </a>
            <CancelarReserva
                :id-cota="idCota"
                :id-usuario="reserva.idUsuario"
                class="ml-auto"
                @cancelar="reserva = $event"
                :pode-cancelar="!projeto"
            />
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
          v-if="projeto"
          cols="12"
          md="6"
          lg="4"
          xl="3"
      >
        <v-card
            class="rounded-lg"
            style="height: 100%"
        >
          <v-card-title>
            <v-icon
                color="secondary"
                class="mr-2"
            >
              pc-documento
            </v-icon>
            Projeto
            <v-chip
                label
                :color="projeto.status.cor"
                class="ml-auto text-caption text--white"
            >
              {{ projeto.status.descricao }}
            </v-chip>
          </v-card-title>
          <v-card-text class="text--black">
            <div>
              Vendedor: <span class="font-weight-bold">{{ projeto.nomeVendedor }}</span>
            </div>
            <div>
              Status atualizado em: <span class="font-weight-bold">{{ toDateTimeBR(projeto.dataStatus) }}</span>
            </div>
          </v-card-text>
          <v-card-actions>
            <a
                class="text-subtitle-1"
                style="text-decoration: underline"
                @click="detalheProjeto"
            >
              Acessar projeto
            </a>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { getCotaReservada } from '@/service/reserva'
  import { getProjetoCota, getStatusProjeto } from '@/service/projeto'
  import AlterarVendedor from '@/components/AlterarVendedor'
  import CancelarReserva from '@/components/CancelarReserva'
  import { toDateTimeBR } from '@/plugins/formatters'

  export default {
    components: {
      AlterarVendedor,
      CancelarReserva
    },
    props: ['idCota'],
    data: () => ({
      reserva: null,
      projeto: null,
      statusProjeto: [],
      toDateTimeBR: toDateTimeBR
    }),
    computed: {
      reservaExpirada () {
        if (this.reserva) {
          const dataExpiracao = new Date(this.reserva.dataExpiracao)

          if (new Date(Date.now()).getTime() < dataExpiracao.getTime()) {
            return false
          }
        }

        return true
      }
    },
    async mounted () {
      await this.getStatusprojeto()
      await this.getReserva()
      await this.getProjeto()
      this.$emit('nomeVendedor', this.reserva.nomeVendedor ?? this.projeto.nomeVendedor)
    },
    methods: {
      async getStatusprojeto () {
        try {
          this.statusProjeto = await getStatusProjeto(this.idCota)
        } catch (erro) {
          this.$toast.error(erro.message)
        }
      },
      async getReserva () {
        try {
          this.reserva = await getCotaReservada(this.idCota)
        } catch (erro) {
          this.$toast.error(erro.message)
        }
      },
      async getProjeto () {
        try {
          this.projeto = await getProjetoCota(this.idCota)
          this.statusProjeto.map(status => {
            if (this.projeto.status === status.status) {
              this.projeto.status = {
                cor: status.cor,
                descricao: status.descricao
              }
            }
          })
        } catch (erro) {
          this.$toast.error(erro.message)
        }
      },
      podeDesabilitarCota () {
        return this.projeto !== ''
      },
      detalheProjeto () {
        this.$router.push({ name: 'GestaoDetalheProjeto', params: { idProjeto: this.projeto.idProjeto } })
      },
      alterarCotaExpirada () {
        this.$toast.error('Algo deu errado. Alguma de suas reservas parece não estar mais ativa. Atualize sua página e tente novamente, por favor.')
      }
    }
  }
</script>
