<template>
  <v-container fluid>
    <v-row no-gutters>
      <h1 class="titulo">Detalhes do projeto</h1>
      <Chip
          id="ChipStatus"
          class="ml-2"
          :color="getCorStatus(projeto.statusProjeto)"
          :texto="getDescricaoStatus(projeto.statusProjeto)"
      />
    </v-row>
    <v-card flat>
      <v-card-text class="cardcolor py-5 px-5 rounded">
        <v-row justify="end">
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="12" lg="4" md="4" xl="4">
                <label class="texto-detalhe">Número do projeto:</label>
                <label class="valor-detalhe">{{ projeto.idProjeto }}</label>
              </v-col>
              <v-col cols="12" sm="12" lg="4" md="4" xl="4">
                <label class="texto-detalhe">Bem:</label>
                <label class="valor-detalhe">{{ projeto.bem }}</label>
              </v-col>
              <v-col cols="12" sm="12" lg="4" md="4" xl="4">
                <label class="texto-detalhe">Vendedor:</label>
                <label class="valor-detalhe">{{ projeto.nomeVendedor }}</label>
              </v-col>
            </v-row>
            <v-divider class="mt-5 d-none d-sm-block" />
          </v-col>
          <v-col cols="12" sm="12" lg="4" md="4" xl="4">
            <v-flex>
              <label class="texto-detalhe">Valor total da entrada:</label>
              <label class="valor-detalhe">{{ real(projeto.entradaOriginal) }}</label>
            </v-flex>
            <v-flex class="container-valor">
              <label class="texto-detalhe">Nome/Razão:</label>
              <label class="valor-detalhe">{{ projeto.nomeCliente }}</label>
            </v-flex>
            <v-flex class="container-valor">
              <label class="texto-detalhe">CPF/CNPJ:</label>
              <label class="valor-detalhe">{{ formatarCpfOuCnpj(projeto.cpfCnpj) }}</label>
            </v-flex>
          </v-col>
          <v-col cols="12" sm="12" lg="4" md="4" xl="4">
            <v-flex>
              <label class="texto-detalhe">Crédito negociado:</label>
              <label class="valor-detalhe">{{ real(projeto.creditoTotal) }}</label>
            </v-flex>
            <v-flex class="container-valor">
              <label class="texto-detalhe">Telefone:</label>
              <label class="valor-detalhe">{{ formatarCelular(projeto.telefone) }}</label>
            </v-flex>
            <v-flex class="container-valor">
              <label class="texto-detalhe">E-mail:</label>
              <label class="valor-detalhe">{{ projeto.email }}</label>
            </v-flex>
          </v-col>
          <v-col cols="12" sm="12" lg="4" md="4" xl="4">
            <v-flex>
              <label class="texto-detalhe">Inserida na plataforma em:</label>
              <label class="valor-detalhe">{{ formatarDataHoraBr(projeto.dataProjeto) }}</label>
            </v-flex>
            <v-flex class="container-valor">
              <label class="texto-detalhe">Status atualizado em:</label>
              <label class="valor-detalhe">{{ formatarDataHoraBr(projeto.dataStatus) }}</label>
            </v-flex>
            <v-flex v-if="projeto.pessoaContato" class="container-valor">
              <label class="texto-detalhe">Pessoa de contato:</label>
              <label class="valor-detalhe">{{ projeto.pessoaContato }}</label>
            </v-flex>
          </v-col>
          <v-col cols="12" sm="12" md="2" lg="2" xl="2">
            <Pix
                :id-projeto="projeto.idProjeto"
                :status-projeto="projeto.statusProjeto"
                :pix-configurado="pixConfigurado"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <h1 class="titulo">Cotas negociadas</h1>
    <DataTable
        class="mt-5 data-table-overlow"
        :headers="headers"
        :itens-externos="cotasNegociadas"
        :show-pagination=false
        item-key="idCota"
    >
      <template v-slot:[`item.cdGrupoCota`]="item">
        <td>{{ item.cdGrupoCota }}</td>
      </template>
      <template v-slot:[`item.creditoAtual`]="item">
        <td>{{ money(item.creditoAtual) }}</td>
      </template>
      <template v-slot:[`item.parcelaAtual`]="item">
        <td>{{ money(item.parcelaAtual) }}</td>
      </template>
      <template v-slot:[`item.entrada`]="item">
        <td>{{ money(item.entrada) }}</td>
      </template>
      <template v-slot:[`item.valorComissao`]="item">
        <td>{{ money(item.valorComissao) }}</td>
      </template>
      <template v-slot:[`item.saldoDevedor`]="item">
        <td>{{ money(item.saldoDevedor) }}</td>
      </template>
      <template v-slot:[`item.contrato`]="item">
        <td>{{ item.contrato }}</td>
      </template>
      <template v-slot:totalizador />
    </DataTable>
    <v-card
        v-if="podeExibirDesconto()"
        class="mt-5"
    >
      <v-card-text class="cardcolor py-1 px-0 rounded">
        <v-col cols="12">
          <v-row>
            <v-col cols="12" sm="12" md="2" lg="2" xl="2">
              <v-row no-gutters>
                <label class="desconto valor-desconto">(*) Desconto</label>
                <v-divider
                    class="d-none d-sm-block ml-5"
                    vertical
                />
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
              <label class="desconto">Entrada original:</label>
              <label class="desconto valor-desconto ml-2">{{ real(projeto.entradaOriginal) }}</label>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" xl="7">
              <label class="desconto">Diferença entre entrada original e entrada ajustada:</label>
              <label class="desconto valor-desconto ml-2">
                {{ real(getDiferencaValorEntrada()) }} ( {{ money(getDiferencaPorcentagemEntrada()) }}% )
              </label>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col cols="12" lg="6" xl="6">
        <h1 class="titulo">Anexos</h1>
        <v-row>
          <template v-for="(anexo) of getTipoAnexos()">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-bind:key="anexo.idTipoAnexo">
              <FileInput
                  :id="`FileInputAnexoVendedor_${anexo.idTipoAnexo}`"
                  :label="anexo.nome"
                  :envio-automatico="true"
                  :pode-deletar="podeDeletarAnexo"
                  :id-projeto="projeto.idProjeto"
                  :id-tipo-anexo="anexo.idTipoAnexo"
                  :anexo="getAnexos(anexo.idTipoAnexo)"
                  @anexo="verificarQuantidadeAnexos(anexo.idTipoAnexo, $event)"
              />
            </v-col>
          </template>
        </v-row>
        <label class="texto-informativo-anexo">* São aceitos arquivos nos formatos PDF e imagens JPG, JPEG e PNG de até 10MB.</label>
      </v-col>
      <v-col cols="12" lg="6" xl="6">
        <h1 class="titulo">Histórico de status</h1>
        <v-card class="mx-auto">
          <v-list
              style="max-height: 445px"
              class="overflow-y-auto"
              two-line
          >
            <template v-for="(historico, index) in statusHistorico">
              <div v-bind:key="index">
                <CardHistoricoStatus
                    :cor="getCorStatus(historico.status)"
                    :status="getDescricaoStatus(historico.status)"
                    :usuario="historico.usuario"
                    :data-hora-status="historico.dataStatus"
                    :comentario="historico.comentario"
                />
                <v-divider
                    class="mx-5"
                    v-if="index < statusHistorico.length - 1"
                />
              </div>
            </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="12" sm="12" md="4" lg="3" xl="2">
        <Button
            id="ButtonVoltar"
            block
            color="secondary"
            outlined
            @click="irParaVenderMeusProjetos"
        >
          Voltar
        </Button>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { unmask } from '@/plugins/helpers'
  import { Mensagens, TipoNaturezaAnexo, StatusProjeto, TipoPerfil } from '@/plugins/constants'
  import { getTipoAnexo, getAnexo } from '@/service/anexo'
  import { getConfiguracoes } from '@/service/configuracao'
  import session from '@/plugins/session'
  import {
    money,
    real,
    toDateBR,
    toDateTimeBR,
    formatarCelular,
    formatarCpfOuCnpj
  } from '@/plugins/formatters'
  import {
    getStatusProjeto,
    getCotasProjeto,
    getHistoricoStatusProjeto,
    getDetalhesProjeto,
    atualizarStatusProjeto
  } from '@/service/projeto'

  import Pix from '@/components/Pix'

  export default {
    name: 'VenderDetalheProjeto',
    components: {
      Pix
    },
    data: () => ({
      headers: [
        { text: 'Grupo-cota', value: 'cdGrupoCota' },
        { text: 'Crédito atual (R$)', value: 'creditoAtual', totalizar: true },
        { text: 'Parcela atual (R$)', value: 'parcelaAtual', totalizar: true },
        { text: 'Entrada (R$)', value: 'entrada', totalizar: true },
        { text: 'Comissão (R$)', value: 'valorComissao', totalizar: true },
        { text: 'Saldo devedor (R$)', value: 'saldoDevedor' },
        { text: 'Contrato', value: 'contrato' }
      ],
      pagination: {
        sortBy: [ 'creditoAtual' ], sortDesc: [ true ]
      },
      projeto: {
        entradaOriginal: 0,
        creditoTotal: 0
      },
      configuracoes: {},
      cotasNegociadas: [],
      tiposAnexos: [],
      anexos: [],
      anexosEnviados: [],
      statusHistorico: [],
      status: [],
      real: real,
      money: money,
      toDateBR: toDateBR,
      formatarCelular: formatarCelular,
      formatarCpfOuCnpj: formatarCpfOuCnpj
    }),
    computed: {
      params () {
        return { pagination: this.pagination }
      },
      pixConfigurado () {
        return !(!this.configuracoes.chavePix || !this.configuracoes.nomeBeneficiario || !this.configuracoes.cidadeBeneficiario)
      }
    },
    async mounted () {
      await this.getConfiguracoes()
      this.carregarDetalhesProjeto(this.$route.params)
    },
    methods: {
      async carregarDetalhesProjeto (params) {
        try {
          if (params.idProjeto) {
            const idProjeto = params.idProjeto

            this.status = await getStatusProjeto()
            this.tiposAnexos = await getTipoAnexo()
            this.anexos = await getAnexo(idProjeto)
            this.preencherAnexosEnviados()
            this.projeto = await getDetalhesProjeto(idProjeto)
            await this.carregarCotasNegociadas(idProjeto)
            this.statusHistorico = await getHistoricoStatusProjeto(idProjeto)
          } else {
            this.irParaVenderMeusProjetos()
          }
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
          this.$router.push({ name: 'MeusProjetos' })
        }
      },
      async carregarCotasNegociadas (idProjeto) {
        const itemsCotasNegociadas = await getCotasProjeto(idProjeto)
        const cotasNegociadas = itemsCotasNegociadas.map(item => {
          item.cdGrupoCota = `${item.cdGrupo}-${item.cdCota}`
          item.valorComissao = this.calcularValorComissaoPerfil(item.creditoAtual)
          item.entrada = (item.entradaAjustada > 0) ? item.entradaAjustada : item.entrada
          return item
        }, [])
        this.cotasNegociadas = cotasNegociadas
      },
      calcularValorComissaoPerfil (valor) {
        const perfil = session.get('perfil')

        switch (perfil) {
          case TipoPerfil.PARCEIRO:
            return (this.configuracoes.percentualComissaoParceiro * valor) / 100
          case TipoPerfil.CLT:
            return (this.configuracoes.percentualComissaoVendedor * valor / 100) +
              (this.configuracoes.percentualComissaoGerente * valor / 100) +
              (this.configuracoes.percentualComissaoSupervisor * valor / 100)
          case TipoPerfil.COLABORADOR:
            return (this.configuracoes.percentualComissaoParceiro * valor) / 100
          case TipoPerfil.SUBLOGIN:
            return (this.configuracoes.percentualComissaoParceiro * valor) / 100
          case TipoPerfil.FRANQUEADO:
            return (this.configuracoes.percentualComissaoParceiro * valor) / 100
          case TipoPerfil.VENDEDOR:
            return (this.configuracoes.percentualComissaoVendedor * valor / 100) +
              (this.configuracoes.percentualComissaoGerente * valor / 100) +
              (this.configuracoes.percentualComissaoSupervisor * valor / 100)
          default:
            return 0
        }
      },
      preencherAnexosEnviados () {
        this.anexos.map(item => {
          this.anexosEnviados[item.idTipoAnexo] = 'enviado'
        })
      },
      async getConfiguracoes () {
        try {
          this.configuracoes = await getConfiguracoes()
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
        }
      },
      getCorStatus (status) {
        if (status && this.status.length > 0) {
          return this.status.filter(item => item.status === status)[0].cor
        }
        return ''
      },
      getDescricaoStatus (status) {
        if (status && this.status.length > 0) {
          return this.status.filter(item => item.status === status)[0].descricao
        }
        return ''
      },
      validaPix () {
        if (!this.podeGerarPix()) {
          this.$toast.error("Somente é possível gerar o pix de pagamento para projetos em status de 'Rascunho', 'Em processo' ou 'Aguardando análise de crédito'. Se for necessário, por favor entre em contato com o Administrador.")
          return
        } else if (!this.isPixConfigurado()) {
          this.$toast.error('Os dados de pagamento em PIX não estão liberados na plataforma. Tente novamente mais tarde, ou entre em contato com o Administrador, por favor.')
          return
        }

        this.gerarPix()
      },
      gerarPix () {
        // TODO implementar lógica do pix
      },
      getDiferencaValorEntrada () {
        const valorEntradaAjustada = this.getValorEntradaAjustada()
        if (this.projeto.entradaOriginal && valorEntradaAjustada) {
          return this.projeto.entradaOriginal - valorEntradaAjustada
        }
        return 0
      },
      getDiferencaPorcentagemEntrada () {
        const valorEntradaAjustada = this.getValorEntradaAjustada()
        if (this.projeto.entradaOriginal && valorEntradaAjustada) {
          const difenrecaValorEntrada = valorEntradaAjustada / this.projeto.entradaOriginal
          return (difenrecaValorEntrada - 1) * 100
        }
        return 0
      },
      getValorEntradaAjustada () {
        if (this.cotasNegociadas.length > 0) {
          const valorTotal = this.cotasNegociadas.reduce((total, item) => {
            return total + (item.entradaAjustada || 0)
          }, 0)

          return valorTotal
        }
        return 0
      },
      getTipoAnexosPessoaFisica () {
        return this.tiposAnexos.filter(item => [TipoNaturezaAnexo.AMBOS, TipoNaturezaAnexo.FISICA].includes(item.tipoPessoa) && !item.anexoAdminsitrativo)
      },
      getTipoAnexosPessoaJuridica () {
        return this.tiposAnexos.filter(item => [TipoNaturezaAnexo.AMBOS, TipoNaturezaAnexo.JURIDICA].includes(item.tipoPessoa) && !item.anexoAdminsitrativo)
      },
      getTipoAnexos () {
        return unmask(this.projeto.cpfCnpj).length === 11 ? this.getTipoAnexosPessoaFisica() : this.getTipoAnexosPessoaJuridica()
      },
      getAnexos (idTipoAnexo) {
        if (this.anexos.length > 0) {
          return this.anexos.find(item => item.idTipoAnexo === idTipoAnexo)
        }
      },
      podeExibirDesconto () {
        const valorTotalEntradaAjustada = this.getValorTotalEntradaAjustada()
        return (valorTotalEntradaAjustada > 0 && this.projeto.entradaOriginal !== valorTotalEntradaAjustada)
      },
      getValorTotalEntradaAjustada () {
        if (this.cotasNegociadas.length > 0) {
          const valorTotal = this.cotasNegociadas.reduce((total, item) => {
            return total + (item.entradaAjustada || 0)
          }, 0)
          return valorTotal
        }
        return 0
      },
      podeGerarPix () {
        return [StatusProjeto.RASCUNHO, StatusProjeto.EM_PROCESSO, StatusProjeto.AGUARDANDO_ANALISE_CREDITO].includes(this.projeto.statusProjeto)
      },
      isPixConfigurado () {
        if (this.configuracoes) {
          return (this.configuracoes.chavePix !== 'ajustar-chave_pix')
        }
        return false
      },
      irParaVenderMeusProjetos () {
        this.$router.push({ name: 'MeusProjetos' })
      },
      podeDeletarAnexo () {
        return StatusProjeto.RASCUNHO === this.projeto.statusProjeto
      },
      async verificarQuantidadeAnexos (idTipoAnexo, file) {
        const quantidadeAnexos = this.getTipoAnexos().length
        this.anexosEnviados[idTipoAnexo] = file
        const anexosEnviados = this.anexosEnviados.filter(item => item !== null).length
        if (quantidadeAnexos === anexosEnviados) {
          const formData = new FormData()
          formData.append('idProjeto', this.projeto.idProjeto)
          formData.append('status', StatusProjeto.EM_PROCESSO)
          await atualizarStatusProjeto(formData)
          this.recarregarPagina()
        }
      },
      recarregarPagina () {
        location.reload()
      },
      formatarDataHoraBr (dataHora) {
        return dataHora ? toDateTimeBR(dataHora) : '-'
      }
    }
  }
</script>

<style scoped>
  .valor-detalhe {
    margin-left: 5px;
    font-size: 14px;
    font-weight: bold;
    color: var(--black);
  }

  .desconto,
  .texto-detalhe {
    font-size: 14px;
    color: var(--black);
  }

  .valor-desconto {
    font-weight: bold;
  }

  .container-valor {
    margin-top: 5px;
  }
</style>
