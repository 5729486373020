<template>
  <div>
    <div v-if="exibeDataHoraExpiracao">
      {{ toDateTimeBR(dataHoraExpiracao) }}
    </div>
    <div v-else-if="exibeContagemRegressiva">
      <v-icon size="20" color="secondary">pc-relogio</v-icon>
      <span class="ml-1 contador">{{ contadorRegressivo }}</span>
    </div>
    <div
        v-else
        class="reservar"
        @click="$emit('podeReservar')">
      Reservar
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { toDateTimeBR } from '@/plugins/formatters'
  import { getSituacaoReserva, horaParaMilissegundo } from '@/plugins/helpers'
  import { TipoExibicaoCota } from '@/plugins/constants'
  const UM_SEGUNDO = 1000

  export default {
    props: {
      dataHoraReserva: String,
      configuracoes: Object,
      somarPenalidade: {
        type: Boolean,
        default: true
      }
    },
    data: () => ({
      contadorRegressivo: '',
      dataHoraExpiracao: '',
      exibeDataHoraExpiracao: false,
      exibeContagemRegressiva: false,
      toDateTimeBR: toDateTimeBR,
      interval: null
    }),
    watch: {
      dataHoraReserva () {
        this.validaReserva()
      }
    },
    mounted () {
      this.validaReserva()
    },
    methods: {
      validaReserva () {
        const parametros = {
          dataHoraReserva: this.dataHoraReserva,
          tempoExpiracaoReserva: this.configuracoes.horasExpiracaoReserva,
          tempoPenalidade: this.somarPenalidade ?? this.configuracoes.horasPenalidadeReserva,
          tempoIniciarContagemRegressiva: this.configuracoes.minutosExibirContagem
        }

        const [ situacao, tempoExpiracaoReserva ] = getSituacaoReserva(parametros)
        if (situacao === TipoExibicaoCota.EXIBIR_DATA_HORA) {
          const tempoExpiracaoEmMilissegundos = horaParaMilissegundo(parametros.tempoExpiracaoReserva)
          const tempoPenalidadeEmMilissegundos = horaParaMilissegundo(parametros.tempoPenalidade)
          this.dataHoraExpiracao = moment(parametros.dataHoraReserva).add(tempoExpiracaoEmMilissegundos + tempoPenalidadeEmMilissegundos)

          this.exibeDataHoraExpiracao = true
          this.exibeContagemRegressiva = false
        } else if (situacao === TipoExibicaoCota.EXIBIR_CONTAGEM_REGRESSIVA) {
          this.exibeContagemRegressiva = true
          this.exibeDataHoraExpiracao = false
          this.iniciaContagemRegressiva(tempoExpiracaoReserva)
        } else {
          this.exibeDataHoraExpiracao = false
          this.exibeContagemRegressiva = false
        }
      },
      iniciaContagemRegressiva (tempo) {
        var duracao = moment.duration(tempo, 'milliseconds', true)

        if (this.interval) {
          clearInterval(this.interval)
        }

        this.interval = setInterval(() => {
          duracao = moment.duration(duracao - UM_SEGUNDO, 'milliseconds')
          let horas = String((duracao.days() * 24) + duracao.hours()).padStart(2, '0')
          let minutos = String(duracao.minutes()).padStart(2, '0')
          let segundos = String(duracao.seconds()).padStart(2, '0')
          this.contadorRegressivo = `${horas}:${minutos}:${segundos}`

          if (horas === '00' && minutos === '00' && segundos === '00') {
            clearInterval(this.interval)
            this.$emit('contagemFinalizada')
          }
        }, UM_SEGUNDO)
      }
    }
  }
</script>

<style scoped>
  .reservar {
    cursor: pointer;
    font-size: 16px !important;
    text-decoration-line: underline;
    color: var(--black);
  }

  .contador {
    color: var(--secondary);
    vertical-align: middle;
  }
</style>
