<script>
  import { VColorPicker } from 'vuetify/es5/components/VColorPicker'
  export default {
    name: 'ColorPicker',
    extends: VColorPicker
  }
</script>

<style lang="scss">
  .v-color-picker__color {
    width: 32px;
    min-height: 32px;
  }

  .v-color-picker__swatches > div {
    justify-content: left;
  }
</style>
