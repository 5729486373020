<template>
  <div v-if="visible" class="d-flex flex-column align-center justify-center outer-container">
    <img class="spinner" src="../assets/img/spinner.png" alt="Carregando...">
    <p class="mt-6 text--secondary text-h5">Carregando...</p>
  </div>
</template>

<script>
  export default {
    name: 'Loading',
    props: { visible: Boolean }
  }
</script>

<style scoped>
  .outer-container {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    z-index: 999;
  }
</style>
