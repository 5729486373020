import Vue from 'vue'
import Toast, { TYPE, POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import CloseButton from '@/components/CloseButton'

Vue.use(Toast, {
  position: POSITION.BOTTOM_RIGHT,
  timeout: 3000,
  icon: 'pc-atencao',
  closeButton: CloseButton,
  closeOnClick: false,
  draggablePercent: 0.35,
  toastDefaults: {
    [TYPE.SUCCESS]: {
      icon: 'pc-sucesso'
    },
    [TYPE.ERROR]: {
      icon: 'pc-erro'
    }
  }
})
