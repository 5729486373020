import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'
import { get } from 'lodash'

export async function getUsuario (usuario) {
  try {
    const { data } = await http.get(`/usuario-newcon/${usuario}`)
    return data
  } catch (erro) {
    if (erro.response.data.msg_erro) {
      throw erro.response.data.msg_erro
    }

    throw Mensagens.ERRO
  }
}

export async function importarUsuario (usuarioNewcon) {
  try {
    usuarioNewcon.perfil = usuarioNewcon.perfil.tipo
    await http.post('/usuario', usuarioNewcon)
  } catch (erro) {
    throw Mensagens.ERRO
  }
}

export async function bloqueioUsuario (id, status) {
  try {
    await http.put(`/usuario/${id}`, {
      bloqueado: status
    })
  } catch (erro) {
    throw Mensagens.ERRO
  }
}

export async function resetarSenha (login, usuario) {
  try {
    const { data } = await http.patch(`/usuario/resetar-senha/${login}`, usuario)
    return data
  } catch (erro) {
    const msg = get(erro, 'response.data.msg_erro', 'Ops! Não foi possível recuperar sua senha. Tente novamente mais tarde.')
    throw Error(msg)
  }
}
