<template>
  <v-container fluid>
    <h1 class="titulo">Usuários</h1>
    <v-row class="pr-3">
      <v-col cols="12" sm="4" lg="4" md="4" xl="4">
        <FilterTextField
            id="FilterTextFieldBusca"
            v-model="busca"
            maxlength="30"
            placeholder="Digite para buscar"
            tooltip="Digite o número do comissionado para buscar na coluna ‘Comissionado’. E a busca em texto será aplicado às demais colunas. (exceto coluna de ‘Bloqueio’)"
            v-uppercase
        />
      </v-col>
      <ImportarUsuarios />
    </v-row>
    <DataTable
        :headers="tabela.cabecalho"
        :filter="tabela.filtros"
        :params="params"
        url="usuario"
    >
      <template v-slot:[`item.cdComissionado`]="item">
        <td>{{ item.cdComissionado || '-' }}</td>
      </template>
      <template v-slot:[`item.login`]="item">
        <td>{{ item.login.toUpperCase() }}</td>
      </template>
      <template v-slot:[`item.nome`]="item">
        <td>{{ item.nome.toUpperCase() }}</td>
      </template>
      <template v-slot:[`item.perfil.nome`]="item">
        <td>{{ item.perfil.nome }}</td>
      </template>
      <template v-slot:[`item.bloqueado`]="item">
        <td>
          <v-switch
              :id="`Switch_${item.id}`"
              inset
              v-model="item.bloqueado"
              @change="alternarBloqueio(item.id, item.bloqueado)"
              :disabled="item.id == $session.get('idUsuario')"
          />
        </td>
      </template>
    </DataTable>
  </v-container>
</template>

<script>
  import { getFilters } from '@/plugins/helpers'
  import ImportarUsuarios from '@/components/ImportarUsuarios.vue'
  import { bloqueioUsuario } from '@/service/usuario'

  export default {
    components: { ImportarUsuarios },
    data: () => ({
      tabela: {
        cabecalho: [
          {
            text: 'Comissionado',
            value: 'cdComissionado',
            width: 200
          },
          {
            text: 'Usuário',
            value: 'login',
            width: 200
          },
          {
            text: 'Nome',
            value: 'nome',
            width: 400
          },
          {
            text: 'Perfil',
            value: 'perfil.nome'
          },
          {
            text: 'Bloqueado',
            value: 'bloqueado',
            width: 115
          }
        ],
        pagination: {
          sortBy: ['nome'], sortDesc: [false]
        },
        filtros: {}
      },
      busca: ''
    }),
    computed: {
      params () {
        return { pagination: this.tabela.pagination }
      }
    },
    watch: {
      busca (valor) {
        if (valor.length > 2 || valor.length === 0) {
          this.tabela.filtros = getFilters(valor)
        }
      },
      '$store.state.recarregar': function () {
        this.tabela.filtros = getFilters('')
      }
    },
    methods: {
      async alternarBloqueio (id, status) {
        try {
          await bloqueioUsuario(id, status)
        } catch (erro) {
          this.$toast.error(erro)
        }
      }
    }
  }
</script>
