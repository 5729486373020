<template>
  <v-icon v-on="$listeners" size="14">pc-fechar</v-icon>
</template>

<script>
  import vuetify from '@/plugins/vuetify'
  export default {
    name: 'CloseButton',
    vuetify
  }
</script>

<style scoped>
  .v-icon {
    color: white;
    opacity: 1;
  }
  .v-icon:hover {
    opacity: 0.8;
  }
</style>
