<template>
  <v-container fluid>
    <h1 class="titulo">Exportar dados</h1>
    <div class="text-center mt-16">
      <p class="text--inputcolor">A exportação extrai todos os dados da plataforma, exige um processamento considerável dos servidores e portanto pode demorar. Execute apenas quando for indispensável.</p>
      <a
          :href="apiUrl + `/api/cota/exportar-dados?l=${token}&s=${username}`"
          style="text-decoration: none"
          @click="download"
      >
        <Button
            id="ButtonExportar"
            class="secondary"
        >
          <v-icon dark left>pc-excel</v-icon>
          Exportar dados .xlsx
        </Button>
      </a>
      <p
          v-if="timeout"
          class="text-body-2 mt-5"
      >
        O arquivo será gerado, por favor aguarde o carregamento da página...
      </p>
    </div>
  </v-container>
</template>

<script>
  import { gerarTokenTemporario } from '@/service/token'

  export default {
    data: () => ({
      timeout: false,
      token: undefined,
      username: undefined
    }),
    computed: {
      apiUrl () {
        return process.env.VUE_APP_WS_ROOT
      }
    },
    mounted () {
      this.gerarToken()
    },
    methods: {
      download (event) {
        if (this.timeout) {
          event.preventDefault()
        } else {
          this.$toast.success('O arquivo será gerado, por favor aguarde o carregamento da página')
          this.timeout = true

          setTimeout(() => {
            this.timeout = false
          }, 10000)
        }
      },
      async gerarToken () {
        try {
          const data = await gerarTokenTemporario()
          this.token = data.token
          this.username = data.username
        } catch (erro) {
          this.$toast.error(erro.message)
        }
      }
    }
  }
</script>
