import { BrowserCacheLocation } from '@azure/msal-browser'

export const persistentes = {
  token: '',
  idUsuario: '',
  nome: '',
  usuario: '',
  perfil: '',
  autenticacaoMultifator: false
}

export const nao_persistentes = {
  loading: false,
  recarregar: false,
  drawer: true,
  msalConfig: {
    auth: {
      clientId: process.env.VUE_APP_AZURE_APP_ID,
      authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AZURE_TENANT_ID}`,
      redirectUri: '/auth/callback',
      navigateToLoginRequestUrl: false
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: false
    }
  },
  msalAuthRequest: {
    prompt: 'select_account'
  }
}
