<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="ml-6">
    <v-menu
        :close-on-content-click="false"
        offset-y
        rounded
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            icon
            class="grey lighten-4"
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>pc-perfil</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>pc-perfil</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ nome }}</v-list-item-title>
              <v-list-item-subtitle>{{ usuario }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item @click="logout()">
            <v-list-item-icon>
              <v-icon>pc-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  import { alert } from '@/plugins/helpers'
  import { mapState } from 'vuex'
  import store from '@/store'

  export default {
    name: 'Perfil',
    computed: {
      ...mapState(['nome', 'usuario'])
    },
    methods: {
      logout () {
        alert({ title: 'Atenção!',
                text: 'Tem certeza que deseja sair?',
                buttons: {
                  'Cancelar': () => {
                  },
                  'Sim': () => {
                    this.sair()
                  }
                }
        })
      },
      sair () {
        if (store.state.autenticacaoMultifator) {
          this.$router.push({
            name: 'LogoutCallback',
            replace: true,
            params: {
              logout: true
            }
          })
        } else {
          this.$session.destroy()
          this.$router.push({ name: 'login', replace: true })
        }
      }
    }
  }
</script>

<style scoped>
  .v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 12px;
  }
</style>
