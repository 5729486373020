<template>
  <div>
    <v-dialog
        v-model="dialog"
        width="440"
    >
      <template v-slot:activator="{ on, attrs }">
        <TextField
            v-model="dataFormatada"
            v-bind="attrs"
            v-on="on"
            append-icon="pc-calendario"
            readonly
        />
      </template>
      <v-card class="py-4 px-4" rounded="lg">
        <v-card-actions>
          <v-form>
            <v-row>
              <v-col />
              <v-col
                  cols="5"
                  class="pb-0"
              >
                <SelectField
                    v-model="mes"
                    :items="meses"
                    item-text="texto"
                    item-value="valor"
                />
              </v-col>
              <v-col
                  cols="5"
                  class="pb-0"
              >
                <SelectField
                    v-model="ano"
                    :items="anos"
                />
              </v-col>
              <v-col />
              <v-col
                  cols="6"
                  class="pt-0"
              >
                <Button
                    width="100%"
                    color="secondary"
                    outlined
                    @click="dialog = false"
                >
                  Cancelar
                </Button>
              </v-col>
              <v-col
                  cols="6"
                  class="pt-0"
              >
                <Button
                    width="100%"
                    color="secondary"
                    @click="salvarData"
                >
                  Salvar
                </Button>
              </v-col>
            </v-row>
          </v-form>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: ['dataInicial'],
    data: () => ({
      dialog: false,
      dataFormatada: '',
      mes: '',
      ano: '',
      meses: [
        { texto: 'Janeiro', valor: 1 },
        { texto: 'Fevereiro', valor: 2 },
        { texto: 'Março', valor: 3 },
        { texto: 'Abril', valor: 4 },
        { texto: 'Maio', valor: 5 },
        { texto: 'Junho', valor: 6 },
        { texto: 'Julho', valor: 7 },
        { texto: 'Agosto', valor: 8 },
        { texto: 'Setembro', valor: 9 },
        { texto: 'Outubro', valor: 10 },
        { texto: 'Novembro', valor: 11 },
        { texto: 'Dezembro', valor: 12 }
      ],
      anos: [2021, 2022, 2023, 2024, 2025, 2026]
    }),
    mounted () {
      if (this.dataInicial) {
        this.mes = this.dataInicial.mes
        this.ano = this.dataInicial.ano
        this.dataFormatada = this.formatarData(this.ano, this.mes)
      }
    },
    methods: {
      salvarData () {
        this.dataFormatada = this.formatarData(this.ano, this.mes)
        this.dialog = false
        this.$emit('data', {
          mes: this.mes,
          ano: this.ano
        })
      },
      formatarData (ano, mes) {
        return `${mes < 10 ? '0' + mes : mes}/${ano}`
      }
    }
  }
</script>
