import Vue from 'vue'
import { mask } from 'vue-the-mask'

import Rules from '@/plugins/validation'

import TextField from '@/components/TextField'
import SelectField from '@/components/SelectField'
import DecimalTextField from '@/components/DecimalTextField'
import Button from '@/components/Button'
import PasswordField from '@/components/PasswordField'
import Loading from '@/components/Loading'
import DataTable from '@/components/DataTable'
import FilterTextField from '@/components/FilterTextField'
import AjudaTooltip from '@/components/AjudaTooltip'
import Checkbox from '@/components/Checkbox'
import ContadorRegressivo from '@/components/ContadorRegressivo'
import Alert from '@/components/Alert'
import ColorPicker from '@/components/ColorPicker'
import DatePicker from '@/components/DatePicker'
import FileInput from '@/components/FileInput'
import Chip from '@/components/Chip'
import CardHistoricoStatus from '@/components/CardHistoricoStatus'
import TextArea from '@/components/TextArea'

const components = {
  Loading,
  TextField,
  SelectField,
  DecimalTextField,
  Button,
  PasswordField,
  DataTable,
  FilterTextField,
  AjudaTooltip,
  Checkbox,
  ContadorRegressivo,
  Alert,
  ColorPicker,
  DatePicker,
  FileInput,
  Chip,
  CardHistoricoStatus,
  TextArea
}

Vue.mixin({
  components,
  directives: { mask },
  computed: {
    $rules: () => Rules
  }
})
