<template>
  <v-layout>
    <v-card class="container">
      <div class="container-titulo">
        <v-icon color="secondary">pc-clipe</v-icon>
        <label class="ml-2 titulo-card bold">{{ titulo }}</label>
      </div>
      <div class="mt-5" v-if="mostrarAdd && !((file && file.name))">
        <v-btn text @click="dialogAnexoGenerico = true">Adicionar</v-btn>
      </div>
      <div v-else>
        <div :class="((file && file.name) ? 'desabilitar-click' : '')">
          <v-file-input
              class="mt-3"
              prepend-icon=''
              dense
              accept=".png, .jpg, .jpeg, application/pdf"
              placeholder="Selecionar arquivo"
              v-model="file"
              v-bind="$attrs"
              v-on="$listeners"
              @change="alterandoArquivo"
              :loading="carregando">
            <template v-slot:selection="{ text }">
              <label v-if="!carregando" class="texto-selecionado">Arquivo:</label>
              <label v-if="!carregando" class="ml-1 texto-selecionado bold">{{ text }}</label>
            </template>
            <template v-slot:progress>
              <div class="d-flex flex-column align-center justify-center outer-container">
                <img class="spinner" src="../assets/img/spinner.png" alt="Carregando...">
                <p class="mt-6 text--black text-h7">Carregando...</p>
              </div>
            </template>
          </v-file-input>
        </div>
      </div>
      <v-row v-if="!carregando" no-gutters class="mt-0">
        <v-col cols="6">
          <label v-show="exibirVisualizar" @click="visualizarArquivo" class="visualizar">visualizar</label>
        </v-col>
        <v-col class="text-align-right" cols="3">
          <v-icon v-show="exibirRealizarDownload" @click="downloadArquivo" color="inputcolor">pc-download</v-icon>
        </v-col>
        <v-col class="text-align-right" cols="3">
          <v-icon v-show="exibirDeletar" @click="prepararDeletarArquivo" color="inputcolor">pc-excluir</v-icon>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
        content-class="app-content"
        v-model="dialogAnexoGenerico"
        persistent
        width="400px">
      <v-form ref="formAnexo">
        <v-card>
          <v-card-title>
            <slot name="title">Anexos</slot>
            <v-btn
                id="ButtonFecharDialogAnexoGenerico"
                icon
                class="ml-auto"
                @click="dialogAnexoGenerico = false"
            >
              <v-icon color="primary" size="12">pc-fechar</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <TextField
                id="TextFieldNomeAnexoGenerico"
                label="Nome"
                v-model="nomeGenerico"
                :rules="[this.$rules.required]"
                maxlength="50"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <Button
                id="ButtonCancelarAnexoGenerico"
                small
                outlined
                color="secondary"
                @click="dialogAnexoGenerico = false">
              Cancelar
            </Button>
            <Button
                id="ButtonConfirmarAnexoGenerico"
                small
                color="secondary"
                @click="adicionarGenerico">
              Confirmar
            </Button>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-layout>
</template>

<script>
  import { alert } from '@/plugins/helpers'
  import { salvarAnexo, salvarAnexoGenerico, deletarAnexo, deletarAnexoGenerico, getAnexoIndividual } from '@/service/anexo'
  import { Mensagens } from '@/plugins/constants'

  const TAMANHO_MAXIMO_ARQUIVO = 10
  const TAMANHO_MAXIMO_ARQUIVO_EM_MB = TAMANHO_MAXIMO_ARQUIVO * 1024 * 1024
  const TIPO_ARQUIVO_PERMITIDO = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']

  export default {
    name: 'FileInput',
    props: {
      label: String,
      idProjeto: Number,
      idTipoAnexo: Number,
      anexo: Object,
      envioAutomatico: {
        type: Boolean,
        default: false
      },
      podeDeletar: Function,
      generico: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      file: [],
      exibirDeletar: false,
      exibirVisualizar: false,
      exibirRealizarDownload: false,
      carregando: false,
      idAnexoServidor: null,
      dialogAnexoGenerico: false,
      nomeGenerico: '',
      mostrarAdd: false,
      titulo: ''
    }),
    watch: {
      file (value) {
        if (value) {
          if (this.isAnexoEnviado()) {
            this.exibirVisualizar = true
            this.exibirRealizarDownload = true
          }
          this.exibirDeletar = true
        } else {
          this.exibirVisualizar = false
          this.exibirRealizarDownload = false
          this.exibirDeletar = false
        }
      },
      anexo: {
        immediate: true,
        deep: true,
        handler (newValue, oldValue) {
          if (oldValue !== newValue) {
            if (newValue && newValue.nomeDocumento) {
              this.file = { name: newValue.nomeDocumento }
              this.idAnexoServidor = newValue.idAnexoServidor
              this.exibirVisualizar = true
              this.exibirRealizarDownload = true
            }
          }
        }
      }
    },
    mounted () {
      this.titulo = this.label
      this.mostrarAdd = this.generico
    },
    methods: {
      async visualizarArquivo () {
        const encodeIdAnexo = Buffer.from(this.idAnexoServidor.toString()).toString('base64')
        let resolvedRoute = this.$router.resolve({
          name: 'Anexo',
          query: { data: encodeIdAnexo }
        })
        window.open(resolvedRoute.href, '_blank')
      },
      async downloadArquivo () {
        const anexo = await this.getAnexoAzure()
        window.location.href = anexo.url
      },
      async getAnexoAzure () {
        try {
          return await getAnexoIndividual(this.idAnexoServidor)
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
        }
      },
      async deletarArquivo () {
        try {
          if (this.isAnexoEnviado()) {
            this.carregando = true

            const payload = {
              idProjeto: this.idProjeto,
              idTipoAnexo: this.idTipoAnexo
            }
            if (this.generico) {
              await deletarAnexoGenerico(this.idAnexoServidor)
              this.$emit('removeanexo', this.idAnexoServidor)
            } else {
              await deletarAnexo(payload)
            }
          }
          this.limparArquivo()
        } catch (erro) {
          this.$toast.error('Algo deu errado. Não foi possível deletar os documentos. Acesse o projeto salvo, e tente novamente.')
        } finally {
          this.carregando = false
        }
      },
      async salvarAnexo () {
        let ok = true
        try {
          this.carregando = true
          const formData = new FormData()
          formData.append('arquivo', this.file)
          formData.append('idProjeto', this.idProjeto)
          formData.append('nomeDocumento', this.file.name)

          let anexo
          if (!this.generico) {
            formData.append('idTipoAnexo', this.idTipoAnexo)
            anexo = await salvarAnexo(formData, true)
          } else {
            formData.append('nomeAnexo', this.anexo.nomeAnexo)
            formData.append('idAnexoGenerico', this.anexo.idAnexoGenerico > 0 ? this.anexo.idAnexoGenerico : 0)
            anexo = await salvarAnexoGenerico(formData, true)
          }

          this.idAnexoServidor = anexo.idAnexoServidor
          this.$toast.success('Feito! Anexo enviado')
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO_ENVIO_ANEXO)
          ok = false
          this.limparArquivo()
        } finally {
          this.carregando = false
        }
        return ok
      },
      limparArquivo () {
        this.file = null
        this.$emit('anexo', null)
      },
      prepararDeletarArquivo () {
        if (this.podeDeletar && !this.podeDeletar()) {
          this.$toast.error('Não é possível remover arquivos de projetos já em andamento')
          return
        }

        alert({
          text: `Confirma a exclusão do ‘${this.label}’? Esta ação não poderá ser desfeita`,
          buttons: {
            'Cancelar': () => {},
            'Confirmar': () => {
              this.deletarArquivo()
            }
          }
        })
      },
      async alterandoArquivo (file) {
        if (!file) {
          this.deletarAnexoErro()
          return
        }

        if (!this.isTamanhoArquivoValido(file)) {
          this.$toast.error(`Arquivo muito grande. O arquivo deve conter no máximo ${TAMANHO_MAXIMO_ARQUIVO}MB`)
          this.deletarAnexoErro()
          return
        } else if (!this.isExtensaoArquivoValido(file)) {
          this.$toast.error('Arquivo com extensão não permitida. São permitidos somente arquivos com as extensões: png, jpeg, jpeg e pdf')
          this.deletarAnexoErro()
          return
        }

        if (this.envioAutomatico) {
          if (await this.salvarAnexo()) {
            this.$emit('anexo', file)
          }
        } else {
          this.$emit('anexo', file)
        }
      },
      deletarAnexoErro () {
        setTimeout(() => {
          this.limparArquivo()
        }, 2000)
      },
      isTamanhoArquivoValido (file) {
        return file && file.size <= TAMANHO_MAXIMO_ARQUIVO_EM_MB
      },
      isExtensaoArquivoValido (file) {
        return file && TIPO_ARQUIVO_PERMITIDO.includes(file.type)
      },
      isAnexoEnviado () {
        return (this.idProjeto && this.file)
      },
      adicionarGenerico () {
        if (this.$refs.formAnexo.validate()) {
          this.anexo.nomeAnexo = this.nomeGenerico
          this.mostrarAdd = false
          this.dialogAnexoGenerico = false
          this.titulo = this.nomeGenerico
        }
      }
    }
  }
</script>

<style scoped>
.bold {
  font-weight: bold;
}

.texto-selecionado {
  font-size: 14px;
}

.container {
  padding: 20px;
  max-height: 175px;
  min-height: 175px;
}

.container-titulo {
  max-height: 55px;
  min-height: 55px;
}

.titulo-card {
  font-size: 18px;
}

.visualizar {
  text-decoration: underline;
  cursor: pointer;
}

.text-align-right {
  text-align: right;
}

.outer-container {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.spinner {
  width: 27px;
  height: 27px;
}

.desabilitar-click {
  pointer-events: none;
}
</style>

<style lang="scss">
.v-text-field > .v-input__control .v-input__append-inner,
.v-text-field > .v-input__control >
.v-input__slot:before, .v-text-field > .v-input__control > .v-input__slot:after {
  display: none;
}

.theme--light .v-file-input .v-file-input__text--placeholder {
  color: var(--secondary);
  text-decoration: underline;
  cursor: pointer;
  margin-left: 32px;
}
</style>
