import http from '@/plugins/http'
import { get } from 'lodash'
import { Mensagens } from '@/plugins/constants'

export async function abrirProjeto (projeto) {
  try {
    const { data } = await http.post('/abrir-projeto', projeto)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function getStatusProjeto () {
  try {
    const { data } = await http.get('/status-projeto')
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function getCotasProjeto (idProjeto) {
  try {
    const { data } = await http.get(`/cota-projeto/${idProjeto}`)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function getDetalhesProjeto (idProjeto) {
  try {
    const { data } = await http.get(`/detalhe-projeto/${idProjeto}`)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function atualizarProtocoloTransferencia (protocolo) {
  try {
    const { data } = await http.put('/protocolo-cota', protocolo)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function getHistoricoStatusProjeto (idProjeto) {
  try {
    const { data } = await http.get(`/historico-status-projeto/${idProjeto}`)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function cancelarProjeto (projeto) {
  try {
    const { data } = await http.post('/cancelar-projeto', projeto)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function getProjetoCota (id) {
  try {
    const { data } = await http.get(`/projeto-cota/${id}`)
    return data
  } catch (e) {
    throw Error(get(e, 'response.data.msg_erro', Mensagens.ERRO))
  }
}

export async function atualizarStatusProjeto (projeto) {
  try {
    const { data } = await http.post('/status-projeto', projeto)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}
