<template>
  <span class="ml-auto mb-10">
    <Button class="ml-auto secondary" @click="dialog = true">
      <v-icon dark left>mdi-plus</v-icon>
      Novo Usuário
    </Button>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span>Importação de usuário Newcon</span>
          <v-btn icon class="ml-auto" @click="dialog = false">
            <v-icon dark size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <p class="mt-4 text-body-2 text--black mb-2">Informe o login do usuário Newcon que deseja importar</p>
        </v-card-text>
        <v-card-text>
          <v-container>
            <form>
              <v-row>
                <v-col cols="8" class="px-0 pb-0">
                  <TextField
                      v-model="importacao.usuario"
                      maxlength="30"
                      :disabled="loginBloqueado"
                  />
                </v-col>
                <v-col cols="4">
                  <v-btn
                      color="secondary text-capitalize dialog-button rounded-lg px-7"
                      :disabled="loginBloqueado"
                      @click="buscarUsuario"
                  >Buscar</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="11" class="px-0 py-0">
                  <TextField
                      v-model="importacao.nome"
                      label="Nome"
                      disabled
                  />
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-radio-group
                    label="Perfil"
                    v-model="importacao.perfil"
                    disabled
                >
                  <v-row>
                    <v-col>
                      <v-radio
                          label="Administrador"
                          value="adm"
                      />
                    </v-col>
                    <v-col>
                      <v-radio
                          label="Parceiro"
                          value="parceiro"
                      />
                    </v-col>
                    <v-col>
                      <v-radio
                          label="Franqueado"
                          value="franqueado"
                      />
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-row>
              <v-row class="text--error transition" v-if="erro">
                <v-col cols="1" class="px-0">
                  <v-icon size="20" color="error" class="mr-1">pc-ajuda</v-icon>
                </v-col>
                <v-col class="px-0">{{ erro }}</v-col>
              </v-row>
            </form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col cols="4">
            <Button
                color="secondary"
                class="text-capitalize dialog-button"
                outlined
                small
                @click="limparCampos"
            >
              Limpar
            </Button>
          </v-col>
          <v-col cols="4">
            <Button
                color="secondary"
                class="text-capitalize dialog-button"
                :disabled="!loginBloqueado"
                small
                @click="importarUsuario"
            >
              Importar
            </Button>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
  import { getUsuario, importarUsuario } from '@/service/usuario'

  export default {
    data: () => ({
      dialog: false,
      loginBloqueado: false,
      erro: '',
      importacao: {
        usuario: '',
        nome: '',
        perfil: '',
        usuarioNewcon: {}
      }
    }),
    watch: {
      dialog (valor) {
        if (valor) {
          this.limparCampos()
        }
      }
    },
    methods: {
      async buscarUsuario () {
        if (this.importacao.usuario.trim() === '') {
          this.erro = 'Informe o login do usuário.'
          return
        }
        this.erro = ''

        try {
          const usuarioNewcon = await getUsuario(this.importacao.usuario)

          this.importacao.nome = usuarioNewcon.nome
          this.importacao.perfil = usuarioNewcon.perfil.tipo
          this.importacao.usuarioNewcon = usuarioNewcon

          this.loginBloqueado = true
        } catch (erro) {
          this.erro = 'Ocorreu um erro ao tentar buscar dados do usuário.'
        }
      },
      async importarUsuario () {
        try {
          await importarUsuario(this.importacao.usuarioNewcon)

          this.$toast.success('Usuário importado com sucesso!')
          this.$store.commit('setRegarregar')
          this.dialog = false
        } catch (erro) {
          this.$toast.error(erro)
        }
      },
      limparCampos () {
        this.loginBloqueado = false
        this.importacao.usuario = ''
        this.importacao.nome = ''
        this.importacao.perfil = ''
        this.erro = ''
      }
    }
  }
</script>

<style scoped>
  .v-btn {
    letter-spacing: normal !important;
  }
</style>
