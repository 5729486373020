<script>
  import { VBtn } from 'vuetify/es5/components/VBtn'
  export default {
    name: 'Button',
    extends: VBtn
  }
</script>

<style scoped>
  .v-btn {
    text-transform: none;
    height: 48px !important;
    padding: 0 32px !important;
    border-radius: 8px;
  }

  .v-btn:not(.v-btn--round).v-size--small {
    height: 36px !important;
  }
</style>
