<template>
  <v-container fluid class="full-height">
    <div class="full-height">
      <img v-if="!isPdf()" :src=url />
      <embed width="100%" height="100%" v-if="isPdf()" :src=base64 type="application/pdf" />
    </div>
  </v-container>
</template>

<script>
  import { getAnexoIndividual, getConteudoAnexo } from '@/service/anexo'
  import { Mensagens } from '@/plugins/constants'

  export default {
    name: 'Anexo',
    data: () => ({
      url: '',
      base64: null,
      nomeAnexo: null
    }),
    mounted () {
      this.carregarUrl(this.$route.query)
    },
    methods: {
      async carregarUrl (params) {
        if (params.data) {
          try {
            const idAnexoServidor = Buffer.from(params.data, 'base64').toString('utf-8')
            const anexo = await getAnexoIndividual(idAnexoServidor)
            this.nomeAnexo = anexo.nomeOriginal
            if (this.isPdf()) {
              this.base64 = 'data:application/pdf;base64,' + await getConteudoAnexo(encodeURIComponent(anexo.url))
            } else {
              this.url = anexo.url
            }
          } catch (erro) {
            this.$toast.error(Mensagens.ERRO)
          }
        }
      },
      isPdf () {
        return this.nomeAnexo.includes('pdf')
      }
    }
  }
</script>

<style scoped>
  .full-height {
    height: 100%;
  }
</style>
