import http from '@/plugins/http'
import { Mensagens } from '@/plugins/constants'
import { get } from 'lodash'

export async function syncSublogins () {
  try {
    const { data } = await http.get(`/sub-logins/buscar`, { options: { silent: true } })
    return data
  } catch (erro) {
    throw get(erro, 'response.data.msg_erro', Mensagens.ERRO)
  }
}
