<template>
  <div>
    <label v-if="label" :class="{ 'label-error': hasError }" class="d-flex mb-1">
      <span>{{ label }}</span>
      <AjudaTooltip v-if="tooltip" :mensagem="tooltip" tamanho="20" class="ml-1" />
    </label>
    <v-textarea
        ref="field"
        v-bind="$attrs"
        v-on="$listeners"
        outlined
        dense
        class="text-field transition" />
  </div>
</template>

<script>
  export default {
    name: 'TextArea',
    props: {
      label: String,
      tooltip: String
    },
    data: () => ({
      isMounted: false
    }),
    computed: {
      hasError () { return this.isMounted && this.$refs.field.validationState === 'error' }
    },
    mounted () {
      this.isMounted = true
    }
  }
</script>

<style lang="scss" scoped>
  label {
    color: var(--labelcolor);
    &.label-error {
      color: var(--secondary);
    }
  }
</style>

<style lang="scss">
  .text-field.v-input {
    fieldset {
      border-color: var(--gray);
      background: var(--white);
    }
    &.v-input--is-focused fieldset {
      border-width: 1px;
      border-color: #048ABF;
      filter: drop-shadow(0 0 4px #55AFFC)
    }
    &.v-input--is-disabled fieldset {
      background-color: var(--cardcolor);
    }
    .v-input__slot {
      min-height: 38px !important;
      padding: 0 20px !important;
      color: var(--inputcolor) !important;
    }
    input {
      color: var(--inputcolor);
      &::placeholder {
        color: var(--placeholdercolor);
      }
    }

    &.error--text {
      fieldset {
        border-width: 1px;
        border-color: var(--secondary);
      }
    }
  }
  .v-application .text-field .error--text {
    color: var(--secondary) !important;
  }
</style>
