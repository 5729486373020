<template>
  <v-container fluid>
    <h1 class="titulo">Projetos</h1>
    <div>
      <v-row>
        <v-col cols="12" sm="4" lg="4" md="4" xl="4">
          <FilterTextField
              id="FilterTextFieldBusca"
              v-model="buscar"
              maxlength="30"
              placeholder="Digite para buscar"
              v-uppercase
          />
        </v-col>
        <v-col cols="12" sm="12" lg="4" md="4" xl="4">
          <SelectField
              id="SelectFieldStatus"
              :items="status"
              item-text="descricao"
              item-value="status"
              v-model="statusProjeto"
              placeholder="Selecione um status"
              multiple
              persistent-placeholder
          />
        </v-col>
      </v-row>
    </div>
    <DataTable
        url="meu-projeto"
        class="mt-5 data-table-overlow"
        :filter="filters"
        :headers="headers"
        :params="params"
        item-key="idProjeto"
    >
      <template v-slot:[`item.idProjeto`]="item">
        <td>{{ item.idProjeto }}</td>
      </template>
      <template v-slot:[`item.nomeRazaoSocial`]="item">
        <td>{{ item.nomeRazaoSocial }}</td>
      </template>
      <template v-slot:[`item.dataHoraCriacao`]="item">
        <td>{{ toDateBR(item.dataHoraCriacao) }}</td>
      </template>
      <template v-slot:[`item.valorCredito`]="item">
        <td>{{ money(item.valorCredito) }}</td>
      </template>
      <template v-slot:[`item.nome`]="item">
        <td>{{ item.nome }}</td>
      </template>
      <template v-slot:[`item.login`]="item">
        <td><span :class="isPrincipal(item.perfil) ? 'font-weight-black' : ''">{{ item.login }}</span></td>
      </template>
      <template v-slot:[`item.dataHoraStatus`]="item">
        <td>{{ toDateTimeBR(item.dataHoraStatus) }}</td>
      </template>
      <template v-slot:[`item.status`]="item">
        <td>
          <Chip
              :color="getCorStatus(item.status)"
              :texto="getDescricaoStatus(item.status)"
          />
        </td>
      </template>
      <template v-slot:[`item.detalhe`]="item">
        <td class="text-center">
          <span
              :id="`DetalheProjeto_${item.idProjeto}`"
              class="detalhe-projeto"
              @click="detalharProjeto(item)"
          >
            Detalhes
          </span>
        </td>
      </template>
    </DataTable>
    <b>* Nomes destacados significam que a pessoa é comissionado principal</b>
  </v-container>
</template>

<script>
  import { money, toDateBR, toDateTimeBR } from '@/plugins/formatters'
  import { getFilters } from '@/plugins/helpers'
  import { Mensagens } from '@/plugins/constants'
  import { TipoPerfilNewcon } from '../../../../plugins/constants'
  import { getStatusProjeto } from '@/service/projeto'

  export default {
    name: 'Projetos',
    data: () => ({
      headers: [
        { text: 'N° Projeto', value: 'idProjeto', width: 128 },
        { text: 'Cliente', value: 'nomeRazaoSocial' },
        { text: 'Data de Criação', value: 'dataHoraCriacao', width: 175 },
        { text: 'Crédito (R$)', value: 'valorCredito' },
        { text: 'Parceira/Franqueado', value: 'nome', width: 210 },
        { text: 'Login/Sub-login', value: 'login', width: 210 },
        { text: 'Status Dt. Hora', value: 'dataHoraStatus', width: 210 },
        { text: 'Status', value: 'status', width: 230 },
        { value: 'detalhe', sortable: false, align: 'center', width: 100 }
      ],
      pagination: {
        sortBy: [ 'idProjeto' ], sortDesc: [ true ]
      },
      status: [],
      filters: {},
      buscar: '',
      statusProjeto: '',
      money: money,
      toDateBR: toDateBR,
      toDateTimeBR: toDateTimeBR
    }),
    computed: {
      params () {
        return { pagination: this.pagination, administrador: true }
      }
    },
    watch: {
      buscar (value) {
        if (value.length > 2 || !value) {
          this.recarregarDataTable()
        }
      },
      statusProjeto () {
        this.recarregarDataTable()
      }
    },
    mounted () {
      this.getStatus()
    },
    methods: {
      isPrincipal (perfil) {
        return perfil === TipoPerfilNewcon.CLT || perfil === TipoPerfilNewcon.PARCEIRO
      },
      detalharProjeto (projeto) {
        this.$router.push({ name: 'GestaoDetalheProjeto', params: projeto })
      },
      async getStatus () {
        try {
          this.status = await getStatusProjeto()
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
        }
      },
      getCorStatus (status) {
        if (this.status.length > 0) {
          return this.status.filter(item => item.status === status)[0].cor
        }
        return ''
      },
      getDescricaoStatus (status) {
        if (this.status.length > 0) {
          return this.status.filter(item => item.status === status)[0].descricao
        }
        return ''
      },
      recarregarDataTable () {
        let filtro = null
        filtro = getFilters(this.buscar)

        if (this.statusProjeto.length > 0) {
          filtro = { ...filtro, status_projeto: this.statusProjeto.join(',') }
        }
        this.filters = filtro
      }
    }
  }
</script>

<style scoped>
  .detalhe-projeto {
    color: var(--black);
    text-decoration: underline;
    cursor: pointer;
  }
</style>
