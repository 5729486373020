<template>
  <div class="position-relative">
    <TextField
        v-bind="$attrs"
        v-on="$listeners" />
    <AjudaTooltip
        v-if="!disableTooltip"
        class="tooltip"
        :mensagem="tooltip || mensagem"
    />
  </div>
</template>

<script>
  export default {
    name: 'FilterTextField',
    props: ['tooltip', 'disableTooltip'],
    data: () => ({
      mensagem: '- Buscas com números será aplicada apenas à colunas numéricas.\n- Para buscas em colunas com valor em reais, utilize o formato R$ ’9.999,99’.\n- Para buscas em colunas com datas, utilize o formato ‘dd/mm/aaaa’.\n- As buscas com termo alfanumérico serão aplicadas às demais colunas'
    })
  }
</script>

<style scoped>
  .tooltip >>> .btn-toggle {
    position: absolute;
    bottom: 23px; /* 22px é a distância do bottom da div externa até o bottom do input */
    right: 10px;
    margin-bottom: 9px;
    text-decoration: none;
  }
</style>
