import http from '@/plugins/http'
import { get } from 'lodash'
import { Mensagens } from '@/plugins/constants'

export async function gerarTokenTemporario (usuario) {
  try {
    const { data } = await http.get('/download-token', usuario)
    return data
  } catch (erro) {
    const msg = get(erro, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}
