<template>
  <span>
    <a
        class="text-subtitle-1"
        style="text-decoration: underline"
        @click="dialog = true"
    >
      Alterar vendedor
    </a>

    <v-dialog v-model="dialog" persistent>
      <v-card>
        <v-card-title>
          <span>Alterar vendedor</span>
          <v-btn
              icon
              class="ml-auto"
              @click="dialog = false"
          >
            <v-icon dark size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions class="mt-5 text--black">
          <div>
            <p>Selecione o vendedor para o qual deseja mover a reserva desta cota</p>
            <v-row>
              <v-col cols="6">
                <FilterTextField
                    v-model="busca"
                    maxlength="30"
                    placeholder="Digite para buscar"
                    disable-tooltip="true"
                />
              </v-col>
            </v-row>
            <DataTable
                :headers="tabela.cabecalho"
                :filter="tabela.filtros"
                :params="params"
                url="usuario"
                elevation="0"
                :show-select="true"
                :single-select="true"
                @singleSelected="tabela.selecionado = $event"
                :esconder-linha="esconderLinhaTable"
            >
              <template v-slot:[`item.cdComissionado`]="item">
                <td>{{ item.cdComissionado || '-' }}</td>
              </template>
              <template v-slot:[`item.login`]="item">
                <td>{{ item.login }}</td>
              </template>
              <template v-slot:[`item.nome`]="item">
                <td>{{ item.nome.toUpperCase() }}</td>
              </template>
            </DataTable>
          </div>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <Button
              color="secondary"
              outlined
              style="height: 36px !important"
              @click="dialog = false"
          >
            Voltar
          </Button>
          <ConfirmarAlteracaoVendedor
              :nome-vendedor-atual="nomeVendedor"
              :nome-novo-vendedor="getNomeNovoVendedor()"
              :disabled="!tabela.selecionado"
              @aberto="dialog = $event"
              @confirmar="alterarVendedor"
              class="ml-3"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
  import { getFilters } from '@/plugins/helpers'
  import { cancelarReserva, reservarCota } from '@/service/reserva'
  import ConfirmarAlteracaoVendedor from '@/components/ConfirmarAlteracaoVendedor'

  export default {
    components: {
      ConfirmarAlteracaoVendedor
    },
    props: ['idCota', 'idUsuario', 'nomeVendedor'],
    data: () => ({
      dialog: false,
      busca: '',
      tabela: {
        cabecalho: [
          {
            text: 'Comissionado',
            value: 'cdComissionado'
          },
          {
            text: 'Usuário',
            value: 'login'
          },
          {
            text: 'Nome',
            value: 'nome'
          }
        ],
        pagination: {
          sortBy: ['nome'], sortDesc: [false]
        },
        filtros: {},
        selecionado: null
      }
    }),
    computed: {
      params () {
        return { pagination: this.tabela.pagination }
      }
    },
    watch: {
      busca (valor) {
        if (valor.length > 2 || valor.length === 0) {
          this.tabela.filtros = getFilters(valor)
        }
      },
      '$store.state.recarregar': function () {
        this.tabela.filtros = getFilters('')
      }
    },
    methods: {
      async alterarVendedor () {
        try {
          await cancelarReserva({
            idUsuario: this.idUsuario,
            idCotas: [this.idCota]
          })

          await reservarCota({
            idUsuario: this.tabela.selecionado.id,
            idCotas: [this.idCota]
          })

          this.$toast.success('Feito! Reserva alterada')
        } catch (erro) {
          this.$toast.error(erro.message)
        }

        this.dialog = false
      },
      esconderLinhaTable (item) {
        if (this.idUsuario === item.id || item.bloqueado) {
          return false
        }

        return true
      },
      getNomeNovoVendedor () {
        if (this.tabela.selecionado) {
          return this.tabela.selecionado.nome
        }
        return ''
      }
    }
  }
</script>
