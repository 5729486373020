import { login } from '@/service/login'
import session from '@/plugins/session'

export default {
  methods: {
    async autenticar (username, password) {
      const { token, id_usuario: idUsuario, nome, username: usuario, perfil } = await login({ username, password })
      session.set({ token, idUsuario, nome, usuario, perfil })
    },
    async autenticarAzure (acessToken) {
      const { token, id_usuario: idUsuario, nome, username: usuario, perfil, mensagem } = await login({ acessToken })
      session.set({ token, idUsuario, nome, usuario, perfil })
      return mensagem
    },
    async autenticarToken (s, l) {
      const { token, id_usuario: idUsuario, nome, username: usuario, perfil } = await login({ s, l })
      session.set({ token, idUsuario, nome, usuario, perfil })
    }
  }
}
