<template>
  <v-container fluid>
    <v-row no-gutters>
      <h1 class="titulo">Detalhes do projeto</h1>
      <Chip
          id="ChipStatus"
          class="ml-2"
          :color="getCorStatus(projeto.statusProjeto)"
          :texto="getDescricaoStatus(projeto.statusProjeto)"
      />
    </v-row>
    <v-card flat>
      <v-card-text class="cardcolor py-5 px-5 rounded">
        <v-row justify="end">
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="12" lg="4" md="4" xl="4">
                <label class="texto-detalhe">Número do projeto:</label>
                <label class="valor-detalhe">{{ projeto.idProjeto }}</label>
              </v-col>
              <v-col cols="12" sm="12" lg="4" md="4" xl="4">
                <label class="texto-detalhe">Bem:</label>
                <label class="valor-detalhe">{{ projeto.bem }}</label>
              </v-col>
              <v-col cols="12" sm="12" lg="4" md="4" xl="4">
                <label class="texto-detalhe">Vendedor/Parceiro:</label>
                <label class="valor-detalhe">{{ projeto.nomeVendedor }}</label>
              </v-col>
            </v-row>
            <v-divider class="mt-5 d-none d-sm-block" />
          </v-col>
          <v-col cols="12" sm="12" lg="4" md="4" xl="4">
            <v-flex>
              <label class="texto-detalhe">Inserida na plataforma em:</label>
              <label class="valor-detalhe">{{ formatarDataHoraBr(projeto.dataProjeto) }}</label>
            </v-flex>
            <v-flex class="container-valor">
              <label class="texto-detalhe">Nome/Razão:</label>
              <label class="valor-detalhe">{{ projeto.nomeCliente }}</label>
            </v-flex>
            <v-flex class="container-valor">
              <label class="texto-detalhe">CPF/CNPJ:</label>
              <label class="valor-detalhe">{{ formatarCpfOuCnpj(projeto.cpfCnpj) }}</label>
            </v-flex>
            <v-flex class="container-valor">
              <label class="texto-detalhe">Entrada original:</label>
              <label class="valor-detalhe">{{ real(projeto.entradaOriginal) }}</label>
            </v-flex>
          </v-col>
          <v-col cols="12" sm="12" lg="4" md="4" xl="4">
            <v-flex>
              <label class="texto-detalhe">Status atualizado em:</label>
              <label class="valor-detalhe">{{ formatarDataHoraBr(projeto.dataStatus) }}</label>
            </v-flex>
            <v-flex class="container-valor">
              <label class="texto-detalhe">Telefone:</label>
              <label class="valor-detalhe">{{ formatarCelular(projeto.telefone) }}</label>
            </v-flex>
            <v-flex class="container-valor">
              <label class="texto-detalhe">E-mail:</label>
              <label class="valor-detalhe">{{ projeto.email }}</label>
            </v-flex>
            <v-flex class="container-valor">
              <label class="texto-detalhe">Crédito negociado total:</label>
              <label class="valor-detalhe">{{ real(projeto.creditoTotal) }}</label>
            </v-flex>
          </v-col>
          <v-col cols="12" sm="12" lg="4" md="4" xl="4">
            <v-flex class="container-valor">
              <label class="texto-detalhe">Gerente:</label>
              <label class="valor-detalhe">{{ projeto.nomeGerente }}</label>
            </v-flex>
            <v-flex class="container-valor">
              <label class="texto-detalhe">Supervisor:</label>
              <label class="valor-detalhe">{{ projeto.nomeSupervisor }}</label>
            </v-flex>
            <v-flex v-if="projeto.pessoaContato" class="container-valor">
              <label class="texto-detalhe">Pessoa de contato:</label>
              <label class="valor-detalhe">{{ projeto.pessoaContato }}</label>
            </v-flex>
          </v-col>
          <v-col cols="12" sm="12" md="3" lg="3" xl="3">
            <Button
                id="ButtonCancelarProjeto"
                block
                small
                class="secondary"
                @click="validarCancelarProjeto">
              Cancelar projeto
            </Button>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <h1 class="titulo">Cotas negociadas</h1>
    <DataTable
        class="mt-5 data-table-overlow"
        :headers="headers"
        :itens-externos="cotasNegociadas"
        :show-pagination=false
        :click-rodape="validarAplicarDesconto"
        item-key="idCota"
        @on-load="cotasNegociadas = $event"
        :params="params">
      <template v-slot:[`item.cdGrupoCota`]="item">
        <td>{{ item.cdGrupoCota }}</td>
      </template>
      <template v-slot:[`item.protocoloTransferencia`]="item">
        <td>
          <TextField
              :id="`TextFieldProtocoloTransferencia_${item.idCota}`"
              v-model="item.protocoloTransferencia"
              maxlength="10"
              v-mask="'##########'"
              hide-details
              :disabled="podeAtualizarProtocolo()"
              @blur="alterarProtocoloTransferencia(item.idCotaProjeto, item.protocoloTransferencia)"
          />
        </td>
      </template>
      <template v-slot:[`item.valorCompra`]="item">
        <td>{{ money(item.valorCompra || 0) }}</td>
      </template>
      <template v-slot:[`item.cdGrupo-cdCota`]="item">
        <td>{{ item.cdGrupo }}-{{ item.cdCota }}</td>
      </template>
      <template v-slot:[`item.entradaAjustada`]="item">
        <td
            @click="validarAplicarDesconto(true, item)"
        >
          <span class="click">
            {{ money(item.entradaAjustada) }} {{ podeExibirIconeEntrada(item) ? '*' : '' }}
          </span>
        </td>
      </template>
      <template v-slot:[`item.creditoAtual`]="item">
        <td>{{ money(item.creditoAtual) }}</td>
      </template>
      <template v-slot:[`item.valorComissao`]="item">
        <td>{{ money(item.valorComissao) }}</td>
      </template>
      <template v-slot:[`item.valorResultadoFinanceiro`]="item">
        <td>{{ money(item.valorResultadoFinanceiro) }}</td>
      </template>
      <template v-slot:[`item.valorTributo`]="item">
        <td>{{ money(item.valorTributo) }}</td>
      </template>
      <template v-slot:[`item.valorTransferencia`]="item">
        <td>{{ money(item.valorTransferencia) }}</td>
      </template>
      <template v-slot:[`item.contrato`]="item">
        <td>{{ item.contrato }}</td>
      </template>
      <template v-slot:[`item.detalhe`]="item">
        <td class="text-center">
          <span
              :id="`DetalharCota_${item.idCota}`"
              class="detalhe"
              @click="detalharCota(item)"
          >
            Detalhes
          </span>
        </td>
      </template>
      <template v-slot:totalizador />
    </DataTable>
    <v-card
        v-if="podeExibirDesconto()"
        class="mt-5"
    >
      <v-card-text class="cardcolor py-1 px-0 rounded">
        <v-col cols="12">
          <v-row>
            <v-col cols="12" sm="12" md="2" lg="2" xl="2">
              <v-row no-gutters>
                <label class="desconto valor-desconto">(*) Desconto</label>
                <v-divider class="d-none d-sm-block ml-5" vertical />
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
              <label class="desconto">Entrada original:</label>
              <label class="desconto valor-desconto ml-2">{{ real(projeto.entradaOriginal) }}</label>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" xl="7">
              <label class="desconto">Diferença entre entrada original e entrada ajustada:</label>
              <label class="desconto valor-desconto ml-2">{{ getDiferencaTotal() }}</label>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <h1 class="titulo">Anexos do vendedor</h1>
        <v-row>
          <template v-for="(anexo) of getTipoAnexos()">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-bind:key="anexo.idTipoAnexo">
              <FileInput
                  :id="`FileInputAnexoVendedor_${anexo.idTipoAnexo}`"
                  :label="anexo.nome"
                  :envio-automatico="true"
                  :pode-deletar="podeDeletarAnexo"
                  :id-projeto="projeto.idProjeto"
                  :id-tipo-anexo="anexo.idTipoAnexo"
                  :anexo="getAnexos(anexo.idTipoAnexo)"
                  @anexo="verificarQuantidadeAnexos(anexo.idTipoAnexo, $event)"
              />
            </v-col>
          </template>
          <label class="ml-3 texto-informativo-anexo">* São aceitos arquivos nos formatos PDF e imagens JPG, JPEG e PNG de até 10MB.</label>
        </v-row>
        <h1 class="titulo">Anexos administrativo</h1>
        <v-row>
          <template v-for="(anexo) of getTipoAnexosAdministrativo()">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-bind:key="anexo.idTipoAnexo">
              <FileInput
                  :id="`FileInputAnexoAdministrativo_${anexo.idTipoAnexo}`"
                  :label="anexo.nome"
                  :envio-automatico="true"
                  :pode-deletar="podeDeletarAnexo"
                  :id-projeto="projeto.idProjeto"
                  :id-tipo-anexo="anexo.idTipoAnexo"
                  :anexo="getAnexos(anexo.idTipoAnexo)"
              />
            </v-col>
          </template>
          <label class="ml-3 texto-informativo-anexo">* São aceitos arquivos nos formatos PDF e imagens JPG, JPEG e PNG de até 10MB.</label>
        </v-row>
        <h1 class="titulo">Anexos Genérico</h1>
        <v-row>
          <template v-for="(anexo) in anexosGenerico">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-bind:key="anexo.idAnexoGenerico">
              <FileInput
                  :id="`FileInputAnexoGerente_${anexo.idAnexoGenerico}`"
                  :label="anexo.nomeAnexo"
                  :envio-automatico="true"
                  :pode-deletar="podeDeletarAnexo"
                  :id-projeto="projeto.idProjeto"
                  :id-tipo-anexo="0"
                  :generico="true"
                  :anexo="getAnexosGenerico(anexo.idAnexoGenerico)"
                  @anexo="adicionarAnexoGenerico"
                  @removeanexo="removerAnexoGenerico"
              />
            </v-col>
          </template>
          <label class="ml-3 texto-informativo-anexo">* São aceitos arquivos nos formatos PDF e imagens JPG, JPEG e PNG de até 10MB.</label>
        </v-row>
        <h1
            v-if="podeExibirAnexoFinalizado()"
            class="titulo">
          {{ getNomeSecaoAnexoFinalizado() }}
        </h1>
        <v-row v-if="podeExibirAnexoFinalizado()">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <FileInput
                id="FileInputAnexoConclusaoOuEstorno"
                :label="anexoFinalizado.nomeTipoAnexo"
                :envio-automatico="false"
                :pode-deletar="podeDeletarAnexo"
                :id-projeto="projeto.idProjeto"
                :id-tipo-anexo="0"
                :anexo="anexoFinalizado"
            />
          </v-col>
          <label class="ml-3 texto-informativo-anexo">* São aceitos arquivos nos formatos PDF e imagens JPG, JPEG e PNG de até 10MB.</label>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <h1 class="titulo">Histórico de status</h1>
        <v-card>
          <v-list
              style="max-height: 445px"
              class="overflow-y-auto"
              two-line
          >
            <template v-for="(historico, index) in statusHistorico">
              <div v-bind:key="index">
                <CardHistoricoStatus
                    :cor="getCorStatus(historico.status)"
                    :status="getDescricaoStatus(historico.status)"
                    :usuario="historico.usuario"
                    :data-hora-status="historico.dataStatus"
                    :comentario="historico.comentario"
                />
                <v-divider
                    class="mx-5"
                    v-if="index < statusHistorico.length - 1"
                />
              </div>
            </template>
          </v-list>
        </v-card>
        <v-row justify="end" class="mt-2">
          <v-col cols="12" sm="12" md="6" lg="5" xl="4">
            <Button
                id="ButtonVoltar"
                block
                color="secondary"
                outlined
                @click="irParaGestaoMeusProjetos">
              Voltar
            </Button>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="5" xl="4">
            <Button
                id="ButtonAtualizarStatus"
                block
                class="secondary"
                @click="validarAtualizarStatusProjeto">
              Atualizar status
            </Button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
        content-class="app-content"
        v-model="dialogCancelarProjeto"
        persistent
        width="400px">
      <v-form ref="formCancelamento">
        <v-card>
          <v-card-title>
            <slot name="title">Cancelamento do projeto</slot>
            <v-btn
                id="ButtonFecharDialogCancelarProjeto"
                icon
                class="ml-auto"
                @click="dialogCancelarProjeto = false">
              <v-icon
                  color="primary"
                  size="12">
                pc-fechar
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <TextArea
                id="TextAreaComentarioCancelamento"
                v-model="comentarioCancelamento"
                label="Justificativa"
                maxlength="200"
                no-resize
                height="100"
                :rules="[this.$rules.required]"
                v-uppercase
            />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <Button
                id="ButtonCancelarCancelamentoProjeto"
                small
                outlined
                color="secondary"
                @click="dialogCancelarProjeto = false">
              Cancelar
            </Button>
            <Button
                id="ButtonConfirmarCancelamentoProjeto"
                small
                color="secondary"
                @click="confirmarCancelarProjeto">
              Confirmar
            </Button>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
        content-class="app-content"
        v-model="dialogAlterarStatus"
        persistent
        width="400px">
      <v-form ref="formAtualizacaoStatus">
        <v-card>
          <v-card-title>
            <slot name="title">Atualização de status</slot>
            <v-btn
                id="ButtonFecharDialogAlterarStatus"
                icon
                class="ml-auto"
                @click="dialogAlterarStatus = false">
              <v-icon
                  color="primary"
                  size="12">
                pc-fechar
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <SelectField
                id="SelectFieldNovoStatus"
                label="Selecione o status"
                :items="getStatusDisponiveisAlteracao()"
                item-text="text"
                item-value="id"
                placeholder="Selecionar"
                persistent-placeholder
                v-model="novoStatus.status"
                :rules="[this.$rules.required]"
            />
            <TextArea
                id="TextAreaJustificativa"
                v-model="novoStatus.justificativa"
                label="Justificativa"
                maxlength="200"
                no-resize
                height="100"
                :rules="[this.$rules.required]"
                v-if="novoStatus.status"
                v-uppercase
            />
            <div v-if="podeExibirAnexoStatus()">
              <FileInput
                  id="FileInputAnexoFinal"
                  :label="getNomeTipoAnexoFinal()"
                  :pode-visualizar=false
                  :pode-realizar-download=false
                  @anexo="novoStatus.anexo = $event"
              />
              <label class="texto-informativo-anexo">{{ getMensagemInformativaAnexoFinal() }}</label>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <Button
                id="ButtonConfirmarAtualizacaoStatus"
                small
                color="secondary"
                @click="confirmarAtualizacaoStatus">
              Confirmar
            </Button>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
        content-class="app-content"
        v-model="dialogEntradaAjustada"
        persistent
        width="450px">
      <v-form ref="formEntradaAjustada">
        <v-card>
          <v-card-title>
            <slot name="title">Alterar valor da entrada</slot>
            <v-btn
                id="ButtonFecharDialogDesconto"
                icon
                class="ml-auto"
                @click="dialogEntradaAjustada = false"
            >
              <v-icon color="primary" size="12">pc-fechar</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-flex>
              <label class="texto-detalhe">Entrada original:</label>
              <label class="valor-detalhe">{{ real(desconto.valorOriginalCota) }}</label>
            </v-flex>
            <v-flex
                class="mt-4"
                v-if="podeExibirDiferencaEntrada()"
            >
              <label class="texto-detalhe">Entrada ajustada:</label>
              <label class="valor-detalhe">{{ real(desconto.valorDiferenca) }} ( {{ money(desconto.porcentagemDiferenca) }}% )</label>
            </v-flex>
            <v-flex class="mt-4">
              <v-row no-gutters>
                <label class="texto-detalhe mt-2">Nova entrada:</label>
                <DecimalTextField
                    id="DecimalTextFieldValorEntrada"
                    class="ml-3"
                    prefix="R$"
                    v-model="desconto.valorEntrada"
                    :rules="[this.$rules.required]"
                    maxlength="10"
                    validate-on-blur
                    @keyup="recalcularEntradaPorcentagem()"
                />
              </v-row>
            </v-flex>
            <v-flex class="mt-4">
              <v-row no-gutters>
                <label class="texto-detalhe mt-2">Nova entrada:</label>
                <DecimalTextField
                    id="DecimalTextFieldPercentualEntrada"
                    class="ml-3"
                    suffix="%"
                    maxvalue="100"
                    v-model="desconto.porcentagemEntrada"
                    :rules="[this.$rules.required]"
                    permitir-numero-negativo
                    validate-on-blur
                    @keyup="recalcularEntradaValor()"
                />
              </v-row>
            </v-flex>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <Button
                id="ButtonReverterValorOriginal"
                small
                outlined
                color="secondary"
                @click="prepararReverterEntrada">
              Reverter ao valor original
            </Button>
            <Button
                id="ButtonConfirmarValorEntrada"
                small
                color="secondary"
                @click="validarEntrada">
              Confirmar
            </Button>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
  import { alert, unmask, calcularSaldoBruto, calcularResultadoFinanceiro, calcularValorComissao } from '@/plugins/helpers'
  import { Mensagens, StatusProjeto, TipoAnexoFinal, TipoNaturezaAnexo } from '@/plugins/constants'
  import { getAnexo, getAnexoFinalizado, getAnexoGenerico, getTipoAnexo } from '@/service/anexo'
  import { getConfiguracoes } from '@/service/configuracao'
  import { atualizarEntradaAjustada } from '@/service/cota'
  import { formatarCelular, formatarCpfOuCnpj, money, real, toDateBR, toDateTimeBR } from '@/plugins/formatters'
  import {
    atualizarProtocoloTransferencia,
    atualizarStatusProjeto,
    cancelarProjeto,
    getCotasProjeto,
    getDetalhesProjeto,
    getHistoricoStatusProjeto,
    getStatusProjeto
  } from '@/service/projeto'

  export default {
    name: 'VenderDetalheProjeto',
    data: () => ({
      dialogCancelarProjeto: false,
      dialogAlterarStatus: false,
      dialogEntradaAjustada: false,
      headers: [
        { text: 'Grupo-cota', value: 'cdGrupoCota' },
        { text: 'Protocolo de transferência', value: 'protocoloTransferencia' },
        { text: 'Valor de compra (R$)', value: 'valorCompra', totalizar: true },
        { text: 'Entrada (R$)', value: 'entradaAjustada', totalizar: true, clicavel: true, complemento: '' },
        { text: 'Crédito atual (R$)', value: 'creditoAtual', totalizar: true },
        { text: 'Comissão (R$)', value: 'valorComissao', totalizar: true },
        { text: 'Resultado financeiro (R$)', value: 'valorResultadoFinanceiro', totalizar: true },
        { text: 'Valor do tributo', value: 'valorTributo', totalizar: true },
        { text: 'Valor da taxa de transferência', value: 'valorTransferencia', totalizar: true },
        { text: 'Número de contrato', value: 'contrato' },
        { value: 'detalhe', sortable: false, align: 'center', width: 100 }
      ],
      pagination: {
        sortBy: [ 'protocoloTransferencia' ], sortDesc: [ false ]
      },
      projeto: {
        entradaOriginal: 0,
        creditoTotal: 0,
        perfilVendedor: ''
      },
      configuracoes: {},
      cotasNegociadas: [],
      tiposAnexos: [],
      anexos: [],
      anexosAdministrativo: [],
      anexosGenerico: [],
      anexosEnviados: [],
      statusHistorico: [],
      statusDisponiveisAlteracao: [],
      novoStatus: {
        status: '',
        justificativa: '',
        anexo: null
      },
      anexoFinalizado: {},
      desconto: {
        idCotaProjeto: null,
        individual: false,
        reverter: false,
        valorDiferenca: 0,
        porcentagemDiferenca: 0,
        valorOriginalCota: 0,
        valorEntradaCota: 0,
        valorEntrada: 0,
        porcentagemEntrada: 0,
        valorCompra: 0
      },
      custoParcelas: 0.0,
      status: [],
      comentarioCancelamento: '',
      real: real,
      money: money,
      toDateBR: toDateBR,
      formatarCelular: formatarCelular,
      formatarCpfOuCnpj: formatarCpfOuCnpj
    }),
    computed: {
      params () {
        return { pagination: this.pagination }
      }
    },
    watch: {
      dialogEntradaAjustada (valor) {
        if (valor) {
          this.recalcularEntradaPorcentagem()
        }
      }
    },
    async mounted () {
      this.carregarDetalhesProjeto(this.$route.params)
      this.carregarStatusDisponiveisAlteracao()
    },
    methods: {
      recalcularEntradaPorcentagem () {
        this.desconto.porcentagemEntrada = this.getDiferencaPorcentagemEntrada(this.desconto.valorOriginalCota, this.desconto.valorEntrada)
          .toFixed(2)
      },
      recalcularEntradaValor () {
        const valorEntrada = this.getNovoValorEntrada(this.desconto.valorOriginalCota, this.desconto.porcentagemEntrada)
        this.desconto.valorEntrada = valorEntrada
      },
      carregarStatusDisponiveisAlteracao () {
        this.statusDisponiveisAlteracao = [
          { id: StatusProjeto.AGUARDANDO_ANALISE_CREDITO, text: 'Aguardando análise de crédito' },
          { id: StatusProjeto.APROVADO, text: 'Aprovado' },
          { id: StatusProjeto.NEGADO, text: 'Negado' },
          { id: StatusProjeto.REANALISE_DOCUMENTO, text: 'Reanálise do documento' },
          { id: StatusProjeto.AGUARDANDO_DOCUMENTO, text: 'Aguardando documento' },
          { id: StatusProjeto.ESTORNADO, text: 'Estornado' },
          { id: StatusProjeto.CONCLUIDO, text: 'Concluído' }
        ]
      },
      getStatusDisponiveisAlteracao () {
        return this.statusDisponiveisAlteracao.filter(item => item.id !== this.projeto.statusProjeto)
      },
      async carregarDetalhesProjeto (params) {
        try {
          if (params.idProjeto) {
            const idProjeto = params.idProjeto

            this.configuracoes = await getConfiguracoes()
            this.status = await getStatusProjeto()
            this.tiposAnexos = await getTipoAnexo()
            this.anexos = await getAnexo(idProjeto)
            this.preencherAnexosEnviados()
            this.anexosGenerico = await getAnexoGenerico(idProjeto)
            this.anexoFinalizado = await getAnexoFinalizado(idProjeto)
            this.projeto = await getDetalhesProjeto(idProjeto)
            await this.carregarCotasNegociadas(idProjeto)

            this.statusHistorico = await getHistoricoStatusProjeto(idProjeto)

            this.adicionarAnexoGenerico()
          } else {
            this.irParaGestaoMeusProjetos()
          }
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
          this.$router.push({ name: 'GestaoProjetos' })
        }
      },
      preencherAnexosEnviados () {
        this.anexos.map(item => {
          this.anexosEnviados[item.idTipoAnexo] = 'enviado'
        })
      },
      async carregarCotasNegociadas (idProjeto) {
        const itemsCotasNegociadas = await getCotasProjeto(idProjeto)
        this.cotasNegociadas = itemsCotasNegociadas.map(item => {
          item.entradaAjustada = item.entradaAjustada > 0 ? item.entradaAjustada : item.entrada
          const saldoBruto = calcularSaldoBruto(item.entradaAjustada || 0, item.valorCompra)

          item.cdGrupoCota = `${item.cdGrupo}-${item.cdCota}`
          item.valorComissao = calcularValorComissao(this.projeto.perfilVendedor, item.creditoAtual, this.configuracoes)
          item.valorTributo = item.percentualTributo ? saldoBruto * (item.percentualTributo / 100) : 0
          item.valorTransferencia = item.percentualTaxaTransferencia ? (item.percentualTaxaTransferencia * item.valorBemObjeto) / 100 : 0

          this.custoParcelas = item.custoParcelas
          item.valorResultadoFinanceiro = calcularResultadoFinanceiro(saldoBruto, item.valorComissao, item.valorTransferencia, item.valorTributo, this.custoParcelas)
          return item
        }, [])
        this.aplicarDiferencaValor()
      },
      atualizarCotasNegociadas () {
        this.cotasNegociadas = this.cotasNegociadas.map(cota => {
          const saldoBruto = calcularSaldoBruto(cota.entradaAjustada || 0, cota.valorCompra)
          cota.valorResultadoFinanceiro = calcularResultadoFinanceiro(saldoBruto, cota.valorComissao, cota.valorTransferencia, cota.valorTributo, this.custoParcelas)
          return cota
        })
      },
      getCorStatus (status) {
        if (status && this.status.length > 0) {
          return this.status.filter(item => item.status === status)[0].cor
        }
        return ''
      },
      getDescricaoStatus (status) {
        if (status && this.status.length > 0) {
          return this.status.filter(item => item.status === status)[0].descricao
        }
        return ''
      },
      confirmarCancelarProjeto () {
        if (this.$refs.formCancelamento.validate()) {
          alert({
            text: `Confirma o cancelamento do projeto ${this.projeto.idProjeto}? As cotas com reserva expirada ficarão disponíveis novamente e esta ação não poderá ser desfeita`,
            buttons: {
              'Cancelar': () => {},
              'Confirmar': () => {
                this.cancelarProjeto()
              }
            }
          })
        }
      },
      async cancelarProjeto () {
        try {
          const payload = {
            idProjeto: this.projeto.idProjeto,
            comentario: this.comentarioCancelamento
          }
          await cancelarProjeto(payload)
          this.recarregarPagina()
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
        }
      },
      recarregarPagina () {
        location.reload()
      },
      validarCancelarProjeto () {
        if (this.podeCancelarProjeto()) {
          this.dialogCancelarProjeto = true
        } else {
          this.$toast.error("Não é possível cancelar projetos em status de ‘Concluído’, ‘Cancelado’ ou 'Estornado'")
        }
      },
      validarAtualizarStatusProjeto () {
        if (this.podeAtualizarStatusProjeto()) {
          this.dialogAlterarStatus = true
        } else {
          this.$toast.error("Não é possível atualizar projetos em status de ‘Concluído’, ‘Cancelado’ nem 'Estornado'")
        }
      },
      podeCancelarProjeto () {
        return !this.isStatusFinal()
      },
      podeAtualizarStatusProjeto () {
        return !this.isStatusFinal()
      },
      podeExibirAnexoStatus () {
        return this.isNovoStatusConcluido() || this.isNovoStatusEstornado()
      },
      confirmarAtualizacaoStatus () {
        if (this.$refs.formAtualizacaoStatus.validate()) {
          if (this.podeConcluirProjeto()) {
            this.$toast.error('O projeto não pode ser concluído diretamente até que todos os documentos sejam salvos. Pois após concluir a venda, o projeto não poderá ser editado.')
            return
          } else if (this.isNovoStatusConcluido() || this.isNovoStatusEstornado()) {
            if (!this.novoStatus.anexo || this.novoStatus.anexo.size <= 0) {
              const tipoAnexo = this.isNovoStatusConcluido() ? 'contrato de venda' : 'comprovante de estorno'
              this.$toast.error(`É necessário anexar o ${tipoAnexo}`)
              return
            }
          }

          this.atualizarStatusProjeto()
        }
      },
      async atualizarStatusProjeto () {
        try {
          const formData = new FormData()

          const file = this.novoStatus.anexo
          if (file) {
            formData.append('arquivo', file)
            formData.append('nomeDocumento', file.name)
            formData.append('nomeTipoAnexo', this.getNomeTipoAnexoFinal())
          }
          formData.append('idProjeto', this.projeto.idProjeto)
          formData.append('status', this.novoStatus.status)
          formData.append('comentario', this.novoStatus.justificativa)

          await atualizarStatusProjeto(formData)
          this.$toast.success('Status atualizado com sucesso!')
          this.recarregarPagina()
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
        }
      },
      getNomeTipoAnexoFinal () {
        return this.isNovoStatusConcluido() ? TipoAnexoFinal.CONTRATO_VENDA : TipoAnexoFinal.COMPROVANTE_ESTORNO
      },
      podeConcluirProjeto () {
        return this.isNovoStatusConcluido() && this.isProjetoRascunho()
      },
      isNovoStatusConcluido () {
        return this.novoStatus.status === StatusProjeto.CONCLUIDO
      },
      isNovoStatusEstornado () {
        return this.novoStatus.status === StatusProjeto.ESTORNADO
      },
      isProjetoRascunho () {
        return this.projeto.statusProjeto === StatusProjeto.RASCUNHO
      },
      getMensagemInformativaAnexoFinal () {
        if (this.isNovoStatusConcluido()) {
          return '* Para concluir o projeto é necessário anexar o contrato assinado. São aceitos arquivos nos formatos PDF e imagens JPG, JPEG e PNG de até 10MB.'
        }
        return '* Para estornar projeto é necessário anexar o comprovante de estorno. São aceitos arquivos nos formatos PDF e imagens JPG, JPEG e PNG de até 10MB.'
      },
      getDiferencaTotal () {
        const valorEntradaOriginal = this.projeto.entradaOriginal
        const valorEntradaAjustada = this.getValorTotalEntradaAjustada()
        const valorDiferenca = real(this.getDiferencaValorEntrada(valorEntradaOriginal, valorEntradaAjustada))
        const porcentagemDiferenca = this.getDiferencaPorcentagemEntrada(valorEntradaOriginal, valorEntradaAjustada)
        const porcentagemNormalizada = porcentagemDiferenca > 0 ? `+${money(porcentagemDiferenca)}` : money(porcentagemDiferenca)

        return `${valorDiferenca} ( ${porcentagemNormalizada} %)`
      },
      getDiferencaValorEntrada (valorEntradaOriginal, valorEntradaAjustada) {
        if (valorEntradaOriginal && valorEntradaAjustada) {
          return valorEntradaOriginal - valorEntradaAjustada
        }
        return 0
      },
      getDiferencaPorcentagemEntrada (valorEntradaOriginal, valorEntradaAjustada) {
        if (valorEntradaOriginal && valorEntradaAjustada) {
          const difenrecaValorEntrada = valorEntradaAjustada / valorEntradaOriginal
          return (difenrecaValorEntrada - 1) * 100
        }
        return 0
      },
      getNovoValorEntrada (valorEntradaOriginal, porcentagemEntradaAjustada) {
        if (valorEntradaOriginal && porcentagemEntradaAjustada) {
          return valorEntradaOriginal + ((porcentagemEntradaAjustada / 100) * valorEntradaOriginal)
        }
        return 0
      },
      getValorTotalEntradaAjustada () {
        if (this.cotasNegociadas.length > 0) {
          const valorTotal = this.cotasNegociadas.reduce((total, item) => {
            return total + (item.entradaAjustada || 0)
          }, 0)
          return valorTotal
        }
        return 0
      },
      getValorTotalCompra () {
        if (this.cotasNegociadas.length > 0) {
          const valorCompra = this.cotasNegociadas.reduce((total, item) => {
            return total + (item.valorCompra || 0)
          }, 0)
          return valorCompra
        }
        return 0
      },
      getTipoAnexosPessoaFisica () {
        return this.tiposAnexos.filter(item => [TipoNaturezaAnexo.AMBOS, TipoNaturezaAnexo.FISICA].includes(item.tipoPessoa) && !item.anexoAdminsitrativo)
      },
      getTipoAnexosPessoaJuridica () {
        return this.tiposAnexos.filter(item => [TipoNaturezaAnexo.AMBOS, TipoNaturezaAnexo.JURIDICA].includes(item.tipoPessoa) && !item.anexoAdminsitrativo)
      },
      getTipoAnexosAdministrativo () {
        return this.tiposAnexos.filter(item => TipoNaturezaAnexo.AMBOS === item.tipoPessoa && item.anexoAdminsitrativo)
      },
      getTipoAnexos () {
        return unmask(this.projeto.cpfCnpj).length === 11 ? this.getTipoAnexosPessoaFisica() : this.getTipoAnexosPessoaJuridica()
      },
      adicionarAnexoGenerico () {
        if (this.anexosGenerico.length < 5) {
          this.anexosGenerico.push({ nomeAnexo: 'Novo anexo genérico' })
        }
      },
      removerAnexoGenerico (idAnexoServidor) {
        const index = this.anexosGenerico.findIndex(item => item.idAnexoServidor === idAnexoServidor)
        this.anexosGenerico.splice(index, 1)
        this.carregarDetalhesProjeto(this.$route.params)
      },
      getAnexosGenerico (idAnexoGenerico) {
        return this.anexosGenerico.find(item => item.idAnexoGenerico === idAnexoGenerico)
      },
      getAnexos (idTipoAnexo) {
        if (this.anexos.length > 0) {
          return this.anexos.find(item => item.idTipoAnexo === idTipoAnexo)
        }
      },
      podeExibirDesconto () {
        const valorTotalEntradaAjustada = this.getValorTotalEntradaAjustada()
        return (valorTotalEntradaAjustada > 0 && this.projeto.entradaOriginal !== valorTotalEntradaAjustada)
      },
      podeAtualizarProtocolo () {
        return this.isStatusFinal()
      },
      isStatusFinal () {
        return [StatusProjeto.ESTORNADO, StatusProjeto.CANCELADO, StatusProjeto.CONCLUIDO].includes(this.projeto.statusProjeto)
      },
      detalharCota (item) {
        alert({
          text: `Deseja sair da tela do projeto e acessar os detalhes da cota ${item.cdGrupo}-${item.cdCota}?`,
          buttons: {
            'Cancelar': () => {},
            'Confirmar': () => {
              this.$router.push({ name: 'DetalheCota', params: { id: item.idCota } })
            }
          }
        })
      },
      podeDeletarAnexo () {
        return [
          StatusProjeto.RASCUNHO,
          StatusProjeto.EM_PROCESSO,
          StatusProjeto.AGUARDANDO_ANALISE_CREDITO,
          StatusProjeto.AGUARDANDO_DOCUMENTO,
          StatusProjeto.REANALISE_DOCUMENTO,
          StatusProjeto.NEGADO
        ].includes(this.projeto.statusProjeto)
      },
      async alterarProtocoloTransferencia (idCotaProjeto, protocolo) {
        try {
          const payload = {
            idCotaProjeto: idCotaProjeto,
            protocoloTransferencia: protocolo
          }
          await atualizarProtocoloTransferencia(payload)
          this.inserirProtocoloNaLista(idCotaProjeto, protocolo)
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
        }
      },
      inserirProtocoloNaLista (idCotaProjeto, protocolo) {
        this.cotasNegociadas.forEach(item => {
          if (idCotaProjeto === item.idCotaProjeto) {
            item.protocoloTransferencia = protocolo
          }
        })
      },
      irParaGestaoMeusProjetos () {
        this.$router.push({ name: 'GestaoProjetos' })
      },
      validarAplicarDesconto (individual = false, item = null) {
        if (!this.podeAplicarDesconto()) {
          this.$toast.error("Só é possível ajustar as entradas para projetos nos status 'Rascunho', 'Em processo', 'Análise crédito', 'Aprovado' ou 'Negado'.")
          return
        }

        this.desconto.individual = individual
        this.prepararEntrada(item)
      },
      prepararEntrada (item = null) {
        const individual = this.desconto.individual
        this.desconto.valorEntradaCota = individual ? item.entradaAjustada : this.getValorTotalEntradaAjustada()
        this.desconto.valorOriginalCota = individual ? item.entrada : this.projeto.entradaOriginal
        if (individual) {
          this.desconto.valorCompra = item.valorCompra
          this.desconto.valorEntrada = (item.entradaAjustada > 0) ? item.entradaAjustada : null
          this.desconto.porcentagemEntrada =
            (item.entradaAjustada > 0) ? money(this.getDiferencaPorcentagemEntrada(this.desconto.valorOriginalCota, this.desconto.valorEntrada)) : null
        } else {
          this.desconto.valorCompra = this.getValorTotalCompra()
          this.desconto.valorEntrada = null
          this.desconto.porcentagemEntrada = null
        }
        this.desconto.idCotaProjeto = individual ? item.idCotaProjeto : null
        this.dialogEntradaAjustada = true
        this.desconto.reverter = false

        if (individual) {
          this.desconto.valorDiferenca = this.getDiferencaValorEntrada(this.desconto.valorOriginalCota, this.desconto.valorEntradaCota)
          this.desconto.porcentagemDiferenca = this.getDiferencaPorcentagemEntrada(this.desconto.valorOriginalCota, this.desconto.valorEntradaCota)
        } else {
          this.desconto.valorDiferenca = this.getDiferencaValorEntrada(this.desconto.valorOriginalCota, this.desconto.valorEntradaCota)
          this.desconto.porcentagemDiferenca = this.getDiferencaPorcentagemEntrada(this.projeto.entradaOriginal, this.getValorTotalEntradaAjustada())
        }
      },
      async validarEntrada () {
        if (this.$refs.formEntradaAjustada.validate()) {
          if (this.desconto.individual) {
            await this.alterarEntrada(this.desconto.idCotaProjeto)
            this.aplicarEntradaValor()
            this.aplicarDiferencaValor()
            this.dialogEntradaAjustada = false
          } else {
            alert({
              text: 'A diferença do valor de entrada do projeto será aplicado para cada uma das cotas selecionadas. Confirma a alteração de todos os valores? Esta ação não poderá ser desfeita.',
              buttons: {
                'Cancelar': () => {},
                'Confirmar': () => {
                  const forEachPromisse = new Promise(resolve => {
                    let contador = 1

                    this.cotasNegociadas.forEach(async (item, key, array) => {
                      if (this.desconto.reverter) {
                        await this.reverterParaEntradaOriginal(item.idCotaProjeto, item.entrada)
                      } else {
                        await this.alterarEntradaPorcentagem(item.idCotaProjeto, item.entrada)
                      }

                      const ULTIMO_ITEM = array.length - 1
                      if (key === ULTIMO_ITEM) {
                        if (this.desconto.reverter) {
                          this.aplicarEntradaOriginal()
                        } else {
                          this.aplicarEntradaPorcentagem()
                        }
                        this.aplicarDiferencaValor()
                        this.dialogEntradaAjustada = false
                      }

                      if (contador === array.length) {
                        resolve()
                      }

                      contador++
                    })
                  })

                  forEachPromisse.then(() => {
                    this.atualizarCotasNegociadas()
                  })
                }
              }
            })
          }
        }
      },
      async alterarEntrada (idCotaProjeto) {
        try {
          const payload = {
            idCotaProjeto: idCotaProjeto,
            entradaAjustada: this.desconto.valorEntrada
          }
          await atualizarEntradaAjustada(payload)
          this.adicionaComplementoFooter()
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
        }
      },
      async alterarEntradaPorcentagem (idCotaProjeto, valorEntrada) {
        try {
          const novoValorEntrada = valorEntrada + ((this.desconto.porcentagemEntrada / 100) * valorEntrada)
          const payload = {
            idCotaProjeto: idCotaProjeto,
            entradaAjustada: novoValorEntrada
          }
          await atualizarEntradaAjustada(payload)
          this.adicionaComplementoFooter()
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
        }
      },
      async reverterParaEntradaOriginal (idCotaProjeto, valorEntradaOriginal) {
        try {
          const payload = {
            idCotaProjeto: idCotaProjeto,
            entradaAjustada: valorEntradaOriginal
          }
          await atualizarEntradaAjustada(payload)
          this.adicionaComplementoFooter()
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
        }
      },
      aplicarEntradaValor () {
        const cotasNegociadas = this.cotasNegociadas.map(item => {
          if (this.desconto.idCotaProjeto === item.idCotaProjeto) {
            item.entradaAjustada = parseFloat(this.desconto.valorEntrada)
          }
          return item
        })
        this.cotasNegociadas = cotasNegociadas
      },
      adicionaComplementoFooter () {
        this.headers.forEach(item => {
          if (item.value === 'entradaAjustada') {
            item.complemento = '*'
          }
        })
      },
      aplicarEntradaPorcentagem () {
        const cotasNegociadas = this.cotasNegociadas.map(item => {
          item.entradaAjustada = parseFloat(item.entrada + ((this.desconto.porcentagemEntrada / 100) * item.entrada))
          return item
        })
        this.cotasNegociadas = cotasNegociadas
      },
      aplicarEntradaOriginal () {
        const cotasNegociadas = this.cotasNegociadas.map(item => {
          item.entradaAjustada = parseFloat(item.entrada)
          return item
        })
        this.cotasNegociadas = cotasNegociadas
      },
      aplicarDiferencaValor () {
        let diferencaCentavos = 0
        const cotasNegociadas = this.cotasNegociadas.map((item, key, array) => {
          const entradaAjustada = parseFloat(item.entradaAjustada.toFixed(2))
          if (entradaAjustada > item.entradaAjustada) {
            diferencaCentavos += entradaAjustada - item.entradaAjustada
          } else {
            diferencaCentavos += item.entradaAjustada - entradaAjustada
          }
          const ULTIMO_ITEM = array.length - 1
          if (key === ULTIMO_ITEM) {
            item.entradaAjustada = parseFloat(item.entradaAjustada + diferencaCentavos)
          }
          return item
        })
        this.cotasNegociadas = cotasNegociadas
      },
      prepararReverterEntrada () {
        if (this.desconto.individual) {
          this.desconto.valorEntrada = this.desconto.valorOriginalCota
          this.desconto.porcentagemEntrada = 0
          this.desconto.valorDiferenca = this.getDiferencaValorEntrada(this.desconto.valorOriginalCota, this.desconto.valorEntradaCota)
          this.desconto.porcentagemDiferenca = this.getDiferencaPorcentagemEntrada(this.desconto.valorOriginalCota, this.desconto.valorEntradaCota)
        } else {
          this.desconto.reverter = true
          this.desconto.valorEntrada = this.projeto.entradaOriginal
          this.desconto.porcentagemEntrada = 0
          this.desconto.valorDiferenca = this.getDiferencaValorEntrada(this.desconto.valorOriginalCota, this.desconto.valorEntradaCota)
          this.desconto.porcentagemDiferenca = this.getDiferencaPorcentagemEntrada(this.projeto.entradaOriginal, this.getValorTotalEntradaAjustada())
        }
      },
      podeAplicarDesconto () {
        return [
          StatusProjeto.RASCUNHO,
          StatusProjeto.EM_PROCESSO,
          StatusProjeto.AGUARDANDO_ANALISE_CREDITO,
          StatusProjeto.APROVADO,
          StatusProjeto.NEGADO
        ].includes(this.projeto.statusProjeto)
      },
      podeExibirDiferencaEntrada () {
        return this.desconto.valorEntradaCota
      },
      formatarDataHoraBr (dataHora) {
        return dataHora ? toDateTimeBR(dataHora) : '-'
      },
      podeExibirIconeEntrada (item) {
        const podeExibir = item.entradaAjustada > 0 && item.entrada !== item.entradaAjustada
        if (podeExibir) {
          this.adicionaComplementoFooter()
        }
        return podeExibir
      },
      podeExibirAnexoFinalizado () {
        return (this.anexoFinalizado)
      },
      getNomeSecaoAnexoFinalizado () {
        return this.anexoFinalizado.nomeTipoAnexo === TipoAnexoFinal.CONTRATO_VENDA
          ? 'Anexo de conclusão'
          : 'Anexo de estorno'
      },
      async verificarQuantidadeAnexos (idTipoAnexo, file) {
        const quantidadeAnexos = this.getTipoAnexos().length
        this.anexosEnviados[idTipoAnexo] = file
        const anexosEnviados = this.anexosEnviados.filter(item => item !== null).length
        if (quantidadeAnexos === anexosEnviados) {
          const formData = new FormData()
          formData.append('idProjeto', this.projeto.idProjeto)
          formData.append('status', StatusProjeto.EM_PROCESSO)
          await atualizarStatusProjeto(formData)
          this.recarregarPagina()
        }
      }
    }
  }
</script>

<style scoped>
.valor-detalhe {
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
  color: var(--black);
}

.desconto,
.texto-detalhe {
  font-size: 14px;
  color: var(--black);
}

.valor-desconto {
  font-weight: bold;
}

.container-valor {
  margin-top: 5px;
}

.detalhe {
  color: var(--black);
  text-decoration: underline;
  cursor: pointer;
}

.click {
  text-decoration: underline;
  cursor: pointer;
}
</style>
