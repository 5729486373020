<template>
  <v-container fluid>
    <v-row no-gutters>
      <h1 class="titulo">Detalhes da cota</h1>
      <Chip
          id="ChipStatus"
          class="ml-2"
          :color="corStatus"
          :texto="getDescricaoStatus(cota.status)"
      />
    </v-row>
    <div>
      <v-form
          ref="form"
          :disabled="isEdicaoDesabilitada"
      >
        <v-card flat>
          <v-card-text class="cardcolor py-5 px-5 rounded">
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" sm="12" lg="3" md="4" xl="3">
                    <v-flex>
                      <label class="texto-detalhe">Contrato:</label>
                      <label class="valores-cota">{{ cota.contrato }}</label>
                    </v-flex>
                  </v-col>
                  <v-col cols="12" sm="12" lg="3" md="3" xl="3">
                    <v-flex class="mt-2">
                      <label class="texto-detalhe">Grupo-cota:</label>
                      <label class="valores-cota">{{ cota.cdGrupo }}-{{ cota.cdCota }}</label>
                    </v-flex>
                  </v-col>
                  <v-col cols="12" sm="12" lg="3" md="3" xl="3">
                    <v-flex class="mt-2">
                      <label class="texto-detalhe">Produto:</label>
                      <label class="valores-cota capitalize">{{ cota.bem }}</label>
                    </v-flex>
                  </v-col>
                  <v-col cols="12" sm="12" lg="3" md="3" xl="3">
                    <v-flex class="mt-2">
                      <label class="texto-detalhe">Vendedor:</label>
                      <label class="valores-cota capitalize">{{ nomeVendedor }}</label>
                    </v-flex>
                  </v-col>
                </v-row>
                <v-divider class="mt-5 d-none d-sm-block" />
              </v-col>
              <v-col cols="12" sm="12" lg="3" md="3" xl="3">
                <v-flex>
                  <label class="texto-detalhe">Saldo devedor:</label>
                  <label class="valores-cota">{{ real(cota.saldoDevedor) }}</label>
                </v-flex>

                <v-flex class="mt-2">
                  <label class="texto-detalhe">Prazo restante:</label>
                  <label class="valores-cota">{{ cota.prazoRestante }} meses</label>
                </v-flex>

                <v-flex class="mt-2">
                  <label class="texto-detalhe">Bem Objeto:</label>
                  <label class="valores-cota">{{ real(cota.valorBemObjeto) }}</label>
                </v-flex>
              </v-col>
              <v-col cols="12" sm="12" lg="3" md="3" xl="3">
                <v-flex>
                  <label class="texto-detalhe">Crédito atual:</label>
                  <label class="valores-cota">{{ real(cota.creditoAtual) }}</label>
                </v-flex>
                <v-flex class="mt-2">
                  <label class="texto-detalhe">Parcela atual:</label>
                  <label class="valores-cota">{{ real(cota.parcelaAtual) }}</label>
                </v-flex>
              </v-col>
              <v-col cols="12" sm="12" lg="3" md="3" xl="3">
                <v-flex>
                  <label class="texto-detalhe">Venc. próxima parcela:</label>
                  <label class="valores-cota">{{ toDateBR(cota.vencimentoProximaParcela) }}</label>
                </v-flex>
                <v-flex class="mt-2">
                  <label class="texto-detalhe">Custo de parcelas:</label>
                  <label class="valores-cota">{{ real(cota.custoParcelas) }}</label>
                </v-flex>
              </v-col>
              <v-col cols="12" sm="12" lg="3" md="3" xl="3">
                <v-flex>
                  <label class="texto-detalhe">Inserida na plataforma em:</label>
                  <label class="valores-cota">{{ formatarDataHoraBr(cota.dataHoraCriacao) }}</label>
                </v-flex>
                <v-flex class="mt-2">
                  <label class="texto-detalhe">Última sincronização em:</label>
                  <label class="valores-cota">{{ formatarDataHoraBr(cota.dataHoraSincronizacao) }}</label>
                </v-flex>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="4" lg="4">
                <v-row>
                  <v-col>
                    <DecimalTextField
                        :permitir-numero-negativo="true"
                        id="DecimalTextFieldPercentualTributo"
                        suffix="%"
                        label="Tributo"
                        maxvalue="100.00"
                        minvalue="0.00"
                        v-model="cota.percentualTributo"
                        @keydown="validarPercentualTributo"
                    />
                  </v-col>
                  <v-col class="flex-end">
                    <DecimalTextField
                        :permitir-numero-negativo="true"
                        id="DecimalTextFieldValorTributo"
                        prefix="R$"
                        disabled
                        :value="calculado.valorTributo"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4">
                <v-row>
                  <v-col>
                    <DecimalTextField
                        :permitir-numero-negativo="true"
                        id="DecimalTextFieldPercentualComissao"
                        suffix="%"
                        label="Comissão média"
                        maxvalue="100.00"
                        v-model="cota.percentualComissao"
                    />
                  </v-col>
                  <v-col class="flex-end">
                    <DecimalTextField
                        :permitir-numero-negativo="true"
                        id="DecimalTextFieldValorComissao"
                        prefix="R$"
                        disabled
                        :value="calculado.valorComissao"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4">
                <v-row>
                  <v-col>
                    <DecimalTextField
                        :permitir-numero-negativo="true"
                        id="DecimalTextFieldPercentualTaxaTransferencia"
                        suffix="%"
                        label="Taxa de transferência"
                        maxvalue="100.00"
                        v-model="cota.percentualTaxaTransferencia"
                    />
                  </v-col>
                  <v-col class="flex-end">
                    <DecimalTextField
                        :permitir-numero-negativo="true"
                        id="DecimalTextFieldValorTaxaTransferencia"
                        prefix="R$"
                        disabled
                        :value="calculado.valorTaxaTransferencia"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="4" lg="4">
                <DecimalTextField
                    :permitir-numero-negativo="true"
                    id="DecimalTextFieldValorCompra"
                    prefix="R$"
                    label="Valor da compra"
                    v-model="cota.valorCompra"
                    maxlength="12"
                    :rules="required"
                />
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4">
                <v-row>
                  <v-col>
                    <DecimalTextField
                        :permitir-numero-negativo="true"
                        id="DecimalTextFieldPercentualEntrada"
                        suffix="%"
                        label="Entrada"
                        maxvalue="100.00"
                        v-model="cota.percentualEntrada"
                        tooltip="Calculado automaticamente pela porcentagem de entrada definida, do valor de Crédito atual da Cota"
                        :rules="required"
                    />
                  </v-col>
                  <v-col class="flex-end">
                    <DecimalTextField
                        :permitir-numero-negativo="true"
                        id="DecimalTextFieldValorEntrada"
                        prefix="R$"
                        disabled
                        v-model="calculado.valorEntrada"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4">
                <DecimalTextField
                    :permitir-numero-negativo="true"
                    id="DecimalTextFieldValorSaldoBruto"
                    prefix="R$"
                    disabled
                    label="Saldo bruto"
                    :value="calculado.valorSaldoBruto"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" lg="2">
                <DecimalTextField
                    :permitir-numero-negativo="true"
                    id="DecimalTextFieldPercentualResultadoFinanceiro"
                    suffix="%"
                    disabled
                    label="Resultado financeiro"
                    v-model="calculado.percentualResultadoFinanceiro"
                    tooltip="Resultado da subtração do 'Custo de parcelas', ‘Comissão média’, ‘Taxa de transferência’ e 'Tributo' do ‘Saldo bruto'"
                />
              </v-col>
              <v-col cols="6" lg="2" class="flex-end">
                <DecimalTextField
                    :permitir-numero-negativo="true"
                    id="DecimalTextFieldValorResultadoFinanceiro"
                    prefix="R$"
                    disabled
                    :value="calculado.valorResultadoFinanceiro"
                />
              </v-col>
              <v-col class="align-self-center" cols="8">
                <Checkbox
                    id="CheckboxDisponivelVenda"
                    v-model="cota.disponivelVenda"
                    label="Disponível para venda"
                    color="secondary"
                />
              </v-col>
            </v-row>
            <v-col
                cols="12"
                class="px-0"
                v-if="hasTextoInformativo"
            >
              <v-icon color="iconcolor">pc-atencao</v-icon>
              <label class="texto-informativo ml-2">{{ textoInformativo }}</label>
            </v-col>
          </v-card-text>
        </v-card>
      </v-form>
      <VinculoCota
          v-if="cota.id"
          :id-cota="cota.id"
          @nomeVendedor="nomeVendedor = $event"
      />
    </div>
    <v-card flat>
      <v-card-text class="cardcolor rounded py-10">
        <v-row justify="end">
          <v-col cols="12" sm="12" md="3" lg="2" xl="2">
            <Button
                id="ButtonVoltar"
                block
                color="secondary"
                outlined
                @click="irParaListagemDeCotas"
            >
              Voltar
            </Button>
          </v-col>
          <v-col cols="12" sm="12" md="3" lg="2" xl="2" v-if="podeSalvar">
            <Button
                id="ButtonSalvar"
                block
                class="secondary"
                @click="salvar"
            >
              Salvar
            </Button>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { real, toDateBR, toDateTimeBR } from '@/plugins/formatters'
  import { StatusCota, Mensagens } from '@/plugins/constants'
  import { getConfiguracoes } from '@/service/configuracao'
  import { getCota, atualizarCota, getStatusCota } from '@/service/cota'
  import VinculoCota from '@/components/VinculoCota'
  import { calcularPercentualResultadoFinanceiro, calcularResultadoFinanceiro, calcularSaldoBruto } from '@/plugins/helpers'

  export default {
    name: 'CotasContempladas',
    components: {
      VinculoCota
    },
    data: () => ({
      podeSalvar: true,
      cota: {
        id: null,
        contrato: '',
        cdGrupo: '',
        cdCota: '',
        bem: '',
        creditoAtual: 0.00,
        custoParcelas: 0,
        prazoRestante: 0,
        parcelaAtual: 0.00,
        vencimentoProximaParcela: '',
        saldoDevedor: 0.00,
        disponivelVenda: false,
        status: '',
        dataHoraSincronizacao: '',
        dataHoraCriacao: '',
        percentualTributo: 0.00,
        percentualComissao: 0.00,
        percentualTaxaTransferencia: 0.00,
        percentualEntrada: 0.00,
        valorCompra: 0.00,
        valorBemObjeto: 0.00
      },
      configuracoes: {
        percentualTributo: 0,
        percentualTaxaTransferencia: 0,
        percentualEntrada: 0,
        percentualComissaoMedia: 0
      },
      calculado: {
        valorTributo: 0.00,
        valorComissao: 0.00,
        valorTaxaTransferencia: 0.00,
        valorEntrada: 0.00,
        valorSaldoBruto: 0.00,
        percentualResultadoFinanceiro: 0.00,
        valorResultadoFinanceiro: 0.00
      },
      nomeVendedor: '',
      status: [],
      corStatus: '',
      textoInformativo: '',
      hasTextoInformativo: false,
      required: [],
      isEdicaoDesabilitada: false,
      real: real,
      toDateBR: toDateBR
    }),
    computed: {
      percentualTributo () {
        return this.cota.percentualTributo
      },
      percentualComissao () {
        return this.cota.percentualComissao
      },
      percentualTaxaTransferencia () {
        return this.cota.percentualTaxaTransferencia
      },
      valorCompra () {
        return this.cota.valorCompra
      },
      valorEntrada () {
        return this.calculado.valorEntrada
      },
      porcentagemEntrada () {
        return this.cota.percentualEntrada
      },
      disponivelVenda () {
        return this.cota.disponivelVenda
      }
    },
    watch: {
      percentualTributo () {
        this.calcularValorTributo()
        this.calcularResultadoFinanceiro()
      },
      percentualComissao () {
        this.calculado.valorComissao = (this.cota.creditoAtual * (this.cota.percentualComissao / 100))
        this.calcularResultadoFinanceiro()
      },
      percentualTaxaTransferencia () {
        this.calculado.valorTaxaTransferencia = this.cota.valorBemObjeto * (this.cota.percentualTaxaTransferencia / 100)
        this.calcularResultadoFinanceiro()
      },
      valorCompra () {
        this.calcularSaldoBruto()
        this.calcularValorTributo()
      },
      valorEntrada () {
        this.calcularSaldoBruto()
      },
      porcentagemEntrada () {
        const valorEntrada = (this.cota.creditoAtual * (this.cota.percentualEntrada / 100))
        this.calculado.valorEntrada = valorEntrada > 0 ? valorEntrada : null
        this.calcularResultadoFinanceiro()
      },
      'calculado.valorSaldoBruto': function () {
        if (this.calculado.valorSaldoBruto < 0) {
          this.cota.percentualTributo = 0
        }

        this.calcularValorTributo()
        this.calcularResultadoFinanceiro()
      },
      disponivelVenda (disponivel) {
        this.required = disponivel ? [this.$rules.required, this.$rules.maior_que_zero] : []
      }
    },
    created () {
      this.loadParams(this.$route.params)
    },
    methods: {
      async loadParams (params) {
        if (params.id) {
          try {
            this.configuracoes = await getConfiguracoes()
            this.status = await getStatusCota()

            this.cota = await getCota(params.id)

            if (!this.cota.percentualTributo) {
              this.cota.percentualTributo = this.configuracoes.percentualTributo
            }
            if (!this.cota.percentualComissao) {
              this.cota.percentualComissao = this.configuracoes.percentualComissaoMedia
            }
            if (!this.cota.percentualTaxaTransferencia) {
              this.cota.percentualTaxaTransferencia = this.configuracoes.percentualTaxaTransferencia
            }
            if (!this.cota.percentualEntrada) {
              this.cota.percentualEntrada = this.configuracoes.percentualEntrada
            }

            if (this.cota.valorEntradaAjustada > 0) {
              this.cota.percentualEntrada = this.cota.valorEntradaAjustada / this.cota.creditoAtual * 100
            }

            this.validaCotaIndisponivel()

            this.corStatus = this.getCorStatus()
            this.textoInformativo = this.getTextoInformativo()
            this.hasTextoInformativo = (this.textoInformativo)
            this.isEdicaoDesabilitada = this.podeEditarCota()
          } catch (erro) {
            this.$toast.error(Mensagens.ERRO)
          }
        } else {
          this.irParaListagemDeCotas()
        }
      },
      calcularSaldoBruto () {
        this.calculado.valorSaldoBruto = calcularSaldoBruto(this.calculado.valorEntrada || 0, this.cota.valorCompra)
        this.calcularResultadoFinanceiro()
      },
      calcularResultadoFinanceiro () {
        this.calculado.valorResultadoFinanceiro = calcularResultadoFinanceiro(this.calculado.valorSaldoBruto, this.calculado.valorComissao, this.calculado.valorTaxaTransferencia, this.calculado.valorTributo, this.cota.custoParcelas)
        this.calculado.percentualResultadoFinanceiro = calcularPercentualResultadoFinanceiro(this.calculado.valorResultadoFinanceiro, this.cota.creditoAtual)
      },
      calcularValorTributo () {
        const valorTributo = (this.calculado.valorSaldoBruto * (this.cota.percentualTributo / 100))
        let tributo = valorTributo !== null ? valorTributo : 0
        this.calculado.valorTributo = tributo > 0 ? tributo : 0
      },
      irParaListagemDeCotas () {
        this.$router.push({ name: 'GestaoCotasContempladas' })
      },
      async salvar () {
        if (this.$refs.form.validate()) {
          try {
            const status = this.cota.disponivelVenda ? 'ativa' : 'indisponivel'
            const payload = {
              status: status,
              percentualTributo: this.parseValor(this.cota.percentualTributo),
              percentualComissao: this.parseValor(this.cota.percentualComissao),
              percentualTaxaTransferencia: this.parseValor(this.cota.percentualTaxaTransferencia),
              percentualEntrada: this.parseValor(this.cota.percentualEntrada),
              valorEntrada: this.parseValor(this.calculado.valorEntrada),
              valorCompra: this.parseValor(this.cota.valorCompra),
              percentualResultadoFinanceiro: this.parseValor(this.calculado.percentualResultadoFinanceiro),
              disponivelVenda: this.cota.disponivelVenda
            }
            await atualizarCota(this.cota.id, payload)
            this.$router.push({ name: 'GestaoCotasContempladas' })
          } catch (erro) {
            this.$toast.error(Mensagens.ERRO)
          }
        } else {
          this.$toast.error('Campos obrigatórios não preenchidos, verifique o formulário e tente novamente')
        }
      },
      parseValor (value) {
        return value > 0 ? parseFloat(value).toFixed(2) : '0.00'
      },
      getCorStatus () {
        const status = this.cota.status
        if (status && this.status.length > 0) {
          return this.status.filter(item => item.status === status)[0].cor
        }
        return ''
      },
      getDescricaoStatus (status) {
        if (status && this.status.length > 0) {
          return this.status.filter(item => item.status === status)[0].descricao
        }
        return ''
      },
      validaCotaIndisponivel () {
        this.podeSalvar = true
        const status = this.cota.status

        if (StatusCota.INDISPONIVEL === status) {
          this.podeSalvar = false
        }

        this.getHabilitaBotaoSalvar()
      },
      getHabilitaBotaoSalvar () {
        const status = this.cota.status
        if ([ StatusCota.RESERVADA, StatusCota.EM_PROJETO ].includes(status)) {
          this.podeSalvar = false
        } else if (StatusCota.INDISPONIVEL === status) {
          this.podeSalvar = false
        } else if (StatusCota.VENDIDA === status) {
          this.podeSalvar = false
        } else if (StatusCota.TRANSFERIDA === status) {
          this.podeSalvar = false
        }
      },
      getTextoInformativo () {
        const status = this.cota.status
        if ([ StatusCota.RESERVADA, StatusCota.EM_PROJETO ].includes(status)) {
          return 'Não é possível editar uma cota que esteja vinculada à uma reserva ou à um projeto.'
        } else if (StatusCota.INDISPONIVEL === status) {
          return 'Não é possível editar uma cota com status “Indisponível"'
        } else if (StatusCota.VENDIDA === status) {
          return 'Não é possível editar uma cota com status “Vendida"'
        } else if (StatusCota.TRANSFERIDA === status) {
          return 'Não é possível editar uma cota com status “Transferida"'
        }
      },
      podeEditarCota () {
        const status = this.cota.status
        return [ StatusCota.RESERVADA, StatusCota.EM_PROJETO, StatusCota.INDISPONIVEL, StatusCota.VENDIDA, StatusCota.TRANSFERIDA ].includes(status)
      },
      formatarDataHoraBr (dataHora) {
        return dataHora ? toDateTimeBR(dataHora) : '-'
      },
      validarPercentualTributo (event) {
        if (this.calculado.valorSaldoBruto < 0) {
          event.preventDefault()
        }
      }
    }
  }
</script>

<style scoped>
  .valores-cota {
    margin-left: 5px;
    font-size: 14px;
    font-weight: bold;
    color: var(--black);
  }

  .texto-informativo {
    font-size: 14px;
    font-weight: bold;
    color: var(--black);
  }

  .texto-detalhe {
    font-size: 14px;
    color: var(--black);
  }

  .flex-end {
    align-self: flex-end;
  }

  .capitalize {
    text-transform: capitalize;
  }
</style>
