<template>
  <v-layout v-resize="onResize">
    <v-container fluid>
      <h1 class="titulo">Reservas</h1>
      <DataTable
          ref="datatable"
          class="mt-5 data-table-overlow"
          v-model="itensSelecionados[null]"
          show-select
          :headers="headers"
          :params="params"
          :itens-externos="getReservaPorEtiqueta(null)"
          item-key="id_cota"
          :show-pagination=false
          titulo="Lista de cotas não classificadas"
          :cor-checkbox="null"
      >
        <template v-slot:[`item.produto.nome`]="item">
          <td>{{ item.produto.nome }}</td>
        </template>
        <template v-slot:[`item.creditoAtual`]="item">
          <td>{{ money(item.creditoAtual) }}</td>
        </template>
        <template v-slot:[`item.prazoRestante`]="item">
          <td>{{ item.prazoRestante }}</td>
        </template>
        <template v-slot:[`item.parcelaAtual`]="item">
          <td>{{ money(item.parcelaAtual) }}</td>
        </template>
        <template v-slot:[`item.saldoDevedor`]="item">
          <td>{{ money(item.saldoDevedor) }}</td>
        </template>
        <template v-slot:[`item.valorEntrada`]="item">
          <td>{{ money(item.valorEntrada) }}</td>
        </template>
        <template v-slot:[`item.dataHoraReserva`]="item">
          <td>
            <ContadorRegressivo
                :data-hora-reserva="item.dataHoraReserva"
                :configuracoes="configuracoes"
                :somar-penalidade=false
                @contagemFinalizada="atualizarReservaZerada"
            />
          </td>
        </template>
        <template v-slot:[`item.cdGrupoCota`]="item">
          <td>{{ item.cdGrupo }}-{{ item.cdCota }}</td>
        </template>
        <template v-slot:[`item.contrato`]="item">
          <td>{{ item.contrato }}</td>
        </template>
        <template v-slot:[`item.vencimentoProximaParcela`]="item">
          <td>{{ toDateBR(item.vencimentoProximaParcela) }}</td>
        </template>
        <template v-slot:totalizador />
        <template v-if="getReservaPorEtiqueta(null).length > 0" v-slot:footer>
          <v-col>
            <v-row class="fill-height" align="center" wrap>
              <v-col>
                <v-flex>
                  <v-btn
                      id="ButtonCancelamentoReserva"
                      text
                      color="secondary"
                      @click="preparaCancelamentoReserva(null)"
                  >
                    <v-icon color="secondary">pc-excluir</v-icon>
                    {{ descricaoCancelarReserva }}
                  </v-btn>
                  <v-btn
                      id="ButtonClassificarReserva"
                      text
                      color="secondary"
                      @click="preparaClassificarReserva(null)"
                  >
                    <v-icon color="secondary">pc-lista</v-icon>
                    {{ getDescricaoClassificarLista(null) }}
                  </v-btn>
                </v-flex>
              </v-col>
              <v-col
                  cols="12"
                  md="3"
                  lg="3"
              >
                <Button
                    id="ButtonGerarProjeto"
                    block
                    class="secondary"
                    @click="gerarProjeto(null)"
                >
                  {{ getDescricaoGerarProjeto(null) }}
                </Button>
              </v-col>
            </v-row>
          </v-col>
        </template>
      </DataTable>
      <template v-for="(etiqueta, index) of etiquetas">
        <div v-show="getReservaPorEtiqueta(etiqueta.cor).length > 0" v-bind:key="index">
          <DataTable
              v-bind:key="index"
              class="mt-5 data-table-overlow"
              v-model="itensSelecionados[etiqueta.cor]"
              show-select
              :headers="headers"
              :params="params"
              :itens-externos="getReservaPorEtiqueta(etiqueta.cor)"
              item-key="id_cota"
              :show-pagination=false
              titulo="Lista com etiqueta"
              :cor-checkbox="etiqueta.cor">
            <template v-slot:[`item.produto.nome`]="item">
              <td>{{ item.produto.nome }}</td>
            </template>
            <template v-slot:[`item.creditoAtual`]="item">
              <td>{{ money(item.creditoAtual) }}</td>
            </template>
            <template v-slot:[`item.prazoRestante`]="item">
              <td>{{ item.prazoRestante }}</td>
            </template>
            <template v-slot:[`item.parcelaAtual`]="item">
              <td>{{ money(item.parcelaAtual) }}</td>
            </template>
            <template v-slot:[`item.saldoDevedor`]="item">
              <td>{{ money(item.saldoDevedor) }}</td>
            </template>
            <template v-slot:[`item.valorEntrada`]="item">
              <td>{{ money(item.valorEntrada) }}</td>
            </template>
            <template v-slot:[`item.dataHoraReserva`]="item">
              <td>
                <ContadorRegressivo
                    :data-hora-reserva="item.dataHoraReserva"
                    :configuracoes="configuracoes"
                    :somar-penalidade=false
                    @contagemFinalizada="atualizarReservaZerada"
                />
              </td>
            </template>
            <template v-slot:[`item.cdGrupoCota`]="item">
              <td>{{ item.cdGrupo }}-{{ item.cdCota }}</td>
            </template>
            <template v-slot:[`item.contrato`]="item">
              <td>{{ item.contrato }}</td>
            </template>
            <template v-slot:[`item.vencimentoProximaParcela`]="item">
              <td>{{ toDateBR(item.vencimentoProximaParcela) }}</td>
            </template>
            <template v-slot:totalizador />
            <template v-slot:footer>
              <v-col>
                <v-row class="center">
                  <v-col class="mr-10" cols="12" sm="12" md="3" lg="2" xl="2">
                    <v-btn
                        :id="`ButtonDesclassificarReserva_${index}`"
                        text
                        color="secondary"
                        @click="desclassificarReserva(etiqueta.cor)"
                    >
                      <v-icon color="secondary">pc-excluir</v-icon>
                      {{ getDescricaoExcluirLista(etiqueta.cor) }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" lg="2" xl="2">
                    <v-btn
                        :id="`ButtonClassificarReserva_${index}`"
                        text
                        color="secondary"
                        @click="preparaClassificarReserva(etiqueta.cor)"
                    >
                      <v-icon color="secondary">pc-lista</v-icon>
                      {{ getDescricaoClassificarLista(etiqueta.cor) }}
                    </v-btn>
                  </v-col>
                  <v-col class="ml-auto" cols="12" sm="12" md="3" lg="3" xl="3">
                    <Button
                        :id="`ButtonGerarProjeto_${index}`"
                        block
                        class="secondary"
                        @click="gerarProjeto(etiqueta.cor)"
                    >
                      {{ getDescricaoGerarProjeto(etiqueta.cor) }}
                    </Button>
                  </v-col>
                </v-row>
              </v-col>
            </template>
          </DataTable>
        </div>
      </template>
      <v-dialog
          v-on="$listeners"
          :scrollable="true"
          content-class="app-content"
          v-model="dialog"
          persistent
      >
        <v-card>
          <v-card-title>
            <slot name="title">Reclassificar reservas</slot>
            <v-btn
                id="ButtonDialogReclassificarReserva"
                icon
                class="ml-auto"
                @click="dialog = false"
            >
              <v-icon dark size="12">pc-fechar</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pb-0">
            <div v-if="swatches.length > 0">
              <slot>Selecione a cor da etiqueta:</slot>
              <ColorPicker
                  id="ColorPickerEtiquetasDisponiveis"
                  :swatches="swatches"
                  v-model="corSelecionada"
                  show-swatches
                  hide-canvas
                  hide-inputs
                  hide-mode-switch
                  hide-sliders
              />
            </div>
            <div v-if="swatchesEmUso.length > 0">
              <slot>Em uso:</slot>
              <ColorPicker
                  id="ColorPickerEtiquetasEmUso"
                  :swatches="swatchesEmUso"
                  v-model="corSelecionada"
                  show-swatches
                  hide-canvas
                  hide-inputs
                  hide-mode-switch
                  hide-sliders
              />
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <Button
                id="ButtonConfirmarReclassificarEtiqueta"
                small
                class="ml-auto secondary"
                @click="validaClassificacaoEtiqueta(corCard, corSelecionada)"
            >
              Confirmar
            </Button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-layout>
</template>

<script>
  import { money, toDateBR } from '@/plugins/formatters'
  import { getSituacaoReserva, alert } from '@/plugins/helpers'
  import { getMinhasReservas, cancelarReserva, atualizarEtiquetaReserva } from '@/service/reserva'
  import { getEtiquetas } from '@/service/etiqueta'
  import { getConfiguracoes } from '@/service/configuracao'
  import { TipoExibicaoCota, Mensagens } from '@/plugins/constants'
  import session from '@/plugins/session'

  export default {
    name: 'MinhasReservas',
    data: () => ({
      dialog: false,
      isMobile: false,
      headers: [
        { text: 'Bem', value: 'produto.nome', width: 130 },
        { text: 'Crédito atual (R$)', value: 'creditoAtual', totalizar: true },
        { text: 'Prazo restante (meses)', value: 'prazoRestante' },
        { text: 'Parcela atual (R$)', value: 'parcelaAtual', totalizar: true },
        { text: 'Saldo devedor (R$)', value: 'saldoDevedor', totalizar: true },
        { text: 'Entrada (R$)', value: 'valorEntrada', totalizar: true },
        { text: 'Disponível em', value: 'dataHoraReserva' },
        { text: 'Grupo-cota', value: 'cdGrupoCota' },
        { text: 'Contrato', value: 'contrato' },
        { text: 'Venc. da próxima parcela', value: 'vencimentoProximaParcela' }
      ],
      pagination: {
        sortBy: [ 'produto.nome' ], sortDesc: [ false ]
      },
      reservas: [],
      etiquetas: [],
      swatches: [],
      swatchesEmUso: [],
      configuracoes: [],
      itensSelecionados: [],
      corSelecionada: null,
      corCard: '',
      money: money,
      toDateBR: toDateBR
    }),
    computed: {
      params () {
        return { pagination: this.pagination }
      },
      descricaoCancelarReserva () {
        if (this.$refs.datatable.selected.length > 0) {
          return `Cancelar reservas (${this.$refs.datatable.selected.length})`
        }

        return 'Cancelar reservas'
      }
    },
    async mounted () {
      await this.getConfiguracoes()
      await this.getEtiquetas()
      await this.getMinhasReservas()
    },
    methods: {
      atualizarReservaZerada () {
        this.reloadPage()
      },
      getDescricaoExcluirLista (etiqueta) {
        const itensSelecionados = this.getSelecionadosPorEtiqueta(etiqueta).length
        return itensSelecionados === 0 ? 'Excluir lista' : `Excluir (${itensSelecionados})`
      },
      getDescricaoClassificarLista (etiqueta) {
        const itensSelecionados = this.getSelecionadosPorEtiqueta(etiqueta).length
        return itensSelecionados === 0 ? 'Classificar lista' : `Classificar lista (${itensSelecionados})`
      },
      getDescricaoGerarProjeto (etiqueta) {
        const itensSelecionados = this.getSelecionadosPorEtiqueta(etiqueta).length
        return itensSelecionados === 0 ? 'Gerar projeto' : `Gerar projeto (${itensSelecionados})`
      },
      async getMinhasReservas () {
        const data = await getMinhasReservas({ idUsuario: session.get('idUsuario') })
        const reservas = data.filter((reserva) => {
          if (this.isReservaDisponivel(reserva)) {
            reserva.cdGrupoCota = `${reserva.cdGrupo}-${reserva.cdCota}`
            return reserva
          }
        })

        this.reservas = reservas
      },
      async getEtiquetas () {
        const data = await getEtiquetas()
        this.etiquetas = data
      },
      async getConfiguracoes () {
        try {
          this.configuracoes = await getConfiguracoes()
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
        }
      },
      separarEtiquetas () {
        const etiquetas = this.etiquetas
        const etiquetasUtilizadas = this.reservas.map(item => item.etiqueta)

        let etiquetasDispooniveis = []; let etiquetasEmUso = []
        let etiquetaColuna = []; let etiquetaColunaEmUso = []
        for (var i = 0; i < etiquetas.length; i++) {
          const cor = etiquetas[i].cor
          const isEtiquetaUtilizada = etiquetasUtilizadas.includes(cor)

          if (!isEtiquetaUtilizada) {
            etiquetaColuna.push(cor)
            etiquetasDispooniveis.push(etiquetaColuna)
            etiquetaColuna = []
          } else {
            etiquetaColunaEmUso.push(cor)
            etiquetasEmUso.push(etiquetaColunaEmUso)
            etiquetaColunaEmUso = []
          }
        }
        this.swatches = etiquetasDispooniveis
        this.swatchesEmUso = etiquetasEmUso
      },
      preparaCancelamentoReserva (etiqueta) {
        alert({
          text: 'Ao escolher selecionar essa opção, você estará cancelando essas reservas realizadas. \nVocê confirma que deseja cancelar as reservas das cotas da lista, ou selecionadas?',
          buttons: {
            'Cancelar': () => true,
            'Confirmar': () => {
              this.cancelarReserva(etiqueta)
            }
          }
        })
      },
      async cancelarReserva (etiqueta) {
        const items = this.getItensPorEtiqueta(etiqueta)
        try {
          let idsCotas = items.map(item => item.id_cota)

          const payload = {
            idUsuario: session.get('idUsuario'),
            idCotas: idsCotas
          }
          await cancelarReserva(payload)
          this.reloadPage()
        } catch (erro) {
          this.$toast.error(erro.message)
        }
      },
      async desclassificarReserva (etiqueta) {
        this.$refs.datatable.selected = []

        const items = this.getItensPorEtiqueta(etiqueta)
        try {
          let idsCotas = items.map(item => item.id_cota)

          const payload = {
            idUsuario: session.get('idUsuario'),
            idCotas: idsCotas,
            etiqueta: null
          }
          await atualizarEtiquetaReserva(payload)
          this.alterarEtiquetaReserva(idsCotas, null)
          this.dialog = false
        } catch (erro) {
          this.$toast.error(erro.message)
        }
      },
      preparaClassificarReserva (etiqueta) {
        this.separarEtiquetas()
        if (!this.validaProdutosIguaisPorEtiqueta(etiqueta)) {
          this.$toast.error('Não é possível adicionar cotas de bens diferentes à mesma etiqueta')
          return
        }

        this.corSelecionada = null
        this.corCard = etiqueta
        this.dialog = true
      },
      validaClassificacaoEtiqueta (etiqueta, etiquetaSelecionada) {
        const etiquetaSelecionadaHex = etiquetaSelecionada.hex
        if (this.validaProdutosIguais(etiqueta, etiquetaSelecionadaHex)) {
          this.$toast.error('Não é possível adicionar cotas de bens diferentes à mesma etiqueta')
          return
        }

        const reservasEtiquetaSelecionada = this.getItensPorEtiqueta(etiquetaSelecionadaHex).length
        if (reservasEtiquetaSelecionada > 0) {
          alert({
            text: `Etiqueta em uso: Já existem ${reservasEtiquetaSelecionada} cotas classificadas nesta etiqueta, se você confirmar a ação as novas cotas selecionadas serão agrupadas com as cotas já existentes nesta etiqueta.\n Você confirma a classificação?`,
            buttons: {
              'Cancelar': () => true,
              'Confirmar': () => {
                this.classificarReserva(etiqueta, etiquetaSelecionadaHex)
              }
            }
          })
        } else {
          this.classificarReserva(etiqueta, etiquetaSelecionadaHex)
        }
      },
      async classificarReserva (etiqueta, etiquetaSelecionada) {
        const items = this.getItensPorEtiqueta(etiqueta)
        try {
          let idsCotas = items.map(item => item.id_cota)

          const payload = {
            idUsuario: session.get('idUsuario'),
            idCotas: idsCotas,
            etiqueta: etiquetaSelecionada
          }
          await atualizarEtiquetaReserva(payload)
          this.alterarEtiquetaReserva(idsCotas, etiquetaSelecionada)
          this.dialog = false
        } catch (erro) {
          this.$toast.error(erro.message)
        }
      },
      alterarEtiquetaReserva (idsCotas, etiqueta) {
        this.reservas = this.reservas.map(item => {
          if (idsCotas.includes(item.id_cota)) {
            item.etiqueta = etiqueta
          }
          return item
        })

        this.desmarcarCheckbox(idsCotas)
      },
      desmarcarCheckbox (idsCotas) {
        this.$refs.datatable.selected = this.$refs.datatable.selected.filter(item => {
          if (!idsCotas.includes(item.id_cota)) {
            return item
          }
        })
      },
      gerarProjeto (etiqueta) {
        if (!this.validaProdutosIguaisPorEtiqueta(etiqueta)) {
          this.$toast.error('Não é possível gerar projetos com cotas de bens diferentes. Verifique a lista de cotas que selecionou')
          return
        }

        const items = this.getItensPorEtiqueta(etiqueta)
        this.$router.push({ name: 'AberturaProjeto', params: { items } })
      },
      getReservaPorEtiqueta (etiqueta) {
        const reservas = this.reservas.filter(reserva => reserva.etiqueta === etiqueta)
        return reservas ?? []
      },
      getSelecionadosPorEtiqueta (etiqueta) {
        if (this.itensSelecionados[etiqueta]) {
          const reservasSelecionadas = this.itensSelecionados[etiqueta].filter(item => item.etiqueta === etiqueta, [])
          return reservasSelecionadas
        }
        return []
      },
      isReservaDisponivel (reserva) {
        const parametros = {
          dataHoraReserva: reserva.dataHoraReserva,
          tempoExpiracaoReserva: this.configuracoes.horasExpiracaoReserva,
          tempoPenalidade: this.configuracoes.horasPenalidadeReserva,
          tempoIniciarContagemRegressiva: this.configuracoes.minutosExibirContagem
        }

        const situacao = getSituacaoReserva(parametros)
        return situacao[0] !== TipoExibicaoCota.EXIBIR_DISPONIVEL
      },
      onResize () {
        this.isMobile = window.innerWidth < 960
      },
      reloadPage () {
        location.reload()
      },
      validaProdutosIguaisPorEtiqueta (etiqueta) {
        const items = this.getItensPorEtiqueta(etiqueta)

        const produtos = items.reduce((rv, x) => {
          (rv[x['produto'].nome] = rv[x['produto'].nome] || []).push(x)
          return rv
        }, {})

        return Object.keys(produtos).length === 1
      },
      validaProdutosIguais (etiqueta, etiquetaSelecionada) {
        const reserva = this.getItensPorEtiqueta(etiqueta)
        const itemsNovaEtiqueta = this.getItensPorEtiqueta(etiquetaSelecionada)

        const cota = itemsNovaEtiqueta.findIndex(item => item.produto.nome !== reserva[0]['produto'].nome)
        return cota === 0
      },
      getItensPorEtiqueta (etiqueta) {
        const reservasSelecionados = this.getSelecionadosPorEtiqueta(etiqueta)
        const todasReservas = this.getReservaPorEtiqueta(etiqueta)
        return reservasSelecionados.length > 0 ? reservasSelecionados : todasReservas
      }
    }
  }
</script>

<style scoped>
  .center {
    align-items: center;
  }
</style>
