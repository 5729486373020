import http from '@/plugins/http'
import { get } from 'lodash'
import { Mensagens } from '@/plugins/constants'

export async function getCota (idCota) {
  try {
    const { data } = await http.get(`/cota/${idCota}`)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function atualizarCota (idCota, cota) {
  try {
    const { data } = await http.put(`/cota/${idCota}`, cota)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function getStatusCota () {
  try {
    const { data } = await http.get('/status-cota')
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}

export async function atualizarEntradaAjustada (cota) {
  try {
    const { data } = await http.put('/entrada-ajustada-cota', cota)
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', Mensagens.ERRO)
    throw Error(msg)
  }
}
