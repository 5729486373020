import http from '@/plugins/http'
import { get } from 'lodash'

export async function login (usuario) {
  try {
    const { data } = await http.post('/login', usuario, { baseURL: process.env.VUE_APP_WS_ROOT })
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', 'Não foi possível autenticar')
    throw Error(msg)
  }
}

export async function getLoginVendedor (login) {
  try {
    const { data } = await http.get(`/login/vendedor/${login}`, { baseURL: process.env.VUE_APP_WS_ROOT })
    return data
  } catch (e) {
    const msg = get(e, 'response.data.msg_erro', 'Não foi possível autenticar')
    throw Error(msg)
  }
}
