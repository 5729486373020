<template>
  <v-layout>
    <v-navigation-drawer
        v-model="drawer"
        class="secondary"
        app
        :mobile-breakpoint="960"
    >
      <div class="d-flex flex-column py-4">
        <img class="logo-menu align-self-center" src="../../assets/img/logo-menu.png" alt="Logo Embracon Corretora" />
        <div class="py-10">
          <v-expansion-panels>
            <v-expansion-panel v-for="area in routes" :key="area.path">
              <v-expansion-panel-header><v-icon color="white">{{ area.menuIcon }}</v-icon> {{ area.menuTitle }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels>
                  <v-expansion-panel v-for="secao in area.children.filter(item => item.perfisPermitidos.includes(perfil))" :key="secao.path">
                    <v-expansion-panel-header><v-icon color="white">{{ secao.menuIcon }}</v-icon> {{ secao.menuTitle }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="d-flex flex-column">
                        <a class="menu-item" :class="{ 'menu-active': $router.currentRoute.path === rota.path }" v-for="rota in secao.children.filter(item => !item.hasOwnProperty('exibirMenu') || item.exibirMenu)" :key="rota.path"
                           @click="$router.replace(rota.path)">
                          {{ rota.menuTitle }}
                        </a>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </v-navigation-drawer>
    <v-main class="app-content" app>
      <v-app-bar color="white" class="toolbar white--text">
        <v-app-bar-nav-icon class="toolbar_icon" @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <Notificacoes />
        <Perfil />
      </v-app-bar>
      <router-view class="router-view"></router-view>
    </v-main>
  </v-layout>
</template>

<script>
  import Notificacoes from '@/components/Notificacoes.vue'
  import { nestedRoutes } from '@/plugins/router'
  import Perfil from '@/components/Perfil'
  import { mapState } from 'vuex'

  export default {
    components: { Perfil, Notificacoes },
    data: () => ({
      drawer: true,
      verificarInatividade: null,
      ultimaInteracao: new Date()
    }),
    computed: {
      ...mapState(['perfil']),
      tempoMaximoInatividade () {
        // Em minutos
        return 30
      },
      routes: () => nestedRoutes
    },
    watch: {
      drawer (value) {
        this.$store.commit('setDrawer', value)
      }
    },
    beforeDestroy () {
      clearInterval(this.verificarInatividade)
    },
    created () {
      this.verificarInatividade = setInterval(() => {
        const now = new Date()
        // Quanto tempo já passou desde a última interação em minutos
        const tempo_passado = Math.floor((now - this.ultimaInteracao) / 1000) / 60

        if (tempo_passado >= this.tempoMaximoInatividade) {
          this.$session.destroy()
          // noinspection JSIgnoredPromiseFromCall
          this.$router.replace('login')
          this.$toast.error('Sua sessão expirou, entre novamente')
          console.info(`Usuário deslogado após ${this.tempoMaximoInatividade} minutos de inatividade`)
        }
      }, 1000)

      document.addEventListener('click', () => {
        // eslint-disable-next-line no-console
        this.ultimaInteracao = new Date()
      })
    },
    methods: {
      menuRoute (name) {
        const route = this.$router.resolve({ name }).route
        return { text: route.meta.title, to: route.path }
      }
    }
  }
</script>

<style>

  .v-list__group__header--active .v-list__group__header__append-icon .v-icon {
    color: #FFFFFF;
  }

  .v-list__group__header .v-list__group__header__append-icon .v-icon {
    color: #FFFFFF;
  }
</style>

<style lang="scss" scoped>

  .theme--light.v-expansion-panels .v-expansion-panel {
    background-color: transparent;
  }

  .v-expansion-panel::before {
    box-shadow: none;
  }

  .theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
    height: 0;
    border-color: transparent;
  }

  //noinspection CssInvalidPseudoSelector
  .v-expansion-panel-header, .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap, .menu-item {
    padding: 8px 0 8px 6px;
    line-height: 1;
    min-height: 0;
    font-size: 16px;
    color: var(--white);
    border-radius: 0;
  }

  .v-expansion-panel-header, .menu-item {
    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }
  }
  .v-expansion-panel--active:not(:first-child), .v-expansion-panel--active + .v-expansion-panel {
    margin-top: 0;
  }

  //noinspection CssInvalidPseudoSelector
  .v-expansion-panel-header::v-deep .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate) .v-icon {
    transform: rotate(90deg);
    color: var(--white);
  }

  //noinspection CssInvalidPseudoSelector
  .v-expansion-panel--active > .v-expansion-panel-header--active::v-deep .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate) .v-icon {
    transform: rotate(0deg);
  }

  .v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
    flex: none;
    margin-right: 6px;
  }

  .menu-item.menu-active {
    background: var(--white);
    color: var(--secondary);
  }

  .logo-menu {
    width: 90px;
  }
</style>
