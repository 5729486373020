<template>
  <Loading :visible="true" />
</template>

  <script>

  export default {
    mounted () {
      this.logout()
    },
    methods: {
      async logout () {
        if (this.$route.params.logout) {
          try {
            await this.$msalInstance.handleRedirectPromise()
            await this.$msalInstance.logoutRedirect()
          } catch (erro) {
            console.error(erro)
          }
        }

        this.$session.destroy()

        this.$router.push({
          name: 'login',
          replace: true
        })
      }
    }
  }

  </script>
