<template>
  <v-app :style="cssProps">
    <router-view class="router-view"></router-view>
    <Loading :visible="loading" />
    <Alert ref="alert" />
  </v-app>
</template>

<script>
  import Vue from 'vue'
  import { mapState } from 'vuex'
  import { reduce } from 'lodash'
  import { events } from '@/plugins/events'
  import { PublicClientApplication } from '@azure/msal-browser'

  export default {
    name: 'App',
    computed: {
      ...mapState(['loading']),
      cssProps () {
        const theme = this.$vuetify.theme.currentTheme

        this.injectTextColorClasses(theme)

        return reduce(theme, (acc, v, k) => {
          acc[`--${k}`] = v
          return acc
        }, {})
      }
    },
    created () {
      Vue.prototype.$msalInstance = new PublicClientApplication(this.$store.state.msalConfig)

      events.$on('alertShow', (options) => this.alertShow(options))
      events.$on('alertDismiss', () => this.alertDismiss())

      const html = document.querySelector('html')
      html.setAttribute('lang', 'pt-BR')
      html.setAttribute('translate', 'no')
    },
    methods: {
      injectTextColorClasses (theme) {
        let styles = ''
        for (const [key, value] of Object.entries(theme)) {
          styles += `.theme--light.v-application .text--${key} { color: ${value} !important; }\n`
        }
        let tag = document.getElementById('injected-theme')
        if (tag) {
          tag.remove()
        }
        tag = document.createElement('style')
        tag.id = 'injected-theme'
        tag.setAttribute('type', 'text/css')
        tag.appendChild(document.createTextNode(styles))
        document.head.appendChild(tag)
      },
      alertShow (options) {
        this.$refs.alert.show(options)
      },
      alertDismiss () {
        this.$refs.alert.dismiss()
      }
    }
  }

  Vue.prototype.$alert = {
    show: (options) => events.$emit('alertShow', options),
    dismiss: () => events.$emit('alertDismiss')
  }
</script>
