<template>
  <v-layout>
    <v-flex class="col-esquerda flex-basis-0 d-none d-sm-block">
      <img class="img-login" src="../assets/img/img-login.png" alt="Portal Corretora">
    </v-flex>
    <v-flex class="flex-basis-0 d-flex align-center">
      <v-form
          v-if="!multifator"
          ref="form"
          class="d-flex flex-column flex col-direita"
          @submit.prevent="entrar"
      >
        <div class="d-flex flex justify-center mb-16">
          <img src="../assets/img/logo.png" alt="Portal Corretora" />
        </div>
        <div v-if="!exibirRecuperacaoSenha">
          <TextField
              v-model="login"
              placeholder="Digite o seu login"
              label="Login"
              maxlength="30"
              :rules="[$rules.required]"
              @keydown.space.prevent
              :disabled="loading"
          />
          <PasswordField
              v-model="senha"
              placeholder="Digite a sua senha"
              label="Senha"
              maxlength="30"
              :rules="[$rules.required]"
              :disabled="loading"
          />
          <v-row class="mt-16">
            <v-col cols="12" sm="6" class="d-flex justify-center align-center">
              <a
                  href="#"
                  @click="exibirFormulatorioRecuperacao()"
              >Recuperar senha
              </a>
            </v-col>
            <v-col cols="12" sm="6" class="d-flex justify-center">
              <Button
                  color="secondary"
                  type="submit"
              >
                Entrar
              </Button>
            </v-col>
          </v-row>
        </div>
        <div v-if="exibirRecuperacaoSenha">
          <TextField
              v-model="recuperacao.login"
              placeholder="Digite o seu login"
              label="Login"
              maxlength="30"
              :disabled="desabilitarLogin"
              :rules="[$rules.required]"
              @keydown.space.prevent
              validate-on-blur
          />
          <TextField
              v-if="exibirCpfCnpjRecuperacao"
              label="CPF/CNPJ"
              v-model="recuperacao.cpfCnpj"
              v-mask="['###.###.###-##','##.###.###/####-##']"
              :rules="[this.$rules.required, this.$rules.documento]"
              validate-on-blur
          />
          <TextField
              v-if="exibirEmailRecuperacao"
              label="E-mail"
              v-model="recuperacao.email"
              maxlength="150"
              :rules="[this.$rules.required, this.$rules.email]"
              :tooltip="getMensagemInformativa()"
              validate-on-blur
              v-uppercase
          />
          <v-row class="mt-16">
            <v-col cols="12" sm="6" class="d-flex justify-center align-center">
              <Button
                  color="secondary"
                  outlined
                  @click="voltarLogin"
              >
                Voltar
              </Button>
            </v-col>
            <v-col cols="12" sm="6" class="d-flex justify-center">
              <Button
                  color="secondary"
                  @click="iniciarRecuperacaoSenha"
              >
                Próximo
              </Button>
            </v-col>
          </v-row>
        </div>
      </v-form>
      <div
          class="text-center mx-auto"
          v-else
      >
        <div class="d-flex flex justify-center mb-8">
          <img src="../assets/img/logo.png" alt="Portal Corretora" />
        </div>
        <Button
            color="secondary"
            @click="loginAzure"
        >
          Logar no Portal da Corretora
        </Button>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
  import auth from '@/mixins/auth'
  import { getLoginVendedor } from '@/service/login'
  import { resetarSenha } from '@/service/usuario'
  import { TipoPerfilNewcon } from '@/plugins/constants'
  import { unmask } from '@/plugins/helpers'
  import { mapState } from 'vuex'
  import { buscarConfiguracoesPublicas } from '@/service/configuracao'

  export default {
    name: 'Login',
    mixins: [auth],
    data: () => ({
      login: '',
      senha: '',
      recuperacao: {
        login: '',
        email: '',
        cpfCnpj: '',
        perfil: ''
      },
      desabilitarLogin: false,
      exibirRecuperacaoSenha: false,
      exibirCpfCnpjRecuperacao: false,
      exibirEmailRecuperacao: false,
      perfilValidado: false,
      multifator: true
    }),
    computed: {
      ...mapState(['loading'])
    },
    mounted () {
      this.configuracaoesPublicas()

      if (this.$route.query.s && this.$route.query.l) {
        this.autenticarUrl()
      }
    },
    methods: {
      async loginAzure () {
        const session = await this.$msalInstance.handleRedirectPromise()

        if (session) {
          await this.$msalInstance.logoutRedirect({
            onRedirectNavigate: url => false
          })
        }

        this.$msalInstance.loginRedirect(this.$store.state.msalAuthRequest)
      },
      async configuracaoesPublicas () {
        try {
          const { data: configuracoes } = await buscarConfiguracoesPublicas()
          this.multifator = configuracoes.autenticacaoMultifator

          this.$store.commit('setAutenticacaoMultifator', this.multifator)
        } catch (erro) {
          this.$toast.error(erro.message)
        }
      },
      async entrar () {
        if (!this.exibirRecuperacaoSenha) {
          if (this.$refs.form.validate()) {
            try {
              await this.autenticar(this.login, this.senha)
              // noinspection JSIgnoredPromiseFromCall
              this.$router.replace('/')
            } catch (e) {
              this.$toast.error(e.message)
            }
          }
        }
      },
      async autenticarUrl () {
        try {
          await this.autenticarToken(this.$route.query.s, this.$route.query.l)
          this.$router.replace('/')
        } catch (e) {
          this.$router.replace(this.$router.currentRoute.path)
          this.$toast.error(e.message)
        }
      },
      async iniciarRecuperacaoSenha () {
        if (this.$refs.form.validate()) {
          try {
            if (this.perfilValidado) {
              await this.recuperarSenha()
              this.$toast.success('Uma senha temporária foi enviada para o seu e-mail. Caso não tenha acesso ao seu e-mail, por favor entre em contato com o administrador do sistema.')
              this.voltarFormularioLogin()
              return
            }
            await this.validaPerfilUsuario()
          } catch (erro) {
            this.$toast.error(erro)
          }
        }
      },
      async recuperarSenha () {
        try {
          const payload = {
            cpf_cnpj: unmask(this.recuperacao.cpfCnpj),
            email: this.podeExibirEmailRecuperacaoRecuperacao() ? this.recuperacao.email : null
          }
          await resetarSenha(this.recuperacao.login, payload)
        } catch (erro) {
          this.voltarFormularioLogin()
          throw erro.message
        }
      },
      async validaPerfilUsuario () {
        try {
          const usuario = await getLoginVendedor(this.recuperacao.login)
          this.recuperacao.perfil = usuario.perfil
          this.perfilValidado = true
          this.exibirCpfCnpjRecuperacao = true
          this.desabilitarLogin = this.podeDesabilitarLoginRecuperacao()
          this.exibirEmailRecuperacao = this.podeExibirEmailRecuperacaoRecuperacao()
        } catch (erro) {
          this.perfilValidado = false
          const msg = 'Usuário não identificado. Entre em contato com o administrador do sistema'
          throw msg
        }
      },
      exibirFormulatorioRecuperacao () {
        this.exibirRecuperacaoSenha = true
        this.recuperacao.login = this.login
      },
      voltarFormularioLogin () {
        this.exibirRecuperacaoSenha = false
        this.recuperacao = {}
        this.exibirCpfCnpjRecuperacao = false
        this.exibirEmailRecuperacao = false
        this.perfilValidado = false
        this.desabilitarLogin = false
      },
      podeDesabilitarLoginRecuperacao () {
        return this.recuperacao.login !== ''
      },
      podeExibirEmailRecuperacaoRecuperacao () {
        return [ TipoPerfilNewcon.PARCEIRO, TipoPerfilNewcon.SUBLOGIN ].includes(this.recuperacao.perfil.toLowerCase())
      },
      getMensagemInformativa () {
        return this.recuperacao.perfil.toLowerCase() === TipoPerfilNewcon.PARCEIRO
          ? 'E-mail alternativo da conta.'
          : 'E-mail principal da conta.'
      },
      voltarLogin () {
        this.voltarFormularioLogin()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .col-esquerda {
    background: var(--primary);
  }
  .col-direita {
    padding: 0 15%;
  }
  .img-login {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    padding: 0 12%;
  }
</style>
