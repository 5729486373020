<template>
  <v-container fluid>
    <h1 class="titulo">Cotas</h1>
    <div>
      <v-row justify="space-between">
        <v-col cols="12" sm="4" lg="4" md="4" xl="4">
          <FilterTextField
              id="FilterTextFieldBusca"
              v-model="buscar"
              maxlength="30"
              placeholder="Digite para buscar"
              v-uppercase
          />
        </v-col>
        <v-col cols="12" sm="6" lg="6" md="6" xl="6">
          <div class="float-xs-left float-sm-left float-lg-right float-md-right">
            <label>Disponível para venda:</label>
            <v-row class="pt-1">
              <Checkbox
                  id="CheckboxDisponivelVenda"
                  color="secondary"
                  v-model="disponivelVenda.sim"
                  label="Sim"
                  class="ml-2 mt-0"
              />
              <Checkbox
                  id="CheckboxNaoDisponivelVenda"
                  color="secondary"
                  v-model="disponivelVenda.nao"
                  label="Não"
                  class="ml-5 mt-0"
              />
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <DataTable
        url="cota"
        class="mt-5 data-table-overlow"
        :filter="filters"
        :headers="headers"
        :params="params"
    >
      <template v-slot:[`item.cdGrupo-cdCota`]="item">
        <td>{{ item.cdGrupo }}-{{ item.cdCota }}</td>
      </template>
      <template v-slot:[`item.status`]="item">
        <td>
          <Chip
              v-if="listaStatusCota"
              id="ChipStatus"
              :color="getStatusCotaCorreto(item.status).cor"
              :texto="getStatusCotaCorreto(item.status).descricao"
          />
        </td>
      </template>
      <template v-slot:[`item.creditoAtual`]="item">
        <td>{{ money(item.creditoAtual) }}</td>
      </template>
      <template v-slot:[`item.parcelaAtual`]="item">
        <td>{{ money(item.parcelaAtual) }}</td>
      </template>
      <template v-slot:[`item.prazoRestante`]="item">
        <td>{{ item.prazoRestante }}</td>
      </template>
      <template v-slot:[`item.nomeBem`]="item">
        <td class="capitalize">{{ item.nomeBem }}</td>
      </template>
      <template v-slot:[`item.disponivelVenda`]="item">
        <td class="text-center">
          <v-flex class="mt-3 mt-sm-1 status status-color">
            <span :class="getDisponibilidade(item.disponivelVenda)" />
          </v-flex>
        </td>
      </template>
      <template v-slot:[`item.acao`]="item">
        <td class="text-center">
          <v-icon
              :id="`IconEditar_${item.idCota}`"
              color="labelcolor"
              @click="editar(item)"
          >
            pc-editar
          </v-icon>
        </td>
      </template>
    </DataTable>
  </v-container>
</template>

<script>
  import { money } from '@/plugins/formatters'
  import { getFilters } from '@/plugins/helpers'
  import { getStatusCota } from '@/service/cota'

  export default {
    name: 'GestaoCotasContempladas',
    data: () => ({
      headers: [
        { text: 'Grupo-cota', value: 'cdGrupo-cdCota' },
        { text: 'Status', value: 'status' },
        { text: 'Crédito atual (R$)', value: 'creditoAtual' },
        { text: 'Parcela atual (R$)', value: 'parcelaAtual' },
        { text: 'Prazo restante (meses)', value: 'prazoRestante' },
        { text: 'Bem', value: 'nomeBem' },
        { text: 'Disponível para venda', value: 'disponivelVenda', align: 'center' },
        { text: 'Ação', value: 'acao', sortable: false, align: 'center', width: 100 }
      ],
      pagination: {
        sortBy: [ 'cdGrupo', 'cdCota' ], sortDesc: [ true, true ]
      },
      filters: {},
      disponivelVenda: {
        sim: true,
        nao: true
      },
      buscar: '',
      money: money,
      listaStatusCota: undefined
    }),
    computed: {
      params () {
        return { pagination: this.pagination }
      }
    },
    watch: {
      buscar (value) {
        if (value.length > 2 || !value) {
          this.recarregarDataTable()
        }
      },
      disponivelVenda: {
        handler () {
          this.recarregarDataTable()
        },
        deep: true
      }
    },
    mounted () {
      this.getListaStatusCota()
    },
    methods: {
      editar (cota) {
        this.$router.push({ name: 'DetalheCota', params: cota })
      },
      getDisponibilidade (status) {
        return status ? 'disponivel' : 'indisponivel'
      },
      recarregarDataTable () {
        let filtro = null
        filtro = getFilters(this.buscar)

        const disponivel = this.disponivelVenda
        if (disponivel.sim && !disponivel.nao) {
          filtro = { ...filtro, disponivel_venda: true }
        } else if (!disponivel.sim && disponivel.nao) {
          filtro = { ...filtro, disponivel_venda: false }
        } else {
          filtro = { ...filtro }
        }
        this.filters = filtro
      },
      async getListaStatusCota () {
        try {
          this.listaStatusCota = await getStatusCota()
        } catch (erro) {
          this.$toast.error(erro.response.data.msg_erro)
        }
      },
      getStatusCotaCorreto (status) {
        for (let i = 0; i < this.listaStatusCota.length; i++) {
          if (status === this.listaStatusCota[i].status) {
            return this.listaStatusCota[i]
          }
        }

        return {
          cor: '',
          descricao: status
        }
      }
    }
  }
</script>

<style scoped>
  .capitalize {
    text-transform: capitalize;
  }

  span.disponivel:before {
      background-color: #008836;
  }

  span.indisponivel:before {
      background-color: #CE171E;
  }

  .status-color span:before {
      border-radius: 50%;
      content: '';
      position: absolute;
      width: 11px;
      height: 11px;
      left: 0;
      top: 50%;
      margin-top: -6px;
  }

  .status span {
      position: relative;
  }
</style>
