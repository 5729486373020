<script>
  import { VCheckbox } from 'vuetify/es5/components/VCheckbox'
  export default {
    name: 'Checkbox',
    extends: VCheckbox
  }
</script>

<style lang="scss">
  .v-label {
    color: var(--labelcolor) !important;
    font-weight: bold;
    font-size: 14px;
  }
</style>
