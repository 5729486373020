<template>
  <span>
    <Button
        :disabled="disabled"
        color="secondary"
        style="height: 36px !important"
        @click="abrirDialog"
    >
      Confirmar
    </Button>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="100px"
    >
      <v-card>
        <v-card-title>
          <span>Alterar Vendedor</span>
          <v-btn
              icon
              class="ml-auto"
              @click="dialog = false"
          >
            <v-icon dark size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions class="mt-5 text--black">
          <div>
            <p>Você confirma a alteração da reserva do vendedor "{{ nomeVendedorAtual }}" para o vendedor "{{ nomeNovoVendedor }}"? Esta ação será imediata e não poderá ser desfeita.</p>
          </div>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <Button
              color="secondary"
              outlined
              style="height: 36px !important"
              @click="fecharDialog"
          >
            Cancelar
          </Button>
          <Button
              color="secondary"
              style="height: 36px !important"
              @click="confirmar"
          >
            Confirmar
          </Button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
  export default {
    props: ['nomeVendedorAtual', 'nomeNovoVendedor', 'disabled'],
    data: () => ({
      dialog: false
    }),
    methods: {
      confirmar () {
        this.$emit('confirmar')
        this.dialog = false
      },
      abrirDialog () {
        this.dialog = true
        this.$emit('aberto', false)
      },
      fecharDialog () {
        this.dialog = false
        this.$emit('aberto', true)
      }
    }
  }
</script>
