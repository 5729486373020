<template>
  <div class="position-relative">
    <TextField
        :type="showPassword ? 'text' : 'password'"
        v-bind="$attrs"
        v-on="$listeners" />
    <a href="#" class="d-block btn-toggle" @click="showPassword = !showPassword">
      <v-icon color="primary">{{ showPassword ? 'pc-senha-off' : 'pc-senha-on' }}</v-icon>
    </a>
  </div>
</template>

<script>
  export default {
    name: 'PasswordField',
    data: () => ({
      showPassword: false
    })
  }
</script>

<style scoped>
  .btn-toggle {
    position: absolute;
    bottom: 22px; /* 22px é a distância do bottom da div externa até o bottom do input */
    right: 10px;
    margin-bottom: 9px;
    text-decoration: none;
  }
</style>
