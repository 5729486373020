<template>
  <v-container fluid>
    <h1 class="titulo">Cotas</h1>
    <div>
      <v-row
          align="center"
          justify="space-between"
      >
        <v-col cols="12" sm="12" lg="4" md="4" xl="4">
          <v-icon color="iconcolor">pc-atencao</v-icon>
          <label class="texto-informativo ml-2">Utilize o botão
            <v-icon
                small
                color="secondary">
              pc-adicionar
            </v-icon>
            para adicionar a cota à lista de cotas selecionadas e simular o total de uma operação.</label>
        </v-col>
        <v-col cols="12" sm="12" lg="4" md="4" xl="4">
          <SelectField
              id="SelectFieldProduto"
              label="Produto"
              :items="produtos"
              item-text="nome"
              item-value="id"
              v-model="filtro.idProduto"
          />
        </v-col>
        <v-col cols="12" sm="12" lg="3" md="3" xl="3">
          <v-row>
            <v-col cols="12" sm="6" lg="6" md="6" xl="6">
              <Checkbox
                  id="CheckboxComReserva"
                  color="secondary"
                  v-model="filtro.comReserva"
                  label="Com reserva"
              />
            </v-col>
            <v-col cols="12" sm="6" lg="6" md="6" xl="6">
              <Checkbox
                  id="CheckboxSemReserva"
                  color="secondary"
                  v-model="filtro.semReserva"
                  label="Sem reserva"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <template>
      <DataTable
          url="cotasparareserva"
          class="mt-5 data-table-overlow"
          :filter="filters"
          :headers="headers"
          :params="params"
          :esconder-linha="escondeOuExibeLinha"
          item-key="id_cota"
      >
        <template v-slot:[`item.icone`]="item">
          <td>
            <v-icon
                :id="`Adicionar_${item.id_cota}`"
                color="secondary"
                @click="adicionar(item)"
            >
              pc-adicionar
            </v-icon>
          </td>
        </template>
        <template v-slot:[`item.produto.nome`]="item">
          <td>{{ item.produto.nome }}</td>
        </template>
        <template v-slot:[`item.creditoAtual`]="item">
          <td>{{ money(item.creditoAtual) }}</td>
        </template>
        <template v-slot:[`item.prazoRestante`]="item">
          <td>{{ item.prazoRestante }}</td>
        </template>
        <template v-slot:[`item.parcelaAtual`]="item">
          <td>{{ money(item.parcelaAtual) }}</td>
        </template>
        <template v-slot:[`item.saldoDevedor`]="item">
          <td>{{ money(item.saldoDevedor) }}</td>
        </template>
        <template v-slot:[`item.valorEntrada`]="item">
          <td>{{ money(item.valorEntrada) }}</td>
        </template>
        <template v-slot:[`item.dataHoraReserva`]="item">
          <td>
            <ContadorRegressivo
                :data-hora-reserva="getFinalPenalidade(item.dataHoraExpiracao)"
                :configuracoes="configuracoes"
                @podeReservar="reservarCotaIndividual(item)"
            />
          </td>
        </template>
        <template v-slot:[`item.cdGrupo-cdCota`]="item">
          <td>{{ item.cdGrupo }}-{{ item.cdCota }}</td>
        </template>
        <template v-slot:[`item.contrato`]="item">
          <td>{{ item.contrato }}</td>
        </template>
        <template v-slot:[`item.vencimentoProximaParcela`]="item">
          <td>{{ toDateBR(item.vencimentoProximaParcela) }}</td>
        </template>
      </DataTable>
    </template>
    <h1 class="titulo">Cotas selecionadas</h1>
    <DataTable
        class="mt-5 data-table-overlow"
        :filter="filters"
        :headers="headersSelecionada"
        :itens-externos="cotasSelecionadas"
        :show-pagination=false
        item-key="id_cota"
    >
      <template v-slot:[`item.icone`]="item">
        <td>
          <v-icon
              :id="`Remover_${item.id_cota}`"
              color="secondary"
              @click="remover(item)"
          >
            pc-remover
          </v-icon>
        </td>
      </template>
      <template v-slot:[`item.produto.nome`]="item">
        <td>{{ item.produto.nome }}</td>
      </template>
      <template v-slot:[`item.creditoAtual`]="item">
        <td>{{ money(item.creditoAtual) }}</td>
      </template>
      <template v-slot:[`item.prazoRestante`]="item">
        <td>{{ item.prazoRestante }}</td>
      </template>
      <template v-slot:[`item.parcelaAtual`]="item">
        <td>{{ money(item.parcelaAtual) }}</td>
      </template>
      <template v-slot:[`item.saldoDevedor`]="item">
        <td>{{ money(item.saldoDevedor) }}</td>
      </template>
      <template v-slot:[`item.valorEntrada`]="item">
        <td>{{ money(item.valorEntrada) }}</td>
      </template>
      <template v-slot:[`item.cdGrupo-cdCota`]="item">
        <td>{{ item.cdGrupo }}-{{ item.cdCota }}</td>
      </template>
      <template v-slot:[`item.contrato`]="item">
        <td>{{ item.contrato }}</td>
      </template>
      <template v-slot:[`item.vencimentoProximaParcela`]="item">
        <td>{{ toDateBR(item.vencimentoProximaParcela) }}</td>
      </template>
      <template v-if="isCotaSelecionada" v-slot:totalizador />
    </DataTable>
    <v-card
        v-if="isCotaSelecionada"
        color="cardcolor"
        class="mt-10"
        flat
    >
      <v-card-text class="cardcolor rounded py-10">
        <v-row justify="end">
          <v-col cols="12" md="4" lg="3" xl="2">
            <Button
                id="ButtonLimparCotasSelecionadas"
                block
                color="secondary"
                outlined
                @click="limparCotasSelecionadas"
            >
              Limpar seleção
            </Button>
          </v-col>
          <v-col cols="12" md="4" lg="3" xl="2">
            <Button
                id="ButtonReservarCotasSelecionadas"
                block
                class="secondary"
                @click="reservarCotasSelecionadas"
            >
              Reservar selecionadas
            </Button>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { money, toDateBR } from '@/plugins/formatters'
  import { alert, getSituacaoReserva } from '@/plugins/helpers'
  import { Mensagens, TipoExibicaoCota } from '@/plugins/constants'
  import { getConfiguracoes } from '@/service/configuracao'
  import { getProdutos } from '@/service/produto'
  import { reservarCota } from '@/service/reserva'
  import session from '@/plugins/session'
  import moment from 'moment'

  const QTD_MAX_COTA_SELECIONADA = 15

  export default {
    name: 'CotasDisponiveisReserva',
    data: () => ({
      headers: [
        { value: 'icone', sortable: false, align: 'center', width: 44 },
        { text: 'Bem', value: 'produto.nome' },
        { text: 'Crédito atual (R$)', value: 'creditoAtual', totalizar: true },
        { text: 'Prazo restante (meses)', value: 'prazoRestante' },
        { text: 'Parcela atual (R$)', value: 'parcelaAtual', totalizar: true },
        { text: 'Saldo devedor (R$)', value: 'saldoDevedor', totalizar: true },
        { text: 'Entrada (R$)', value: 'valorEntrada', totalizar: true },
        { text: 'Disponível em', value: 'dataHoraReserva' },
        { text: 'Grupo-cota', value: 'cdGrupo-cdCota' },
        { text: 'Contrato', value: 'contrato' },
        { text: 'Venc. da próxima parcela', value: 'vencimentoProximaParcela' }
      ],
      headersSelecionada: [
        { value: 'icone', sortable: false, align: 'center', width: 44 },
        { text: 'Bem', value: 'produto.nome' },
        { text: 'Crédito atual (R$)', value: 'creditoAtual', totalizar: true },
        { text: 'Prazo restante (meses)', value: 'prazoRestante' },
        { text: 'Parcela atual (R$)', value: 'parcelaAtual', totalizar: true },
        { text: 'Saldo devedor (R$)', value: 'saldoDevedor', totalizar: true },
        { text: 'Entrada (R$)', value: 'valorEntrada', totalizar: true },
        { text: 'Grupo-cota', value: 'cdGrupo-cdCota' },
        { text: 'Contrato', value: 'contrato' },
        { text: 'Venc. da próxima parcela', value: 'vencimentoProximaParcela' }
      ],
      pagination: {
        sortBy: [ 'cdGrupo', 'cdCota' ], sortDesc: [ true, true ]
      },
      cotasSelecionadas: [],
      isCotaSelecionada: false,
      filters: {},
      filtro: {
        idProduto: null,
        comReserva: false,
        semReserva: true,
        idUsuario: session.get('idUsuario')
      },
      configuracoes: {
        minutosExibirContagem: 0,
        horasPenalidadeReserva: 0
      },
      produtos: [],
      money: money,
      toDateBR: toDateBR
    }),
    computed: {
      params () {
        return { pagination: this.pagination }
      }
    },
    watch: {
      filtro: {
        handler (filter) {
          this.filters = { ...filter }
        },
        deep: true
      },
      cotasSelecionadas (cotas) {
        this.isCotaSelecionada = cotas.length > 0
      }
    },
    mounted () {
      this.filters = { ...this.filtro }
      this.getProdutos()
      this.getConfiguracoes()
    },
    methods: {
      carregarCotas () {
        this.filters = { ...this.filtro }
      },
      async getConfiguracoes () {
        try {
          this.configuracoes = await getConfiguracoes()
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
        }
      },
      async getProdutos () {
        try {
          this.produtos = await getProdutos()
          const todosProdutos = { id: null, nome: 'Todos' }
          this.produtos.unshift(todosProdutos)
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
        }
      },
      adicionar (cota) {
        if (this.validaProduto(cota.produto.nome)) {
          this.$toast.error('Só é possível selecionar cotas do mesmo bem. Verifique a lista de cotas selecionadas.')
          return
        } else if (this.cotasSelecionadas.length === QTD_MAX_COTA_SELECIONADA) {
          this.$toast.error('Não é possível adicionar mais que 15 cotas na listagem de selecionadas')
          return
        }

        this.cotasSelecionadas = [ ...this.cotasSelecionadas, cota ]
      },
      validaProduto (produto) {
        const cota = this.cotasSelecionadas.findIndex(item => item.produto.nome !== produto)
        return cota === 0
      },
      remover (cota) {
        const cotasSelecionadas = this.cotasSelecionadas.filter(item => JSON.stringify(item) !== JSON.stringify(cota))
        this.cotasSelecionadas = cotasSelecionadas
      },
      limparCotasSelecionadas () {
        this.cotasSelecionadas = []
      },
      reservarCotaIndividual (cota) {
        this.reservar([cota])
      },
      reservarCotasSelecionadas () {
        const [ cotasDisponiveis, possuiCotaNaoDisponivel ] = this.getCotasDisponiveis()

        if (cotasDisponiveis.length === 0) {
          alert({
            text: 'Nenhuma cota selecionada está disponível para reserva no momento. Verifique a lista de cotas selecionadas',
            buttons: {
              'Confirmar': () => true
            }
          })
        } else if (possuiCotaNaoDisponivel) {
          alert({
            text: 'Existem cotas selecionadas que ainda não estão disponíveis. Deseja reservar apenas as cotas que estão disponíveis?',
            buttons: {
              'Cancelar': () => true,
              'Confirmar': () => {
                this.reservar(cotasDisponiveis)
                this.limparCotasSelecionadas()
              }
            }
          })
        } else {
          this.reservar(cotasDisponiveis)
          this.limparCotasSelecionadas()
        }
      },
      async reservar (cotas) {
        try {
          let idsCotas = cotas.map(item => item.id_cota)

          const payload = {
            idUsuario: session.get('idUsuario'),
            idCotas: idsCotas
          }
          await reservarCota(payload)
          const msgSucesso = idsCotas.length > 1 ? 'Feito! Reservas registradas.' : 'Feito! Reserva registrada.'
          this.$toast.success(msgSucesso)
          this.carregarCotas()
        } catch (erro) {
          this.$toast.error(erro.message)
        }
      },
      getCotasDisponiveis () {
        let cotasDisponiveisParaReserva = []
        let possuiCotaNaoDisponivel = false

        this.cotasSelecionadas.forEach((cota, chave) => {
          if (this.isCotaDisponivel(cota)) {
            cotasDisponiveisParaReserva = [ ...cotasDisponiveisParaReserva, cota ]
          } else {
            possuiCotaNaoDisponivel = true
          }
        })

        return [ cotasDisponiveisParaReserva, possuiCotaNaoDisponivel ]
      },
      isCotaDisponivel (cota) {
        const parametros = {
          dataHoraReserva: cota.dataHoraExpiracao,
          tempoPenalidade: 0,
          tempoIniciarContagemRegressiva: this.configuracoes.minutosExibirContagem,
          cotaCancelada: cota.cancelada
        }

        const situacao = getSituacaoReserva(parametros)
        return situacao[0] === TipoExibicaoCota.EXIBIR_DISPONIVEL
      },
      escondeOuExibeLinha (item) {
        if (this.cotasSelecionadas.some(e => e.id_cota === item.id_cota)) {
          return false
        }
        return true
      },
      getFinalPenalidade (dataHoraExpiracao) {
        return moment(dataHoraExpiracao, 'YYYY-MM-DD HH:mm:ss.uuu')
          .add(this.configuracoes.horasPenalidadeReserva, 'hours')
      }
    }
  }
</script>

<style scoped>
  .texto-informativo {
    font-size: 14px;
    color: var(--black);
  }
</style>
