<template>
  <v-layout>
    <v-container fluid>
      <h1 class="titulo">Abertura de projeto</h1>
      <h1 class="titulo">Dados do cliente</h1>
      <v-form ref="form">
        <v-row>
          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <TextField
                id="TextFieldNomeRazaoSocial"
                label="Nome/Razão social"
                v-model="projeto.nomeRazaoSocial"
                :rules="[this.$rules.required]"
                maxlength="50"
                v-uppercase
            />
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <TextField
                id="TextFieldCpfCnpj"
                label="CPF/CNPJ"
                v-model="projeto.cpfCnpj"
                v-mask="['###.###.###-##','##.###.###/####-##']"
                :rules="[this.$rules.required, this.$rules.documento]"
                @blur="verificaTipoDocumento"
                validate-on-blur
            />
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <TextField
                id="TextFieldTelefone"
                label="Telefone"
                v-model="projeto.telefone"
                v-mask="['(##) ####-####', '(##) #####-####']"
                :rules="[this.$rules.required, this.$rules.celular_telefone]"
                validate-on-blur
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <TextField
                id="TextFieldEmail"
                label="E-mail"
                v-model="projeto.email"
                maxlength="50"
                :rules="[this.$rules.required, this.$rules.email]"
                validate-on-blur
                v-uppercase
            />
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <TextField
                id="TextFieldPessoaContato"
                v-if="isCnpj"
                label="Pessoa de contato"
                v-model="projeto.pessoaContato"
                maxlength="50"
                :rules="[this.$rules.required]"
                v-uppercase
            />
          </v-col>
        </v-row>
      </v-form>
      <h1 class="titulo">Cotas negociadas</h1>
      <DataTable
          class="mt-5 data-table-overlow"
          :headers="headers"
          :itens-externos="cotasNegociadas"
          :show-pagination=false
          item-key="id_cota"
      >
        <template v-slot:[`item.produto.nome`]="item">
          <td>{{ item.produto.nome }}</td>
        </template>
        <template v-slot:[`item.creditoAtual`]="item">
          <td>{{ money(item.creditoAtual) }}</td>
        </template>
        <template v-slot:[`item.parcelaAtual`]="item">
          <td>{{ money(item.parcelaAtual) }}</td>
        </template>
        <template v-slot:[`item.valorEntrada`]="item">
          <td>{{ money(item.valorEntrada) }}</td>
        </template>
        <template v-slot:[`item.comissao`]="item">
          <td>{{ money(item.comissao) }}</td>
        </template>
        <template v-slot:[`item.saldoDevedor`]="item">
          <td>{{ money(item.saldoDevedor) }}</td>
        </template>
        <template v-slot:[`item.cdGrupoCota`]="item">
          <td>{{ item.cdGrupo }}-{{ item.cdCota }}</td>
        </template>
        <template v-slot:[`item.contrato`]="item">
          <td>{{ item.contrato }}</td>
        </template>
        <template v-slot:totalizador />
      </DataTable>
      <div v-if="exibeAnexoCpf || exibeAnexoCnpj">
        <h1 class="titulo">Anexos</h1>
        <v-row v-show="exibeAnexoCpf">
          <template v-for="(anexo, index) of getAnexosPessoaFisica()">
            <v-col cols="12" sm="6" lg="3" xl="2" v-bind:key="index">
              <FileInput
                  :id="`FileInputFisica_${anexo.idTipoAnexo}`"
                  :label="anexo.nome"
                  :pode-visualizar=false
                  :pode-realizar-download=false
                  @anexo="anexosPessoaFisica[anexo.idTipoAnexo] = $event"
              />
            </v-col>
          </template>
        </v-row>
        <v-row v-show="exibeAnexoCnpj">
          <template v-for="(anexo, index) of getAnexosPessoaJuridica()">
            <v-col cols="12" sm="6" lg="3" xl="2" v-bind:key="index">
              <FileInput
                  :id="`FileInputJuridica_${anexo.idTipoAnexo}`"
                  :label="anexo.nome"
                  :pode-visualizar=false
                  :pode-realizar-download=false
                  @anexo="anexosPessoaJuridica[anexo.idTipoAnexo] = $event"
              />
            </v-col>
          </template>
        </v-row>
        <label class="texto-informativo-anexo">* São aceitos arquivos nos formatos PDF e imagens JPG, JPEG e PNG de até 10MB.</label>
      </div>
      <v-card
          color="cardcolor"
          class="mt-10"
          flat>
        <v-card-text class="cardcolor rounded py-10">
          <v-row justify="end">
            <v-col cols="12" md="4" lg="3" xl="2">
              <Button
                  id="ButtonCancelar"
                  block
                  color="secondary"
                  outlined
                  @click="irParaMinhasReservas"
              >
                Cancelar
              </Button>
            </v-col>
            <v-col cols="12" md="4" lg="3" xl="2">
              <Button
                  id="ButtonSalvar"
                  block
                  class="secondary"
                  @click="salvarProjeto"
              >
                Salvar
              </Button>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <v-dialog
        content-class="app-content"
        v-model="dialog"
        persistent
    >
      <v-card>
        <v-card-title>
          <slot name="title">Feito! Projeto registrado com sucesso. O que deseja fazer?</slot>
        </v-card-title>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-col class="text-align-center" cols="12" sm="12" md="6" lg="6" xl="6">
            <Button
                id="ButtonRedirecionarCotasContempladas"
                small
                color="secondary"
                @click="redirecionarCotasContempladas"
            >
              Lista de cotas
            </Button>
          </v-col>
          <v-col class="text-align-center" cols="12" sm="12" md="6" lg="6" xl="6">
            <Button
                id="ButtonRedirecionarDetalhesProjeto"
                small
                color="secondary"
                @click="redirecionarDetalheProjeto"
            >
              Ver projeto
            </Button>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
  import { alert, unmask } from '@/plugins/helpers'
  import { money } from '@/plugins/formatters'
  import { getConfiguracoes } from '@/service/configuracao'
  import { salvarAnexo, getTipoAnexo } from '@/service/anexo'
  import { abrirProjeto, atualizarStatusProjeto } from '@/service/projeto'
  import { Mensagens, TipoNaturezaAnexo, StatusProjeto, TipoPerfil } from '@/plugins/constants'
  import session from '@/plugins/session'

  const CNPJ = 18

  export default {
    name: 'AberturaProjeto',
    data: () => ({
      dialog: false,
      isCnpj: false,
      exibeAnexoCpf: false,
      exibeAnexoCnpj: false,
      anexosPessoaFisica: [],
      anexosPessoaJuridica: [],
      projeto: {
        id: '',
        nomeRazaoSocial: '',
        cpfCnpj: '',
        telefone: '',
        email: '',
        pessoaContato: ''
      },
      headers: [
        { text: 'Bem', value: 'produto.nome', width: 130 },
        { text: 'Crédito atual (R$)', value: 'creditoAtual', totalizar: true },
        { text: 'Parcela atual (R$)', value: 'parcelaAtual', totalizar: true },
        { text: 'Entrada (R$)', value: 'valorEntrada', totalizar: true },
        { text: 'Comissão (R$)', value: 'comissao', totalizar: true },
        { text: 'Saldo devedor (R$)', value: 'saldoDevedor', totalizar: true },
        { text: 'Grupo-cota', value: 'cdGrupoCota' },
        { text: 'Contrato', value: 'contrato' }
      ],
      cotasNegociadas: [],
      configuracoes: [],
      tiposAnexos: [],
      money: money
    }),
    computed: {
      cpfCnpj () {
        return this.projeto.cpfCnpj
      }
    },
    watch: {
      cpfCnpj (value) {
        if (value) {
          this.isCnpj = value.length === CNPJ
        }
      }
    },
    async mounted () {
      await this.getConfiguracoes()
      await this.getTiposAnexos()
      this.carregarCotasNegociadas(this.$route.params)
    },
    methods: {
      irParaMinhasReservas () {
        this.$router.push({ name: 'MinhasReservas' })
      },
      async salvarProjeto () {
        if (this.$refs.form.validate()) {
          try {
            const cotas = this.cotasNegociadas.map(item => {
              const saldoBruto = item.valorEntrada - item.valorCompra
              const valorTaxaTransferencia = (this.configuracoes.percentualTaxaTransferencia * item.creditoAtual) / 100

              return {
                idCota: item.id_cota,
                creditoAtual: item.creditoAtual,
                valorComissao: item.comissao,
                valorResultado: saldoBruto - item.comissao - valorTaxaTransferencia
              }
            })

            const payload = {
              cpfCnpj: unmask(this.projeto.cpfCnpj),
              nomeRazaoSocial: this.projeto.nomeRazaoSocial,
              telefone: unmask(this.projeto.telefone),
              email: this.projeto.email,
              pessoaContato: this.isCnpj ? this.projeto.pessoaContato : '',
              idUsuario: session.get('idUsuario'),
              cotas: cotas
            }
            const projeto = await abrirProjeto(payload)
            this.projeto.id = projeto.id
            await this.salvarAnexo(projeto)
            this.dialog = true
          } catch (erro) {
            this.$toast.error(erro.message)
          }
        } else {
          this.$toast.error('Existem campos obrigatórios não preenchidos. Verifique os dados do cliente e tente novamente')
        }
      },
      async salvarAnexo (projeto) {
        try {
          const anexos = this.isCnpj ? this.anexosPessoaJuridica : this.anexosPessoaFisica
          const quantidadeAnexos = this.isCnpj ? this.getAnexosPessoaJuridica().length - 1 : this.getAnexosPessoaFisica().length - 1
          let anexosEnviados = 0
          anexos.forEach(async (file, key) => {
            if (file) {
              const formData = new FormData()
              formData.append('arquivo', file)
              formData.append('idProjeto', projeto.id)
              formData.append('nomeDocumento', file.name)
              formData.append('idTipoAnexo', key)

              await salvarAnexo(formData)
              if (quantidadeAnexos === ++anexosEnviados) {
                const formData = new FormData()
                formData.append('idProjeto', projeto.id)
                formData.append('status', StatusProjeto.EM_PROCESSO)
                await atualizarStatusProjeto(formData)
              }
            }
          })
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO_ENVIO_ANEXO)
          this.$router.push({ name: 'MeusProjetos' })
        }
      },
      carregarCotasNegociadas (params) {
        if (params.items) {
          const items = params.items.map(item => {
            item.comissao = this.calcularValorComissaoPerfil(item.creditoAtual)
            return item
          })
          this.cotasNegociadas = items
        } else {
          this.irParaMinhasReservas()
        }
      },
      calcularValorComissaoPerfil (valor) {
        const perfil = session.get('perfil')

        switch (perfil) {
          case TipoPerfil.PARCEIRO:
            return (this.configuracoes.percentualComissaoParceiro * valor) / 100
          case TipoPerfil.CLT:
            return (this.configuracoes.percentualComissaoVendedor * valor) / 100
          case TipoPerfil.COLABORADOR:
            return (this.configuracoes.percentualComissaoVendedor * valor / 100) +
              (this.configuracoes.percentualComissaoGerente * valor / 100) +
              (this.configuracoes.percentualComissaoSupervisor * valor / 100)
          case TipoPerfil.SUBLOGIN:
            return (this.configuracoes.percentualComissaoParceiro * valor) / 100
          case TipoPerfil.FRANQUEADO:
            return (this.configuracoes.percentualComissaoParceiro * valor) / 100
          case TipoPerfil.VENDEDOR:
            return (this.configuracoes.percentualComissaoVendedor * valor / 100) +
              (this.configuracoes.percentualComissaoGerente * valor / 100) +
              (this.configuracoes.percentualComissaoSupervisor * valor / 100)
          default:
            return 0
        }
      },
      async getConfiguracoes () {
        try {
          this.configuracoes = await getConfiguracoes()
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
        }
      },
      async getTiposAnexos () {
        try {
          this.tiposAnexos = await getTipoAnexo()
        } catch (erro) {
          this.$toast.error(Mensagens.ERRO)
        }
      },
      getAnexosPessoaFisica () {
        return this.tiposAnexos.filter(item => [TipoNaturezaAnexo.AMBOS, TipoNaturezaAnexo.FISICA].includes(item.tipoPessoa) && !item.anexoAdminsitrativo)
      },
      getAnexosPessoaJuridica () {
        return this.tiposAnexos.filter(item => [TipoNaturezaAnexo.AMBOS, TipoNaturezaAnexo.JURIDICA].includes(item.tipoPessoa) && !item.anexoAdminsitrativo)
      },
      redirecionarCotasContempladas () {
        this.$router.push({ name: 'GestaoCotasContempladas' })
      },
      redirecionarDetalheProjeto () {
        this.$router.push({ name: 'VenderDetalheProjeto', params: { idProjeto: this.projeto.id } })
      },
      verificaTipoDocumento () {
        this.exibeAnexoCpf = this.cpfCnpj.length !== CNPJ
        this.exibeAnexoCnpj = this.cpfCnpj.length === CNPJ
      }
    },
    beforeRouteLeave (to, from, next) {
      if (to.name !== 'GestaoCotasContempladas' && to.name !== 'VenderDetalheProjeto') {
        alert({
          text: `Se você sair o projeto não será salvo e os anexos não serão enviados. Confirma o abandono do projeto?`,
          buttons: {
            'Cancelar': () => next(false),
            'Confirmar': () => {
              next()
            }
          }
        })
      } else {
        next()
      }
    }
  }
</script>

<style scoped>
  .header-text {
    color: var(--primary) !important;
    font-size: 14px;
    font-weight: bold;
  }

  .valor-text {
    color: var(--inputcolor);
    text-align: end;
    font-size: 14px;
  }

  .text-align-center {
    text-align: center;
  }
</style>
